import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import Footer from "../../components/footer-layout/footer";
import HeaderLayout from "../../components/header-layout/header-layout";
import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";
import * as eventActions from "../../store/actions/events";

const EventsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const myEvents = useSelector((state) => state.events.myEvents);
  const otherEvents = useSelector((state) => state.events.otherEvents);
  const [notLoading, setNotLoading] = useState(true);
  useEffect(() => {
    fetchEvents();
  }, [token]);

  const fetchEvents = async () => {
    setLoading(true);
    if (token) {
      axios
        .get(apiService.API_URL() + `/api/v1/getEvents/${user.id}`, {headers: { Authorization: `Bearer ${token}` },})
        .then(function (response) {
          dispatch(eventActions.setOtherEvents(response.data.otherEvent));
          dispatch(eventActions.setMyEvents(response.data.myEvent));
          setLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setNotLoading(true);
        });
    } else {
      axios
        .get(apiService.API_URL() + "/api/v1/getEvents")
        .then(function (response) {
          dispatch(eventActions.setOtherEvents(response.data.otherEvent));
          dispatch(eventActions.setMyEvents(response.data.myEvent));

          setLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setNotLoading(true);
        });
    }
  };

  const EventCard = ({ data }) => {
    return (
      <div className="bg-light d-flex flex-column align-items-center justify-content-center">
        <div>
          <img
            className="my-5"
            src={data.banner_image}
            style={{ height: "80px" }}
            alt="eco"
          />
        </div>
        <div style={{ width: "75%" }}>
          <h1 className="fs-5 fw-semibold text-center">
            {data.challenge_name}
          </h1>

          {data.occurence === "future event" ? (
            <p className="text-center">
              <Moment format="MMMM DD">{data.start_date}</Moment>&nbsp;-&nbsp;
              <Moment format="MMMM DD, YYYY">{data.end_date}</Moment>
            </p>
          ) : (
            <p className="text-center text-capitalize">{data.occurence}</p>
          )}
          <div className="text-center my-5">
            <button
              className="btn bg-pla-primary text-white px-5 text-uppercase fw-bold"
              onClick={() => navigate(`/challenge/${data.challenge_slug}`)}
            >
              Enter
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <HeaderLayout />
      <div className="d-flex flex-column align-items-center justify-content-center">
        <LoadingSpinner hidden={!loading} />

        {!loading && (
          <div className="text-center mt-3">
            <h2>Events in progress</h2>
            <p className="fw-bold fs-6 text-uppercase my-4">
              View <span className=" text-primary cursor-pointer">PAST</span> or
              <span className="text-primary cursor-pointer"> Future</span>{" "}
              Events
            </p>
          </div>
        )}

        {myEvents && myEvents?.length > 0 && (
          <div className="container my-2">
            <p className="text-center text-uppercase fs-6">My Events</p>
            <div className="row justify-content-center gap-4">
              {myEvents.map((eve, index) => {
                return (
                  <div className="col-md-4" key={index}>
                    <EventCard data={eve} />
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {otherEvents && otherEvents?.length > 0 && (
          <div className="container my-4">
            <p className="text-center text-uppercase fs-6">Other Events</p>
            <div className="row justify-content-center gap-4">
              {otherEvents.map((eve, index) => {
                return (
                  <div className="col-md-4" key={index}>
                    <EventCard data={eve} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {!loading && <Footer />}
    </>
  );
};

export default EventsScreen;
