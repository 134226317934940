import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const QuizTermsAndConditions = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      scrollable={true}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Plafera Quiz Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5 className="my-3 fw-bold">PLAFERA QUIZ</h5>

          <p className="fs-6">
            Would you like to test your knowledge? This Plafera quiz can be very
            helpful. Taking our daily online quiz will help you find out how
            much you know about the environment and its problems, such as
            environmental pollution, global warming, waste management, climate
            change, biodiversity, the ecosystem, etc.
          </p>

          <br />
          <h5 className="my-3 fw-bold">How it works</h5>

          <p className="fs-6">
            From Monday through Friday, there are only two questions every day.
            The quiz can be taken at any time during the day, however, once an
            entry has been made it cannot be changed. The first quiz question
            appears once you choose the "Quiz" menu on the Plafera Dashboard and
            expires after 12 seconds. Next, the second quiz question appears and
            expires after 12 seconds too.
          </p>

          <br />
          <p className="fs-6">
            Every Saturday, the quiz's winners are announced. The top three
            finishers will be declared the winners for the week and be displayed
            on the Plafera App landing page. The quiz results for the week are
            given in order of the number of questions successfully answered and
            the fastest average response time.
          </p>

          <br />
          <p className="fs-6">
            The top three weekly quiz winners will receive the following prizes:
            First prize: N2,000 in airtime. Second place: N1,500 in airtime.
            Third Place: N1,000 in airtime.
          </p>

          <br />
          <p className="fs-6">
            Any 1st Place winner that wins Two (2) or more consecutive times in
            a single calendar month will receive a prize for the Winner of the
            Month of N20,000
          </p>

          <br />

          <h5 className="my-3 fw-bold">Terms and Conditions</h5>

          <ol>
            <li>
              The Plafera Quiz is being organized by Plastic Free Rainy Season
              Sensitization Initiative (Plafera).
            </li>
            <br />
            <li>
              All participants must sign up on Plafera App to participate in
              Plafera Quiz
            </li>
            <br />
            <li>
              Entry into the Plafera Quiz is open to all Nigerian citizens only.
              Entries from outside Nigeria shall be considered in the nearest
              future.
            </li>
            <br />
            <li>
              Every 24 hours, there are only two questions. The quiz can be
              taken at any time of day, but entries cannot be withdrawn once
              submitted. Once the "Quiz" menu on the Plafera Dashboard is
              selected, the first quiz question will appear and time out after
              12 seconds. Then the second quiz question follows and times out
              after 12 seconds too. That ends it for the day.
            </li>
            <br />
            <li>
              Quiz results are released every Saturday. Participants can view
              the weekly quiz results when the quiz concludes on Friday each
              week.
            </li>
            <br />
            <li>
              If any of the winners cannot be contacted within five (5) working
              days of the result announcement, he or she will be disqualified
              for the prize and another winner will be chosen from the remaining
              eligible entries in the order of ranking. The Plastic Free Rainy
              Season Sensitization Initiative (Plafera) will select winners,
              contact them, and deliver the prizes.
            </li>
            <br />
            <li>
              Plastic Free Rainy Season Sensitization Initiative (Plafera)
              decision on the Plafera Quiz shall be final and binding and no
              correspondence will be entered into regarding the same.
            </li>
            <br />
            <li>
              One entrant can participate only once. Multiple entries from the
              same entrant will not be considered and will be discarded.
            </li>
            <br />
            <li>
              The Plastic Free Rainy Season Sensitization Initiative (Plafera)
              assumes no responsibility for entries that are lost, late, or
              incomplete, or are not transmitted due to computer error or any
              other error beyond the organizer's reasonable control. Please keep
              in mind that proof of submission is not proof of receipt.
            </li>
            <br />
            <li>
              In the event of unforeseen circumstances, organizers reserve the
              right to amend or withdraw the Quiz at any time. For the avoidance
              of doubt, this includes the right to amend these terms and
              conditions.
            </li>
            <br />
            <li>
              The quiz results for the week are displayed at midnight on Friday
              in order of the highest number of questions correctly answered in
              the shortest average response time; the top three finishers are
              declared the winners for the week and are featured on the Plafera
              landing page of the Plafera App.
            </li>
            <br />
            <li>
              Prizes: All participants will receive a digital Participation
              Notification. If the total number of participants in a weekly quiz
              exceeds 100, there will be three (3) winners for that week's quiz.
              Winners will be announced at the conclusion of each weekly quiz.
              The top three weekly quiz winners will receive the following
              prizes: First prize: N2,000 in airtime. Second place: N1,500 in
              airtime. Third Place: N1,000 in airtime.
              <br />
              <br />
              Winner of the Month: Any 1st Place winner that wins Two (2) or
              more consecutive times in a single calendar month will be given a
              prize for the Winner of the Month of N20,000 which will be
              electronically transferred to the winner’s bank account upon
              receipt of the valid account and identification details.
            </li>
            <br />
            <li>
              By entering the Quiz, the participant accepts and agrees to be
              bound by these Terms and Conditions, mentioned above.
            </li>
            <br />
            <li>
              Participants must update their Plafera profiles with information
              such as their name, email address, phone number, photo, and
              address. By submitting your contact information, you agree that it
              will be used for the Plafera Quiz and promotions by the Plastic
              Free Rainy Season Sensitization Initiative (Plafera).{' '}
            </li>
            <br />
            <li>
              Plastic Free Rainy Season Sensitization Initiative (Plafera) at
              its discretion can review/ change the Terms and Conditions of the
              Plafera quiz upon analysis of weekly quiz participation data.
            </li>
            <br />
            <li>
              These Terms and Conditions shall be governed by the laws of the
              Nigerian Judiciary.{' '}
            </li>
          </ol>
          <br />
          <h6 className="my-3 fw-bold">Terms and Conditions</h6>

          <ol>
            <li>
              The following terms and conditions henceforth shall be governed by
              Nigerian laws and the judgments of the Nigerian judicial system.
            </li>
            <br />
            <li>
              Persons only Nigerian nationality are eligible to participate in
              the Plafera quiz.
            </li>
            <br />
            <li>
              Participants will be required to answer 2 questions within 12
              seconds per question every day, five days a week (Monday -
              Friday).
            </li>
            <br />
            <li>
              The quiz will start as soon as the participant clicks on the
              ‘Quiz’ button on Plafera Dashboard.
            </li>
            <br />
            <li>Entries once submitted cannot be withdrawn.</li>
            <br />
            <li>
              Participants must sign up and provide their name, email address,
              phone number, and any other information requested by the Plafera
              sign-up form. Participants give consent to the Plafera App and
              Plafera to use this information as needed to facilitate the
              conduct of the quiz competition, which may include confirmation of
              participant details, the announcement of winners, and award
              disbursement, by submitting their details and participating in the
              Plafera quiz.
            </li>
            <br />
            <li>
              Participants can only enter the quiz competition once. Multiple
              entries from the same participant will not be accepted and
              considered.
            </li>
            <br />
            <li>
              At midnight on Friday, the quiz results for the week are displayed
              in order of the highest number of questions correctly answered
              using the shortest average response time; the top three finishers
              will be declared the winners for the week and be featured on the
              Plafera landing page of Plafera App.
            </li>
            <br />
            <li>
              In the event of unforeseen circumstances, organizers reserve the
              right to amend the terms and conditions of the competition at any
              time or cancel the competition as considered necessary.
            </li>
            <br />
            <li>
              The organizers reserve the right to disqualify participants,
              refuse entry, or discard entries if such instances or
              participation is detrimental to the competition, or organizers.
              Additionally, any entry will be considered void if the information
              submitted is illegible, incomplete, false, or erroneous.
            </li>
            <br />
            <li>
              Organizers will not accept any responsibility for entries that are
              lost, are late, incomplete, or have not been transmitted due to
              computer error or any other error beyond the organizer’s
              reasonable control. Please note proof of submission of the entry
              is not proof of receipt of the same.
            </li>
            <br />
            <li>
              The participants shall abide by all the terms and conditions of
              the quiz competition, including any amendments or further updates.
            </li>
            <br />
            <li>
              The organizer’s decision on the Plafera Quiz shall be final and
              binding and no correspondence will be entered into regarding the
              same.
            </li>
            <br />
            <li>
              By entering the Plafera Quiz, the participant accepts and agrees
              to be bound by the above-mentioned terms and conditions.
            </li>
            <br />
            <li>
              Prizes: All participants will receive a digital Participation
              Notification. If the total number of participants in a weekly quiz
              exceeds 100, there will be three (3) winners for that week's quiz.
              Winners will be announced at the conclusion of each weekly quiz.
              The top three weekly quiz winners will receive the following
              prizes: First prize: N2,000 in airtime. Second place: N1,500 in
              airtime. Third Place: N1,000 in airtime.
            </li>
          </ol>
          <br />
          <p className="fs-6">
            Winner of the Month: Any 1st Place winner that wins Two (2) or more
            consecutive times in a particular calendar month will be awarded a
            prize of N20,000 which will be electronically transferred to the
            winner’s bank account upon receipt of the valid account and
            identification details.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={props.handleClose}>
          I understand
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuizTermsAndConditions;
