import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";

import * as teamsAction from "../../store/actions/teams";
import Feeds from "../Feeds";

import "./teamprofile.css";

const TeamProfile = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { teamId } = state;
  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState(null);
  const [teamProfile, setTeamProfile] = useState(null);
  const [teamPoints, setTeamPoints] = useState(null);
  const [teamFeeds, setTeamFeeds] = useState(null);
  const [teamMembers, setTeamMembers] = useState(null);
  const [teamMembersCount, setTeamMembersCount] = useState(null);
  const [teamMembersPoints, setTeamMembersPoints] = useState(null);
  const [points, setPoints] = useState(null);

  const teamStore = useSelector((state) => state.teams.team);
  const teamPointsStore = useSelector((state) => state.teams.teamPoints);

  const challenge = localStorage.getItem("challenge");
  const challengedata = JSON.parse(challenge);  
  useEffect(() => {
    fetchTeamData();
  }, []);
  useEffect(() => {
    if (teamStore) {
      setTeamProfile(teamStore.team.data);
      setTeamPoints(teamStore.points);
      setTeamFeeds(teamStore.team_feed);
      setTeamMembers(teamStore.team_members);
      setTeamMembersCount(teamStore.team_members.length);
      setTeamMembersPoints(teamStore.team_member_points);
    }
  }, [teamStore]);
  useEffect(() => {
    if (teamPointsStore) {
      setPoints(teamPointsStore);
    }
  }, [teamPointsStore]);

  const fetchTeamData = () => {
    setLoading(true);
    if (token) {
      axios
        .get(
          apiService.API_URL() + `/api/v1/get-team-by-id?team_id=${teamId}&challange_id=${challengedata.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setLoading(false);
          dispatch(teamsAction.setTeam(response.data));
          fetchTeamPoints();
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setLoading(false);
        });
    }
  };

  const fetchTeamPoints = () => {
    setLoading(true);
    if (token) {
      axios
        .get(apiService.API_URL() + `/api/v1/get-team-points/${teamId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          dispatch(teamsAction.setTeamPoints(response.data));
          setLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setLoading(false);
        });
    }
  };
  return (
    <>
      <ToastContainer />
      <LoadingSpinner hidden={!loading} />
      {!loading && (
        <div>
          <div className="T_profile_body">
            <div className="T_profile_content">
              {teamProfile && (
                <div
                  className="bg-dark p-5 d-flex flex-column align-items-center justify-content-center"
                  style={{
                    backgroundImage: `url(${`${teamProfile?.banner[0]?.image}`})`,
                    backgroundSize: "cover",
                  }}
                >
                  <div>
                    {teamProfile?.logo[0]?.image ? (
                      <img
                        src={`${teamProfile?.logo[0]?.image}`}
                        className="mx-auto d-block rounded-circle"
                        alt="Cinque Terre"
                        style={{ height: "100px", width: "100px" }}
                      />
                    ) : (
                      <div
                        className="px-5 py-4 rounded-circle bg-success d-flex align-items-center justify-content-center text-uppercase fs-3 text-white"
                        style={{ height: "100px", width: "100px" }}
                      >
                        {teamProfile?.name?.charAt(0)}
                      </div>
                    )}
                  </div>
                  <h3 className="text-center title mt-4 mb-2">{}</h3>
                  <h5 className="sub_title mt-1 mb-4 cursor-pointer text-capitalize fs-3 fw-bold">
                    {teamProfile?.name}
                  </h5>
                  <p className="text-white text-center fs-5">
                    {teamProfile?.organization_or_school}
                  </p>
                </div>
              )}
              {teamPoints != null && (
                <div className="points_body mt-5">
                  <h4 className="text-center mb-4">POINTS TOTAL</h4>
                  <div className="d-flex justify-content-center">
                    <div className="box">
                      <h2 className="value text-center">
                        {" "}
                        {teamPoints?.today_points}{" "}
                      </h2>
                      <div className="text-center text-uppercase">Today</div>
                    </div>
                    <div className="box">
                      <h2 className="value text-center">
                        {" "}
                        {teamPoints?.current_week_points}{" "}
                      </h2>
                      <div className="text-center text-uppercase">
                        this week
                      </div>
                    </div>
                    <div className="box">
                      <h2 className="value text-center">
                        {" "}
                        {teamPoints?.total_points}{" "}
                      </h2>
                      <div className="text-center text-uppercase">Total</div>
                    </div>
                  </div>
                </div>
              )}

              {teamFeeds != null && teamFeeds.length > 0 && (
                <div className="row">
                  <div className="col-md-12 col-sm-12 colxs-12 mt-5 mb-5">
                    <h1 className="text-center">Team Feed</h1>
                    <p className="text-center">
                      <b>RECENT UPDATES FROM THIS TEAM</b>
                    </p>
                  </div>
                  {teamFeeds.map((items, i) => {
                    return (
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="team_fead_card ">
                          <div className="team_circle">
                            {items.user[0].name.charAt(0)}{" "}
                            {items.user[0].last_name.charAt(0)}
                          </div>
                          <div className="content mb-4">
                            {items.user[0].name} {items.user[0].last_name}
                          </div>
                          <div className="date text-muted mb-3 text-uppercase">
                            {format(parseISO(items.created_at), "MMM dd")} AT{" "}
                            {format(parseISO(items.created_at), "h:m a")}
                          </div>
                          <div className="content mb-4">{items.content}</div>
                          <div className="profile"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {teamMembers != null && (
                <div className="row mt-5 mb-5">
                  <div className="col-md-12 col-sm-12 colxs-12 mt-5 mb-5">
                    <h1 className="text-center">
                      {" "}
                      {teamMembersCount} Team Members
                    </h1>
                    <p className="text-center">
                      <b>RECENTLY ACTIVE TEAM MEMBERS</b>
                    </p>
                  </div>

                  {teamMembers &&
                    teamMembers.map((item) => (
                      <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="team_fead_card ">
                          <div className="team_circle">
                            {item?.member_details?.name?.charAt(0)}{" "}
                            {item?.member_details?.last_name?.charAt(0)}
                          </div>
                          <div className="content mb-4">
                            {item.member_details.name}{" "}
                            {item.member_details.last_name}
                          </div>
                          <div className="profile">
                            {item.member_details.email}
                          </div>
                          <div className="profile">
                            {item.member_details.team_name}
                          </div>
                          <div className="team_points">
                            {" "}
                            + {item.member_points.total_points}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamProfile;
