import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, Navigate, } from "react-router-dom";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { applyMiddleware, combineReducers, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";

import eventsReducer from "../store/reducers/events";
import teamsReducer from "../store/reducers/teams";
import homeReducer from "../store/reducers/home";
import authReducer from "../store/reducers/auth";
import pieChartReducer from "../store/reducers/pie-chart";
import actionCategoriesReducer from "../store/reducers/actionCategories";
import pointsReducer from "../store/reducers/points";
import dashboardReducer from "../store/reducers/dashboard";
import feedsReducer from "../store/reducers/feeds";
import participantsReducer from "../store/reducers/participants";
import organizationsReducer from "../store/reducers/organizations";
import quizReducer from "../store/reducers/quiz";
import userReducer from "../store/reducers/user";

import * as authActions from "../store/actions/auth";
import * as eventActions from "../store/actions/events";

import "./container.css";
import Home from "../components/home-layout/home";
import Dashboard from "../components/dashboard/dashboard";
import OmniCalculator from "../components/omni-calculator/omni-calculator";
import CampusDashboard from "../components/campus-dashboard/campus-dashboard";
import AdminDashboard from "../screens/AdminDashboard";
import CreateTeam from "../screens/CreateTeam";
import ActionCategories from "../components/campus-dashboard/campus-dashboard-action-categories";
import CampusDashboardView from "../components/campus-dashboard/campus-dashboard-view";
import ActionByActionCategories from "../components/campus-dashboard/action-by-action-category";
import EditProfile from "../screens/EditProfile";
import UserSigninScreen from "../screens/UserSigninScreen";
import UserSignupScreen from "../screens/UserSignupScreen";
import EmailTeammates from "../screens/EmailTeammates";
import TeamInvitations from "../screens/TeamInvitations";
import Points from "../screens/Points";
import ScrollToTop from "../components/ScrollToTop";
import Feeds from "../screens/Feeds";
import EventsScreen from "../screens/EventsScreen";
import JoinTeam from "../screens/JoinTeam";
import ConfirmSignup from "../screens/ConfirmSignup";
import UserCreateTeam from "../screens/UserCreateTeam";
import People from "../screens/People/people";
import Notification from "../screens/Notification";
import HowItWorks from "../screens/HowItWorks";
import TeamProfile from "../screens/TeamProfile";
import UserProfile from "../screens/UserProfile";
import EditTeam from "../screens/EditTeam";
import TeamEvent from "../screens/TeamEvent";
import TeamEventList from "../screens/TeamEventList";
import GenerateLink from "../screens/GenerateLink";
import ResetPassword from "../screens/ResetPassword";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import TermsAndConditions from "../screens/TermsAndConditions";
import EventSuccess from "../screens/EventsSuccess";
import OverAllImpact from "../components/home-layout/overAllImpact";
import QuizResult from "../screens/QuizResult";
import UpdateTeamEvent from "../screens/UpdateTeamEvent";
import FaqScreen from "../screens/FaqScreen";
import QuizTermsAndConditions from "../screens/QuizTermsAndConditions";
import AboutScreen from "../screens/AboutScreen";
import BlogComponent from "../screens/Blog";
import BlogSlugComponent from "../screens/Blog-Slug";
import EventSlug from "../screens/Event-Slug";
import EventsComponent from "../screens/Event";
// import iphoneAddToHomeScreen from "../assets/images/PlaferaGif.gif";

// import { IoPersonAdd } from "react-icons/io5";
// import playstore from "../assets/images/playstoreicn.png";
// import appleicn from "../assets/images/apple.jpg";
// import windowsicn from "../assets/images/windows.png";
// import macicn from "../assets/images/mac.png";
// import firefoxicn from "../assets/images/firefox.png";

import androidIcon from "../assets/images/AndroidStore.png";
import appleStoreIcon from "../assets/images/AppleStore.png";
import iOsGif from "../assets/images/iOsGif.gif";

const rootReducer = combineReducers({
  events: eventsReducer,
  auth: authReducer,
  teams: teamsReducer,
  home: homeReducer,
  pieChart: pieChartReducer,
  actionCategories: actionCategoriesReducer,
  dashboard: dashboardReducer,
  points: pointsReducer,
  feeds: feedsReducer,
  participants: participantsReducer,
  organizations: organizationsReducer,
  quiz: quizReducer,
  user: userReducer,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
const history = createBrowserHistory();
history.listen(console.log);

let deferredPrompt;

export default function Container() {

  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    store.dispatch(authActions.setToken(localStorage.getItem("token")));
    store.dispatch(authActions.setUser(JSON.parse(localStorage.getItem("user"))));
    store.dispatch(eventActions.setChallenge(JSON.parse(localStorage.getItem("challenge"))));
    getAuth();
  }, [isAuth]);

  const getAuth = () => {
    let authenticated = localStorage.getItem("token");
    if (authenticated) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  };

  // add to home screen functionality start

  const [installable, setInstallable] = useState(false);
  const [iosDevices, setIosDevices] = useState(null);
  const [andriodDevices, setAndriodDevices] = useState(null);
  const [isIosModalVisible, setIosModalVisible] = useState(false);
  const [operatingSytemName, setOperatingSytem] = useState(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  }, []);

  const handleInstallClick = (e) => {
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();

    console.log("deferredPrompt", deferredPrompt);

    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
    });
  };

  function operatingSytem() {
    let OSName="Unknown OS";
          let ua = navigator.userAgent
    if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows";
    if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS";			
     
          if (/android/i.test(ua)) {
              OSName =  "Android";
          }
          if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)){
              OSName = "iOS";
          }
                      // Display the OS name
    return OSName;
  }
  
  useEffect(() => {
    
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    var isAppleDevice = navigator.userAgent.includes('Macintosh');
    var isWindowsDevices = navigator.userAgent.match(/Windows/i);
    var isAndroidDevices = navigator.userAgent.match(/Android/i);
    setOperatingSytem(operatingSytem());
    // console.log(" ANDROID DEVICES : ", isAndroidDevices);
    // console.log(" IOS DEVICES : ", isIOS, + " APPLE DEVICES : " + isAppleDevice);

    
    setIosDevices(isIOS)
    setAndriodDevices(isAndroidDevices);
    
  }, []);

  const onOpeniOSModalHandler = () => {
    setIosModalVisible(true)
  }
  const isCloseIOSDevices = () => {
    setIosModalVisible(false);
  }

 
  const isCloseAndroidDevices = () => {
    setAndriodDevices(null);
  }

  
  // add to home screen functionality end
  let installButton;
  switch (operatingSytemName) {
    case 'Android':
      installButton = <div className="text-center android-store-icon">
        <img src={androidIcon} key='windows-install' onClick={handleInstallClick} />          
      </div>
      break;
      case 'iOS':
      installButton = <div className="text-center apple-store-icon">
        <span onClick={onOpeniOSModalHandler}><img src={appleStoreIcon} key='windows-install'  /></span>      
      </div>
      break;
      case 'Windows':
      installButton = <div className="text-center">
        <button key='windows-install' className="install-button theme_btn" onClick={handleInstallClick}>
          Install me      </button>
      </div>
      break;
      case 'MacOS':
      installButton = <div className="text-center">
        <button key='mac-install' className="install-button theme_btn" onClick={handleInstallClick}>
        Install me  </button>
      </div>
      break;

    default:
      installButton = null;
      break;
  }

  return (
    <>
    {installable && installButton}
    {operatingSytemName === 'iOS' && installButton}    
   
      {isIosModalVisible &&
        <div className="popup_body" style={{ zIndex: "9999" }}>
          <div className="popup_content">
            <div className="popup_close">
              <i className="fa fa-times" aria-hidden="true" onClick={isCloseIOSDevices}></i>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mar_auto">
                <h4 class="text-center mb-2">Follow the instruction for Add to home screen</h4>
                <img src={iOsGif} alt="Ios Add to home GIF" />
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <Provider store={store}>
        <Router history={history}>
          <ScrollToTop />
          <Routes>
            <Route
              path={`/`}
              element={<Home logout={() => setIsAuth(false)} />}
            ></Route>
            <Route path={`/linkedin`} element={<LinkedInCallback />}></Route>
            <Route path={`/privacy-policy`} element={<PrivacyPolicy />}></Route>
            <Route path={`/faq`} element={<FaqScreen />}></Route>
            <Route path={`/about`} element={<AboutScreen />}></Route>
            <Route path={`/terms-and-conditions`} element={<TermsAndConditions />}></Route>
            <Route path={`/quiz-terms-and-conditions`} element={<QuizTermsAndConditions />}></Route>
            <Route path={`/linkedin`} element={<LinkedInCallback />}></Route>
            <Route path={`/overall-impact`} element={<OverAllImpact />}></Route>
            <Route path={`/how-it-works`} element={<HowItWorks authenticate={() => setIsAuth(true)} />}></Route>
            <Route path={`/blog`} element={<BlogComponent />}></Route>
            <Route path={`/blog-slug/:BlogSlug`} element={<BlogSlugComponent />}></Route>
            <Route path={`/event/:EventSlug`} element={<EventSlug />}></Route>
            <Route path={`/events-list`} element={<EventsComponent />}></Route>

            {!isAuth && (
              <>
                <Route
                  path={`/sign-in`}
                  element={
                    <UserSigninScreen authenticate={() => setIsAuth(true)} />
                  }
                ></Route>
                <Route path={`/sign-up`} element={<UserSignupScreen />}></Route>
                <Route path={`/generate-link`} element={<GenerateLink />}></Route>
                <Route
                  path={`/reset-password`}
                  element={<ResetPassword />}
                ></Route>
                <Route path={`/notification`} element={<Notification />}></Route>
                <Route
                  path={`/confirm-signup`}
                  element={<ConfirmSignup />}
                ></Route>
              </>
            )}
            <Route path={`/events`} element={<EventsScreen />}></Route>
            {isAuth && (
              <>
                <Route
                  path={`/join-team/:challengeID`}
                  element={<JoinTeam />}
                  logout={() => setIsAuth(false)}
                ></Route>
                <Route
                  path={`/create-team/:challengeID`}
                  element={<UserCreateTeam />}
                  logout={() => setIsAuth(false)}
                ></Route>
              </>
            )}
            <Route path={`/omnicalculator`} element={<OmniCalculator />}></Route>

            {isAuth && (
              <Route
                path={`/dashboard`}
                element={<Dashboard logout={() => setIsAuth(false)} />}
              >
                <Route path="/dashboard/home" element={<AdminDashboard />} />
                <Route path="/dashboard/create-team" element={<CreateTeam />} />
                <Route path="/dashboard/feeds" element={<Feeds />} />
                <Route
                  path="/dashboard/people/:PeopleType"
                  element={<People />}
                />
                <Route path="/dashboard/edit-profile" element={<EditProfile />} />
                <Route
                  path="/dashboard/email-teammates"
                  element={<EmailTeammates />}
                />
                <Route
                  path="/dashboard/team-invitations/:name"
                  element={<TeamInvitations />}
                />
                <Route path="/dashboard/points" element={<Points />} />
                <Route
                  path="/dashboard/teamprofile/:name"
                  element={<TeamProfile />}
                />
                <Route path="/dashboard/edit-team/:name" element={<EditTeam />} />
                <Route
                  path="/dashboard/user-profile/:name"
                  element={<UserProfile />}
                />
                <Route path="/dashboard/create-event" element={<TeamEvent />} />
                <Route
                  path="/dashboard/event-list/:id"
                  element={<TeamEventList />}
                />
                <Route path="/dashboard/quiz-result" element={<QuizResult />} />
                <Route
                  path="/dashboard/edit-event/:id"
                  element={<UpdateTeamEvent />}
                />
                <Route
                  path="/dashboard/event-success/:eventId"
                  element={<EventSuccess />}
                />
              </Route>
            )}

            <Route
              path={`/challenge`}
              lement={<CampusDashboard logout={() => setIsAuth(false)} />}
            >
              <Route
                path="/challenge/allactioncategory"
                element={<CampusDashboardView />}
              />
              <Route
                path="/challenge/actioncategories/:challengeSlug"
                element={<ActionCategories />}
              />
              <Route
                path="/challenge/actionbyactioncategories/:ActionCategorySlug/challengeId/:ChallengeID/challengesluge/:challengeSlug"
                element={<ActionByActionCategories />}
              />
            </Route>
            <Route
              path="*"
              element={<Navigate to={isAuth ? "/dashboard/home" : "/sign-in"} />}
            />
          </Routes>
        </Router>
      </Provider>
    </>
  );
}
