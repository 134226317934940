import { SET_HOMEDATA, SET_STORE_DATA, SET_IMPACTS_DATA } from "../actions/home";

const initialState = {
  homeData: null,
  storeData: null,
  impactsData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOMEDATA:
      return {
        ...state,
        homeData: action.data,
      };
    case SET_STORE_DATA:
      return {
        ...state,
        storeData: action.data,
      };
    case SET_IMPACTS_DATA:
      return {
        ...state,
        impactsData: action.data,
      };
  }
  return state;
};
