import React,{useEffect} from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import LoadingSpinner from '../../components/LoadingSpinner';
import apiService from '../../services/api.service';
import { useQuery } from 'react-query';

import * as pointsAction from '../../store/actions/points';

const Points = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const fetchTeamData = async () => {
    try {
      const response = await axios.get(apiService.API_URL() + '/api/v1/get-points-data', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data.points_data);
      return response.data?.points_data;
    } catch (error) {
      if (error.response.data.errors) {
        for (let i = 0; i < error.response.data.errors.length; i++) {
          toast.error(error.response.data.errors[i]);
        }
      }
      throw error;
    }
  };

  // useQuery hook for fetching data
  const { data: actionPoints, isLoading, isError } = useQuery('actionPoints', fetchTeamData);

  useEffect(() => {
    if (actionPoints) {
      dispatch(pointsAction.setActionPoints(actionPoints));
    }
  }, [actionPoints, dispatch]);

  return (
    <div className="d-flex flex-column bg-white">
      <ToastContainer />
      <LoadingSpinner hidden={!isLoading} />
      {!isLoading && (
        <>
          <section className="d-flex flex-column align-items-center justify-content-center my-4">
            <h2 className="fw-light fs-1 text-capitalize my-2 text-pla-primary">
              points: actions + values
            </h2>
          </section>

          <section>
            <div className="container team-form">
              <p>
                During Plafera Challenge, we encourage you to create habits that
                benefit you, your community and the planet. You choose your
                challenge then earn points for completing your actions, engaging
                your community and sharing your story. Your points will add up
                during the month to help you and your team win competitions and
                demonstrate your overall impact for our planet.
              </p>
              <p>
                You can never be penalized for not completing an action and
                one-time actions award points based on the action difficulty -
                the harder the action, the more points you get for completing
                it. You can always post on the Feed about what struggles you’re
                facing that are keeping you from completing your actions. This
                still gets you 5 points for checking in that day and perhaps
                start a conversation with others in the same boat and find
                solutions to your struggles.
              </p>
              <p>
                These actions are intended to inspire you to explore new
                personal challenges in regards to a plastic free lifestyle. It’s
                absolutely amazing if you’re already taking steps to reduce your
                plastic intake. Don’t forget to select the “I already do this”
                option on actions you have previously been working on and you
                will get points just for being proactive!
              </p>

              <div className="row border-bottom fw-bold text-uppercase">
                <div className="col-4">Action</div>
                <div className="col-2">Points</div>
                <div className="col-6">Desciption</div>
              </div>
              {actionPoints &&
                actionPoints.map((point, index) => {
                  return (
                    <div className="row border-bottom" key={index}>
                      <div className="col-4 my-2">
                        {point.action_description}
                      </div>
                      <div className="col-2 my-2">{point.point}</div>
                      <div className="col-6 my-2">{point.full_description}</div>
                    </div>
                  );
                })}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default Points;
