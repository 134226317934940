import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./emailTeammates.css";
import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";

import * as teamsAction from "../../store/actions/teams";

const EmailTeammates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { teamId } = state;
  const [teammates, setTeammates] = useState();
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const teamMembersStore = useSelector((state) => state.teams.teamMembers);
  const teamDataStore = useSelector((state) => state.teams.teamData);

  useEffect(() => {
    if (user) {
      getTeammates();
    }
  }, [user]);

  useEffect(() => {
    if (teamMembersStore) {
      setTeammates(teamMembersStore);
    }
  }, [teamMembersStore]);

  useEffect(() => {
    if (teamDataStore) {
      setTeam(teamDataStore);
    }
  }, [teamDataStore]);

  const getTeammates = () => {
    setLoading(true);
    if (token) {
      axios
        .get(apiService.API_URL() + `/api/v1/getTeamMembers/${user.team}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          setLoading(false);
          dispatch(teamsAction.setTeamMembers(response.data));
          fetchTeamData();
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setLoading(false);
        });
    }
  };

  const fetchTeamData = () => {
    setLoading(true);
    if (token) {
      axios
        .get(apiService.API_URL() + `/api/v1/team/${teamId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          setLoading(false);
          dispatch(teamsAction.setTeamData(response.data));
          // setTeam(response.data);
          //   fetchTeamPoints();
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setLoading(false);
        });
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    let ids = [];
    if (Array.isArray(data.teammate)) {
      ids = data.teammate;
    } else {
      ids.push(data.teammate);
    }
    let formValue = {
      from: user.id,
      id: ids,
      message: data.message,
    };
    axios
      .post(apiService.API_URL() + "/api/v1/pesonalisedEmails", formValue, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        setLoading(false);
        toast.success(response.data.message);
        reset();
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <ToastContainer />
      <LoadingSpinner hidden={!loading} />
      {team && (
        <section
          className="d-flex align-items-center justify-content-center pad_lr_15px"
          style={{ minHeight: "35vh" }}
        >
          <div
            className="d-flex flex-column h-75 wid-50 bg-dark align-items-center justify-content-center"
            style={{
              minHeight: "26vh",
              backgroundImage: `url(${`${team?.banner[0]?.image}`})`,
              backgroundPosition: "center",
            }}
          >
            {team?.logo[0]?.image ? (
              <img
                src={team.logo[0]?.image}
                alt="logo "
                style={{ height: "100px", width: "100px" }}
                className="rounded-circle"
              />
            ) : (
              <div
                className="px-5 py-4 rounded-circle bg-success d-flex align-items-center justify-content-center text-uppercase fs-3 text-white"
                style={{ height: "100px", width: "100px" }}
              >
                {team?.name?.charAt(0)}
              </div>
            )}

            <h3 className="text-white text-capitalize fs-3 fw-light my-3">
              {team.name}
            </h3>
          </div>
        </section>
      )}
      <section className="bg-white pad_lr_15px">
        <div className="container">
          <div className="d-flex flex-column align-items-center justify-content-center py-5">
            <h3 className="fs-3 fw-light">Email Teammates</h3>
            <a
              className="text-uppercase my-2 text-decoration-none fw-bold text-pla-secondary cursor-pointer"
              onClick={() => navigate("/dashboard/home")}
            >
              Back to dashboard
            </a>
            <p className="wid-50 text-center fs-6">
              Below you can send words of encouragement. Your teammates will
              just recieve your personalized message.
            </p>
          </div>
        </div>
        <form
          className="d-flex align-items-center justify-content-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="wid-50">
            <p>Please select the team members who should recieve this email.</p>

            <div className="col-12">
              {teammates &&
                teammates.map((teammate, i) => {
                  return (
                    <div className="my-2">
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        id={i}
                        value={teammate.id}
                        aria-label="Checkbox htmlFor following text input"
                        {...register("teammate", { required: true })}
                      />

                      <label
                        htmlFor={i}
                        className="form-label text-dark text-capitalize"
                      >
                        {teammate.name + " " + teammate.last_name}
                      </label>
                    </div>
                  );
                })}

              {errors.teammate && (
                <div className="text-danger my-2 fst-italic">
                  This field is required.
                </div>
              )}
            </div>

            {/* <div className="mb-4">
              <button className="btn btn-primary btn-sm me-2">
                Select All
              </button>
              <button className="btn btn-primary btn-sm">Select None</button>
            </div> */}

            <div className="col-12 mb-2">
              <label htmlFor="name" className="form-label text-dark">
                Personalized message :
              </label>

              <textarea
                type="test"
                className="form-control"
                id="name"
                rows={7}
                {...register("message", { required: true })}
              ></textarea>

              {errors.message && (
                <div className="text-danger my-2 fst-italic">
                  This field is required.
                </div>
              )}
            </div>
            <div className=" pb-5 mb-5">
              <button
                className="btn bg-pla-primary text-white btn-sm me-2"
                type="submit"
              >
                Send Message
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default EmailTeammates;
