export const SET_MY_EVENTS = "SET_MY_EVENTS";
export const SET_OTHER_EVENTS = "SET_OTHER_EVENTS";
export const SET_WEEKLY_POINTS = "SET_WEEKLY_POINTS";
export const SET_USER_POINTS = "SET_USER_POINTS";
export const SET_CHALLENGE = "SET_CHALLENGE";
export const SET_EVENT_LIST = "SET_EVENT_LIST";

export const SET_POINTS = "SET_POINTS";
export const GET_EVENT_BY_ID = "GET_EVENT_BY_ID";

export const setMyEvents = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_MY_EVENTS, data: data });
  };
};

export const setOtherEvents = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_OTHER_EVENTS, data: data });
  };
};

export const setPoints = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_POINTS, data: data });
  };
};

export const setWeeklyPoints = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_WEEKLY_POINTS, data: data });
  };
};

export const setUserPoints = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_USER_POINTS, data: data });
  };
};

export const setChallenge = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_CHALLENGE, data: data });
  };
};

export const setEventList = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_EVENT_LIST, data: data });
  };
};
export const getEventById = (data) => {
  return async (dispatch) => {
    dispatch({ type: GET_EVENT_BY_ID, data: data });
  };
};
