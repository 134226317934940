import { SET_QUIZ_RESULT_DATA } from "../actions/quiz";

const initialState = {
  quizResultData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_QUIZ_RESULT_DATA:
      return {
        ...state,
        quizResultData: action.data,
      };
  }
  return state;
};
