import {
  SET_TEAMS,
  SET_ALL_TEAMS,
  SET_TEAM,
  SET_TEAM_POINTS,
  SET_TEAM_MEMBERS,
  SET_TEAM_DATA,
  SET_TEAM_DATA_TO_INVITE,
} from "../actions/teams";

const initialState = {
  teams: null,
  allTeams: null,
  team: null,
  teamPoints: null,
  teamMembers: null,
  teamData: null,
  teamDataToInvite: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TEAMS:
      return {
        ...state,
        teams: action.data,
      };
    case SET_ALL_TEAMS:
      return {
        ...state,
        allTeams: action.data,
      };
    case SET_TEAM:
      return {
        ...state,
        team: action.data,
      };
    case SET_TEAM_POINTS:
      return {
        ...state,
        teamPoints: action.data,
      };
    case SET_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: action.data,
      };
    case SET_TEAM_DATA:
      return {
        ...state,
        teamData: action.data,
      };
    case SET_TEAM_DATA_TO_INVITE:
      return {
        ...state,
        teamDataToInvite: action.data,
      };
  }
  return state;
};
