import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const FAQ = (props) => {
  return (
    <Accordion id="accordionFAQ" defaultActiveKey="0">
      {props?.data?.map((faq) => {
        return (
          <Accordion.Item eventKey={`${faq?.id}`}>
            <Accordion.Header>{faq?.question}</Accordion.Header>
            <Accordion.Body>{faq?.answer}</Accordion.Body>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default FAQ;
