import {
    SET_USER_ACTION
  } from "../actions/dashboard";

const initialState = {
    userActionsByChallenge: null
};

export default (state = initialState, action) => {
    switch (action.type) {
      case SET_USER_ACTION:
        return {
          ...state,
          userActionsByChallenge: action.data,
        };
    }
    return state;
  };