import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import FAQ from '../../components/faq/FAQ';
import Footer from '../../components/footer-layout/footer';
import HeaderLayout from '../../components/header-layout/header-layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import apiService from '../../services/api.service';

const FaqScreen = () => {
  const token = useSelector((state) => state.auth.token);

  // Use useQuery hook for fetching data
  const { data, isLoading, isError } = useQuery('faqs', getFaqs, {
    // onSuccess: (response) => {
    // },
    onError: (error) => {
      if (error.response?.data?.errors) {
        error.response.data.errors.forEach((errorMessage) => {
          toast.error(errorMessage);
        });
      }
    },
  });

  function getFaqs() {
    return axios.get(apiService.API_URL() + '/api/v1/all-faq', {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => response.data.faqs);
  }

  return (
    <>
      <LoadingSpinner hidden={!isLoading} />
      <ToastContainer />
      <HeaderLayout />
      <main className="container">
        <h3 className="my-5 fw-bold text-center">Frequently Asked Questions</h3>
        <div className="mt-md-4 pb-md-5">
          {/* Use data from useQuery  */}
          <FAQ data={data} />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FaqScreen;
