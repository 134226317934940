import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import "./people.css";
import data from "./data.json";
import apiService from "../../services/api.service";
import LoadingSpinner from "../../components/LoadingSpinner";

import { useSelector, useDispatch } from "react-redux";

import * as participantsAction from "../../store/actions/participants";

import * as teamsAction from "../../store/actions/teams";
import * as organizationsAction from "../../store/actions/organizations";

function People() {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const challlenge = localStorage.getItem("challenge");
  const challlengeData = JSON.parse(challlenge);
  const allPracticeStore = useSelector(
    (state) => state.participants.allParticipants
  );
  const allTeamsStore = useSelector((state) => {
    return state.teams.allTeams;
  });

  const allOrganizations = useSelector((state) => {
    return state.organizations.organizations;
  });

  let { PeopleType } = useParams();

  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [state, setState] = useState([]);
  const [countriesHasError, setCountriesHasError] = useState(null);

  const [selectedState, setSelectedState] = useState(null);
  const [stateHasError, setStateHasError] = useState(null);
  const [notLoading, setNotLoading] = useState(true);
  const [tableDisplayData, settableDisplayData] = useState([]);
  const [tableColumns, settableColumns] = useState([]);
  // const [teamID, setTeamID] = useState(null);

  const location = useLocation();
  const path = location.pathname;
  const userIdResult = localStorage.getItem("user");
  const user = JSON.parse(userIdResult);

  const goToUserProfile = (userid, teamid, username) => {
    // setTeamID(teamid);
    var data = {
      state: { id: userid, teamId: teamid },
    };
    navigate(`/dashboard/user-profile/` + username, data, { headers: { Authorization: `Bearer ${token}` }} );
  };

  const goToTeamProfile = (state) => {
    const teamid = Number(state.target.id);
    const name = state.target.innerHTML;
    navigate(`/dashboard/teamprofile/` + name, { state: { teamId: teamid } });
  };

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: '(row)=><a href="#" onClick={goToUserProfile} id={row.id}>{row.name}</a>',
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "DOB",
      selector: "dob",
    },
  ];

  const participantColumn = [
    {
      name: "Userid",
      selector: "userid",
      sortable: true,
      omit: true,
    },
    {
      name: "Name",
      selector: "name",
      cell: (row) => (
        <div
          className="Link"
          onClick={() => goToUserProfile(row.userid, row.teamid, row.name)}
          id={row.userid}
        >
          {row.name}
        </div>
      ),
      sortable: true,
      omit: false,
    },
    {
      name: "Team name",
      selector: "team_name",
      sortable: true,
      omit: false,
    },
    {
      name: "Teamid",
      selector: "teamid",
      sortable: true,
      omit: true,
    },
    {
      name: "Role",
      selector: "role",
      sortable: true,
      omit: false,
    },
    {
      name: "Checkin",
      selector: "checkin",
      sortable: true,
      omit: false,
    },
    {
      name: "Postfeed",
      selector: "postfeed",
      sortable: true,
      omit: false,
    },
    {
      name: "Total points",
      selector: "total_points",
      sortable: true,
      omit: false,
    },
  ];
  const teamColumn = [
    {
      name: "Team id",
      selector: "team_id",
      sortable: true,
      omit: true,
    },
    {
      name: "Team name",
      selector: "team_name",
      cell: (row) => (
        <div className="Link" onClick={goToTeamProfile} id={row.team_id}>
          {row.team_name}
        </div>
      ),
      sortable: true,
      omit: false,
    },
    {
      name: "Organization",
      selector: "organization",
      sortable: true,
      omit: false,
    },
    {
      name: "Total users",
      selector: "total_users",
      sortable: true,
      omit: false,
    },
    {
      name: "Points",
      selector: "points",
      sortable: true,
      omit: false,
    },
  ];

  const organizerColumn = [
    {
      name: "Team id",
      selector: "team_id",
      sortable: true,
      omit: true,
    },
    {
      name: "Team name",
      selector: "team_name",
      cell: (row) => (
        <div className="Link" onClick={goToTeamProfile} id={row.team_id}>
          {row.team_name}
        </div>
      ),
      sortable: true,
      omit: false,
    },
    {
      name: "Organization",
      selector: "organization",
      sortable: true,
      omit: false,
    },
    {
      name: "Total users",
      selector: "total_users",
      sortable: true,
      omit: false,
    },
    {
      name: "Points",
      selector: "points",
      sortable: true,
      omit: false,
    },
  ];

  useEffect(() => {
    countriesData();
    if (PeopleType == "participants") {
      getAllParticipants();
    } else if (PeopleType == "teams") {
      getAllTeam();
    } else if (PeopleType == "organizations") {
      getAllOrganization();
    } else if (PeopleType == "competitions") {
      settableDisplayData([]);
      settableColumns([]);
    }
  }, [path]);

  useEffect(() => {
    if (allPracticeStore) {
      settableDisplayData(allPracticeStore);
    }
  }, [allPracticeStore]);

  useEffect(() => {
    if (allTeamsStore) {
      settableDisplayData(allTeamsStore);
    }
  }, [allTeamsStore]);

  useEffect(() => {
    if (allOrganizations) {
      settableDisplayData(allOrganizations);
    }
  }, [allOrganizations]);

  const getAllParticipants = () => {
    setNotLoading(false);
    axios
      .get(
        apiService.API_URL() + `/api/v1/get-challange-participants/${user?.team}/${challlengeData?.id}`, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        var key = "cell";
        var column = response.data.result_colums;

        dispatch(
          participantsAction.setAllParticipants(
            response.data.over_all_participants
          )
        );

        settableColumns(participantColumn);
        setNotLoading(true);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const getAllTeam = () => {
    setNotLoading(false);
    axios
      .get(apiService.API_URL() +"/api/v1/get-challange-team-list/" +challlengeData?.id, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        dispatch(teamsAction.setAllTeams(response.data.over_all_teams));

        settableColumns(teamColumn);
        setNotLoading(true);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const getAllOrganization = () => {
    setNotLoading(false);
    axios
      .get(
        apiService.API_URL() +
          "/api/v1/get-challange-organization-list/" + challlengeData.id, {headers: { Authorization: `Bearer ${token}` },}
      )
      .then(function (response) {
        dispatch(
          organizationsAction.setOrganizations(response.data.over_all_teams)
        );
        // settableDisplayData(response.data.over_all_teams);
        settableColumns(organizerColumn);
        setNotLoading(true);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const countriesData = async () => {
    setNotLoading(false);
    axios
      .get(apiService.API_URL() + "/api/v1/countries")
      .then(function (response) {
        changeKey(response.data.data);
        setCountries(response.data.data);
        setNotLoading(true);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const changeKey = (arrayObj) => {
    for (var i = 0; i < arrayObj.length; i++) {
      arrayObj[i].value = arrayObj[i]["id"];
      arrayObj[i].label = arrayObj[i]["name"];
      delete arrayObj[i].id;
      delete arrayObj[i].name;
    }
  };

  const onChangeCountry = async (value) => {
    setNotLoading(false);
    setSelectedCountry(value);
    setSelectedState(null);
    setCountriesHasError(false);
    axios
      .get(apiService.API_URL() + `/api/v1/states/${value.value}`)
      .then(function (response) {
        changeKey(response.data.data);
        setState(response.data.data);
        setNotLoading(true);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const onChangeState = async (value) => {
    setSelectedState(value);
    setStateHasError(false);
    setNotLoading(false);
    axios
      .get(apiService.API_URL() + `/api/v1/cities/${value.value}`)
      .then(function (response) {
        changeKey(response.data.data);
        setNotLoading(true);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  return (
    <div className="people">
      <div className="container">
        <div className="people_body">
          <div className="title text-center mt-4 mb-4">{PeopleType}</div>
          <div className="details mt-3 mb-5">
            <h4 className="text-center">
              Everyone is an inspiration. Looking to earn more points? Go to
              your dashboard.
            </h4>
            <h4 className="text-center">
              You’ll get extra points for adding a profile picture, banner
              image, and mission statement.
            </h4>
          </div>
        </div>
        <DataTable
          title={
            PeopleType.toUpperCase().charAt(0) +
            PeopleType.slice(1).toLowerCase()
          }
          columns={tableColumns}
          data={tableDisplayData}
          pagination
          highlightOnHover
        />
      </div>
      <LoadingSpinner hidden={notLoading} />
    </div>
  );
}

export default People;
