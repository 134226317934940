import { SET_ALL_ACTION_CATEGORIES, SET_ACTION_BY_ACTION_CATEGORY } from "../actions/actionCategories";

const initialState = {
  actionCategories: null,
  actionsByActionCategory: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_ACTION_CATEGORIES:
      return {
        ...state,
        actionCategories: action.data,
      };
    case SET_ACTION_BY_ACTION_CATEGORY:
      return {
        ...state,
        actionsByActionCategory: action.data,
      }
  }
  return state;
};
