import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import apiService from '../../../services/api.service';
import LoadingSpinner from '../../LoadingSpinner';
import * as authActions from '../../../store/actions/auth';

const FacebookLoginComponent = ({ authenticate }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const challlenge = localStorage.getItem('challenge');
  const challlengeData = JSON.parse(challlenge);

  const responseFacebook = (response) => {
    setLoading(true);
    if (response.accessToken) {
      axios
        .post(apiService.API_URL() + '/api/v1/social-app-auth', {
          provider: 'facebook',
          access_token: response.accessToken,
          challenge_id: challlengeData.id,
        })
        .then(function (response) {
          setLoading(false);

          localStorage.setItem('token', response.data.token);
          localStorage.setItem(
            'user',
            JSON.stringify(response.data.userDetails)
          );
          dispatch(authActions.setToken(response.data.token));
          dispatch(authActions.setUser(response.data.userDetails));

          authenticate();

          if (response.data.isLoggedIn === true) {
            if (response.data.userDetails.team) {
              navigate('/challenge/allactioncategory');
            } else {
              navigate('/join-team/' + challlengeData.id);
            }
          } else {
            navigate('/confirm-signup', {
              state: { id: response.data.userDetails.id },
            });
          }
        })
        .catch(function (error) {
          setLoading(false);
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingSpinner hidden={!loading} />
      <ToastContainer />
      <FacebookLogin
        appId="397976025660236"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        render={(renderProps) => (
          <button
            className="btn btn-primary mx-2"
            onClick={renderProps.onClick}
            type="button"
          >
            <FaFacebookF />
          </button>
        )}
      />
    </>
  );
};

export default FacebookLoginComponent;
