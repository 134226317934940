import { SET_FEEDS } from "../actions/feeds";

const initialState = {
  allFeeds: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FEEDS:
      return {
        ...state,
        allFeeds: action.data,
      };
  }
  return state;
};
