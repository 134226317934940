import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import apiService from '../../services/api.service';
import HeaderLayout from '../../components/header-layout/header-layout';
import Footer from '../../components/footer-layout/footer';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from '../../components/LoadingSpinner';
function BlogSlugComponent() {

    let { BlogSlug } = useParams();
    const navigate = useNavigate();

    const [postSalug, setPostSalug] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPostSlug();

    }, []);

    const getPostSlug = () => {
        setLoading(true);
        axios.get(apiService.API_URL() + '/api/v1/post/' + BlogSlug)
            .then(function (response) {
                setPostSalug(response.data.postBySlug);
                setLoading(false);
            })
            .catch(function (error) {
                if (error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                        toast.error(error.response.data.errors[i]);
                    }
                }
                setLoading(false);
            });

    }

    const goToBlog = () => {
        navigate("/blog");
    }




    return (
        <div>
            <HeaderLayout />
            <div className='container'>
                <section className="bg-white rounded-3 my-5 mx-2 mx-md-5 d-flex flex-column">
                    <div className="py-2">
                        <h3 className="text-center fs-2 fw-bold title">Blog information</h3>
                    </div>
                    <div className='mt-3 mb-2 text-center'>
                        <button className="theme_btn" onClick={goToBlog}>All Posts</button>
                    </div>
                    <div className="m-4 row">
                        <div className="col-md-12 col-sm-12 col-xs-12 my-3 tab_wid_100 d-flex flex-column">
                            <div className="d-flex flex-column align-items-center p-4 bg-white border m-2 flex-grow-1 Blog_Body">
                                <div>
                                    <div className="title">
                                        {postSalug?.title}
                                    </div>
                                    <div className='tags mb-3'><b>Tags : </b>{postSalug?.tags}</div>
                                    {postSalug?.banner_image != null &&
                                        <div className='banner_img'>
                                            <img src={postSalug?.banner_image} />
                                        </div>
                                    }
                                    <div className="content" dangerouslySetInnerHTML={{ __html: postSalug?.content }} />
                                </div>
                                <div className='mt-3 mb-3'>
                                    <button className="theme_btn" onClick={goToBlog}>All Posts</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
            <LoadingSpinner hidden={!loading} />
        </div>
    )
}

export default BlogSlugComponent;