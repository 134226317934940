import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { FaMicrophoneAlt } from "react-icons/fa";
import { IoDocumentText, IoPerson, IoReload } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import { BsTrophy, BsCheckLg } from "react-icons/bs";
import { RiSwapFill, RiPieChart2Fill } from "react-icons/ri";
import { format, parseISO } from "date-fns";
import { ImPlus } from "react-icons/im";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import * as eventActions from "../../store/actions/events";

import { useDispatch, useSelector } from "react-redux";
import apiService from "../../services/api.service";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";

import * as dashboardAction from "../../store/actions/dashboard";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { el } from "date-fns/locale";
import { disable } from "workbox-navigation-preload";

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6];
const createCardCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const CustomCheckBoxNone = () => {
  return (
    <div
      className="bg-pla-gray rounded-circle cursor-pointer"
      style={{ height: "30px", width: "30px" }}
    ></div>
  );
};

const CustomCheckBoxChecked = () => {
  return (
    <div
      className="bg-pla-gray rounded-circle d-flex align-items-center justify-content-center cursor-pointer"
      style={{ height: "30px", width: "30px" }}
    >
      <IoMdCheckmark />
    </div>
  );
};

const CustomCheckBoxCheckedSuccess = () => {
  return (
    <div
      className="rounded-circle d-flex align-items-center justify-content-center bg-pla-success text-white cursor-pointer"
      style={{ height: "30px", width: "30px" }}
    >
      <IoMdCheckmark />
    </div>
  );
};

const CreateTeamCard = () => {
  return (
    <div className="bg-white rounded-3 shadow d-flex flex-column justify-content-center align-items-center mx-4 my-4 cursor-pointer">
      <div className="text-center py-4 px-4">
        <p className="fs-4">CREATE YOUR OWN TEAM</p>
        <p className="display-3 fw-bold">+15</p>
      </div>
    </div>
  );
};

// const TriggerUserActiveActions = () => {
//   getUserActiveActions()
// }

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const teamId = useSelector((state) => state.auth.teamId);
  const [actions, setActions] = useState({});
  const [points, setPoints] = useState({});
  const [checkinFile, setCheckinFile] = useState(null);
  const [checkinFileID, setCheckinFileID] = useState(null);
  const [actionLearnMoreModel, setactionLearnMoreModel] = useState(false);
  const [actionLearnMoreModelID, setActionLearnMoreModelID] = useState(null);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const challenge = localStorage.getItem("challenge");
  const challengedata = JSON.parse(challenge);

  const userActionsByChallengeStore = useSelector(
    (state) => state.dashboard.userActionsByChallenge
  );
  const pointsStore = useSelector((state) => state.events.userPoints);

  useEffect(() => {
    if (user) {
      getUserActions();
      getUserPoints();
    }
  }, [user]);

  useEffect(() => {
    setActions(userActionsByChallengeStore);
  }, [userActionsByChallengeStore]);

  useEffect(() => {
    setPoints(pointsStore);
  }, [pointsStore]);

  const getUserActions = () => {
    setLoading(true);
    axios
      .get(
        apiService.API_URL() +
        `/api/v1/userActionsByChallenge?challenge_slug=${challengedata?.challenge_slug}&user_id=${user?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setLoading(false);
        dispatch(dashboardAction.setUserAction(response.data.actions));
        // setActions(response.data.actions);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };


  const getPoints = (userId, teamId) => {
    axios
      .get(
        apiService.API_URL() +
        `/api/v1/get-user-points-by-team/${userId}/${teamId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        dispatch(eventActions.setPoints(response.data));
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const getWeeklyPoints = (userId) => {
    axios
      .get(apiService.API_URL() + `/api/v1/get-user-points/${userId}/${challengedata?.id}/week`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(eventActions.setWeeklyPoints(response.data));
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const getUserPoints = () => {
    setLoading(true);
    axios
      .get(apiService.API_URL() + `/api/v1/get-user-points/${user?.id}/${challengedata?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setLoading(false);
        dispatch(eventActions.setUserPoints(response.data));
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const checkinFileUpload = (event, id) => {
    setCheckinFile(event.target.files[0]);
    setCheckinFileID(id);
  };

  const postActionCheckin = (id, checkin, checkedInId) => {
    if(challengedata == null || challengedata?.status == 0){

    }
    else{
      const formData = new FormData();

      if (checkedInId !== null) {
        formData.append("check_in_id", checkedInId);
      }
      formData.append("user_id", user.id);
      formData.append("challenge_id", challengedata.id);
      formData.append("action_id", id);
      formData.append("target_achieved", 0);
      formData.append("checked_in", checkin);
      if (id == checkinFileID) {
        formData.append("file", checkinFile);
      }
      // 1 mb file restriction
      if (checkinFile != null && checkinFile.size >= 1048576) {
        // 1024 * 1024
        var bytes = checkinFile.size / 1048576 + " MB";
        if (bytes >= "2") {
          return toast.error("Please select below 2 MB files");
        }
      }
  
      setLoading(true);
      axios.post(apiService.API_URL() + "/api/v1/userChallengeActionCheckIn",formData,{ headers: { Authorization: `Bearer ${token}` } })
        .then(function (response) {
          setLoading(false);
          toast.success(response.data.msg);
          getUserActions();
          getUserPoints();
          getPoints(user?.id, teamId);
          getWeeklyPoints(user?.id);
          setCheckinFile(null);
          setCheckinFileID(null);
        })
        .catch(function (error) {
          setLoading(false);
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
        });
    }
   
  };

  const actionLearnMore = (actionId) => {
    setactionLearnMoreModel(!actionLearnMoreModel);
    setActionLearnMoreModelID(actionId);
  };

  const ActionCard = ({ data, index }) => {    
    return (
      <div className="d-flex flex-column align-items-center p-4 bg-white border m-2 flex-grow-1">
        <p>{data.action_category}</p>
        <div className="py-2 px-3 border bg-warning rounded-circle d-flex align-items-center justify-content-center text-white mb-3">
          {index + 1}
        </div>
        <p className="text-center text-uppercase fs-6 fw-bold">{data.action}</p>
        <p className="text-center flex-grow-1">{data.description}</p>

        <div
          className="input-group file-input-group mt-3 mb-4 cus_file_body"
          data-controller="file-input"
        >
          <label>
            <i className="fa fa-upload" aria-hidden="true"></i>
            <input
              type="file"
              className="form-control cus_file"
              onChange={(event) => checkinFileUpload(event, data.action_id)}
            />
          </label>
        </div>
        <div className="mt-2 mb-4">
          <small>Upload your video 320p</small>
        </div>
        <div className="pos_rel">
          <button
            className="bg-light py-1 border-none px-4 fs-sm cursor-pointer text-uppercase"
            type="button"
            onClick={() => actionLearnMore(data.action_id)}
          >
            Learn More
          </button>
          {data?.action_resource_link?.[0] != null &&
            actionLearnMoreModel &&
            actionLearnMoreModelID === data.action_id && (
              <div className="actionLearnMoreModel_Body">
                {data.action_resource_link.map((action, index) => {
                  return (
                    <ul key={index}>
                      <li>
                        <a href={"http://" + action} target="blank">
                          {action}
                        </a>
                        <br />
                      </li>
                    </ul>
                  );
                })}
              </div>
            )}
        </div>
        
        
        <button
          className="bg-primary py-2 border-none px-4 fs-sm fw-bold cursor-pointer text-uppercase text-white rounded-pill my-4"
          type="button"
          id={index+'CheckInBtn'+data.action_type} 
          onClick={() => data?.reached_user_maximum_daily_check_in_limit === false && postActionCheckin(data.action_id, 1, null)}
        >
          Check In
        </button>
        
        {data?.reached_user_maximum_daily_check_in_limit === true && 
          <ReactTooltip className="custom-tolltip" anchorId={index+'CheckInBtn'+data.action_type} place="bottom" content="The maximum daily user check-in limit has been reached. So, you can only check-in tomorrow." />
          }

        {challengedata == null || challengedata?.status == 0 && 
                <ReactTooltip anchorId={index+'CheckInBtn'+data.action_type} place="bottom" content="This challenge has been completed already. So, this action cannot be allowed." />
              }

      </div>
    );
  };

  const CompletedActionCard = ({ data, index }) => {
    return (
      <div
        className="d-flex flex-column align-items-center p-4 border m-2 bg-purple flex-grow-1"
        style={{ minHeight: "400px" }}
      >
        <p className="text-white">{data.action_category}</p>
        <div className="py-3 px-3 border bg-white rounded-circle d-flex align-items-center justify-content-center text-dark mb-3">
          <BsTrophy />
        </div>

        <p className="text-center fw-bold text-dark">
          PAY ATTENTION TO CURRENT EVENTS
        </p>

        <div className="flex-grow-1">
          <BsCheckLg size={28} className="ms-1" />
          <p className="fw-bold text-dark my-2">DONE</p>
        </div>

        <div
          className="d-flex flex-column align-items-center bg-purple-100 py-2 px-4 rounded cursor-pointer my-4"
          id={index+'CheckInBtn'+data.action_type} 
          onClick={() =>
            postActionCheckin(data.action_id, 0, data?.check_in_id)
          }
        >
          <IoReload />
          <p className="fw-bold text-dark my-2">UNDO</p>
        </div>

        {challengedata == null || challengedata?.status == 0 &&
                <ReactTooltip anchorId={index+'CheckInBtn'+data.action_type} place="bottom" content="This challenge has been completed already. So, this action cannot be allowed." />
              }

      </div>
    );
  };

  const navigateToActionCategory = () => {
    if (challengedata == null | challengedata?.status == 0) {

    }
    else {
      navigate("/challenge/allactioncategory");
    }
  };

  return (
    <div className="bg-pla-gray">
      <section className="bg-white rounded-3 shadow my-5 mx-2 mx-md-5 d-flex flex-column">
        <div className="py-2">
          <h3 className="text-center fs-2 fw-normal">Actions</h3>
        </div>
        <div
          className="bg-pla-gray m-4 d-flex align-items-center justify-content-center"
          style={{ cursor: challengedata == null | challengedata?.status == 0 ? "not-allowed" : "pointer", height: "200px" }}
        >
          <ImPlus
            id="dashboard_Actioncategory_menu"
            className="text-pla-gray display-6 animate-ease cursor-pointer"
            style={{ cursor: challengedata == null | challengedata?.status == 0 ? "not-allowed" : "pointer" }}
            onClick={() => navigateToActionCategory()}
          />

          {challengedata == null || challengedata?.status == 0 &&
            <ReactTooltip anchorId="dashboard_Actioncategory_menu" place="bottom" content="This challenge has been completed already. So, this action cannot be allowed." />
          }

        </div>
      </section>

      {actions && actions.daily && (
        <section className="bg-white rounded-3 shadow my-5 mx-2 mx-md-5 d-flex flex-column">
          <div className="py-2">
            <h3 className="text-center fs-2 fw-normal">Daily Actions</h3>
          </div>
          <div className="bg-pla-gray m-4 row">
            {actions.daily.map((action, index) => {
              return (
                <div
                  className="col-md-3 col-sm-12 col-xs-12 my-3 tab_wid_100 d-flex flex-column "
                  key={index}
                >
                  {action.check_in ? (
                    <CompletedActionCard data={action} index={index} />
                  ) : (
                    <ActionCard data={action} index={index} />
                  )}
                </div>
              );
            })}
          </div>
        </section>
      )}

      {actions && actions.one_time && (
        <section className="bg-white rounded-3 shadow my-5 mx-2 mx-md-5 d-flex flex-column">
          <div className="py-2">
            <h3 className="text-center fs-2 fw-normal">One Time Actions</h3>
          </div>
          <div className="bg-pla-gray m-4 row">
            {actions.one_time.map((action, index) => {
              return (
                <div
                  className="col-md-3 col-sm-12 col-xs-12 my-3 tab_wid_100 d-flex flex-column "
                  key={index}
                >
                  {action.check_in ? (
                    <CompletedActionCard data={action} index={index} />
                  ) : (
                    <ActionCard data={action} index={index} />
                  )}
                </div>
              );
            })}
          </div>
        </section>
      )}

      {points && points.points_details && (
        <section className="bg-white rounded-3 shadow my-5 mx-2 mx-md-5 d-flex flex-column">
          <div className="py-2">
            <h3 className="text-center fs-2 fw-normal">POINTS HISTORY</h3>
            <h3 className="text-center fs-6 fw-normal text-uppercase text-primary ">
              Showing All Points
            </h3>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className=" m-4 ">
              {points.points_details.map((point, index) => {
                return (
                  <div className="d-flex gap-5 align-items-center" key={index}>
                    <p className="fs-3">+ {point.total_points}</p>
                    <p>{point.action_description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}

      <div className="p-4"></div>

      <ToastContainer />
      <LoadingSpinner hidden={!loading} />
    </div>
  );
};

export default AdminDashboard;
