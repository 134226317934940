import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import { toast, ToastContainer } from "react-toastify";

import "./footer.css";

import sponsoredlogo from "../../assets/images/waica.png";

function Footer() {
  const navigate = useNavigate();
  const joinChallenge = () => {
    navigate("/challenge/allactioncategory");
  };

  const [isShowToPaymentGatewayModel, setToPaymentGatewayModel] =
    useState(false);
  const [paymentValues, setpaymentValues] = useState(null);
  const [isShowPaymentBtn, setShowPaymentBtn] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  var today = new Date();
  var year = today.getFullYear();

  const goToStaticPage = () => {
    navigate("/");
  };

  // payment start
  const closePopup = () => {
    setToPaymentGatewayModel(false);
    setpaymentValues(null);
    setShowPaymentBtn(false);
  };
  const isShowToPaymentGateway = () => {
    setToPaymentGatewayModel(true);
  };

  const onPaymentSumbit = (data) => {
    var formvalue = {
      name: data.customername,
      emailId: data.EmailId,
      phoneNo: data.PhoneNumber,
      donateAmt: data.Amount,
    };
    setpaymentValues(formvalue);
    setShowPaymentBtn(true);
  };

  const config = {
    public_key: "FLWPUBK-90a0370a8bdd854c9019b32a7b958230-X",
    tx_ref: Date.now(),
    amount:
      paymentValues?.donateAmt != null ? Number(paymentValues.donateAmt) : 0,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: paymentValues?.emailId != null ? paymentValues.emailId : "",
      phonenumber: paymentValues?.phoneNo != null ? paymentValues.phoneNo : "",
      name: paymentValues?.name != null ? paymentValues.name : "",
    },
    customizations: {
      title: "plafera",
      description: "Donation",
      logo: "https://plafera.org/assets/images/image.png",
    },
  };

  const fwConfig = {
    ...config,
    text: "Pay Now...",
    callback: (response) => {
      closePaymentModal(); // this will close the modal programmatically
      setToPaymentGatewayModel(false);
      setpaymentValues(null);
      setShowPaymentBtn(false);
      toast.success("Payment is" + " " + response.status);
    },
    onClose: () => {},
  };

  // payment end

  return (
    <footer className="footer">
      {/* <div className="center">
        <div className="row">
          <div className="col-md-5 col-sm-12 col-xs-12">
            <h4>create more environmental + social good</h4>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 curvedarrowbody">
            <img
              src={"/assets/images/curvedarrow.svg"}
              alt="curvedarrow"
              className="curvedarrow"
            />
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="btn_group">
              <img
                src={"/assets/images/btn_highlighter1.svg"}
                alt="curvedarrow"
                className="btn_decorator1"
              />
              <button onClick={joinChallenge}>Join Plafera Challenge</button>
              <img
                src={"/assets/images/btn_highlighter1.svg"}
                alt="curvedarrow"
                className="btn_decorator2"
              />
            </div>

          </div>
        </div>
      </div> */}
      <div className="copy_right">
        <div className="left">
          <img
            src={"/assets/images/image.png"}
            alt="logo"
            className="cursor-pointer"
            onClick={goToStaticPage}
          />
          <span className="logo_txt cursor-pointer" onClick={goToStaticPage}>
            PLAFERA
          </span>
          <span className="copy_right_txt">Copyright by {year} Plafera</span>
        </div>
        <div className="flex_grow1"></div>
        <div className="footer_sponsor">
          <img src={sponsoredlogo} alt="logo" className="mob_view" />
          <p className="copy_right_txt">Plafera App sponsored by WAICA Re</p>
          <img src={sponsoredlogo} alt="logo" className="web_view" />
        </div>
        <div className="right">
          <div className="container">
            <ul>
              <li onClick={isShowToPaymentGateway}>
                <i className="fa fa-id-card-o mob_icn" aria-hidden="true"></i>{" "}
                <span>DONATE</span>
              </li>
              <li onClick={goToStaticPage}>
                {/* <a href="/"> */}
                <i className="fa fa-home mob_icn" aria-hidden="true"></i>{" "}
                <span>HOME</span>
                {/* </a> */}
              </li>
              <li>
                <a href="https://shop.plafera.org/" target="blank">
                  <i
                    className="fa fa-shopping-cart mob_icn"
                    aria-hidden="true"
                  ></i>{" "}
                  <span> STORE </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* payment popup start */}
      {isShowToPaymentGatewayModel && (
        <div className="popup_body">
          <div className="popup_content">
            <div className="popup_close" onClick={closePopup}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5 className="payment_title text-center mt-5 mb-5">DONATE</h5>
              </div>
            </div>

            {isShowPaymentBtn == true && (
              <div className="payment_subtitle">Proceed To Pay...</div>
            )}
            <form className="row" onSubmit={handleSubmit(onPaymentSumbit)}>
              {isShowPaymentBtn == false && (
                <>
                  <div className="col-md-6">
                    <label htmlFor="Name" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      {...register("customername", { required: true })}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="EmailId" className="form-label">
                      Email Id <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="EmailId"
                      {...register("EmailId", { required: true })}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="PhoneNumber" className="form-label">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PhoneNumber"
                      {...register("PhoneNumber", { required: true })}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="Amount" className="form-label">
                      Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="Amount"
                      {...register("Amount", { required: true })}
                    />
                  </div>
                </>
              )}

              {isShowPaymentBtn == false && (
                <div className="col-md-12 colsm-12 col-xs-12">
                  <div className="mar_auto mt-4 mb-4">
                    <button className="theme_btn">Submit</button>
                  </div>
                </div>
              )}

              {isShowPaymentBtn == true && (
                <div className="col-md-12 colsm-12 col-xs-12">
                  <div className="mar_auto mt-4 mb-4 payment_btn">
                    <FlutterWaveButton {...fwConfig} />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
