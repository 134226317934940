import React, { useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const LoadingSpinner = (props) => {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#5E29EB");
  return (
    <div
      className={`min-vw-100 min-vh-100 animated position-fixed  start-0 top-0 d-flex justify-content-center align-items-center  ${
        props.hidden && "d-none"
      }`}
      style={{ zIndex: 50 }}
      id="modal-id"
    >
      <div
        className="position-absolute bg-dark-80 "
        style={{ zIndex: 0, inset: 0 }}
      ></div>
      <div
        style={{ zIndex: 50 }}
        className="min-vw-100 min-vh-100 animated position-fixed  start-0 top-0 d-flex justify-content-center align-items-center "
      >
        <ScaleLoader color={color} loading={loading} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
