import React, { useState, useEffect, useRef } from 'react';
import Footer from '../../components/footer-layout/footer';
import HeaderLayout from '../../components/header-layout/header-layout';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import apiService from '../../services/api.service';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const AboutScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  //Basker Quiz start
  const [isShowFirstQuestion, setIsShowFirstQuestion] = useState(true);
  const [isShowSecondQuestion, setIsShowSecondQuestion] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [firstQuestion, setFirstQuestion] = useState({});
  const [secondQuestion, setSecondQuestion] = useState({});
  const [answer1TimeTaken, setAnswer1TimeTaken] = useState(null);
  const [answer2TimeTaken, setAnswer2TimeTaken] = useState(null);
  const [seconds, setSeconds] = useState(15);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showTandC, setShowTandC] = useState(false);

  const handleShow = () => {
    if (isQuizAvailable === true) {
      setLoading(true);
      getQuizQuestion();
    }
  };
  const goToSignIn = () => {
    navigate('/sign-in');
  };

  const handleTandCShow = () => setShowTandC(true);
  const handleTandCClose = () => setShowTandC(false);

  const modalRef = useRef(null);
  const [resetTimer, setResetTimer] = useState();
  const [isQuizAvailable, setIsQuizAvailable] = useState(false);

  const [quizIsSubmitted, setQuizIsSubmitted] = useState(false);
  const [quizIsSubmittedModelOpen, setQuizIsSubmittedModelOpen] =
    useState(false);
  //Basker Quiz end

  useEffect(() => {
    if (show) {
      if (isShowFirstQuestion === true) {
        setAnswer1TimeTaken(seconds);
      }

      if (isShowSecondQuestion === true) {
        setAnswer2TimeTaken(seconds);
      }
      setTimer();
      if (seconds === 0) {
        handleQuizSubmit();
      }
    }
  }, [show, seconds]);

  useEffect(() => {
    if (user) {
      setLoading(true);
      getTodayQuizStatus();
    }
  }, [user]);

  const closePopup = () => {
    setQuizIsSubmittedModelOpen(false);
  };

  // Quiz start

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleQuizSubmit = () => {
    clearTimeout(resetTimer);
    if (isShowFirstQuestion && Object.keys(secondQuestion).length === 0) {
      modalRef.current.click();
    }
    if (isShowFirstQuestion) {
      setIsShowSecondQuestion(true);
      setIsShowFirstQuestion(false);
      setSeconds(15);
    }

    if (isShowSecondQuestion) {
      setAnswer2TimeTaken(seconds);
      setIsShowSecondQuestion(false);
      modalRef.current.click();
    }
  };

  const onSubmit = async (data) => {
    let time_taken_question_1 = 0;
    let time_taken_question_2 = 0;

    if (answer1TimeTaken !== null) {
      time_taken_question_1 = 12 - answer1TimeTaken;
    }

    if (answer2TimeTaken !== null) {
      time_taken_question_2 = 12 - answer2TimeTaken;
    }

    const formData = new FormData();
    formData.append('first_question_id', data.first_question);
    formData.append('first_question_answer', data.first_question_answer);
    formData.append('second_question_id', data.second_question);
    formData.append('second_question_answer', data.second_question_answer);
    formData.append('time_taken_question_1', time_taken_question_1);
    formData.append('time_taken_question_2', time_taken_question_2);
    formData.append("user_id", user.id);
    
    axios
      .post(apiService.API_URL() + '/api/v1/save-quiz', formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.data.code == 200) {
          toast.success('Thank you for participating in the quiz');
          setIsQuizAvailable(false);
          setQuizIsSubmitted(true);
          setQuizIsSubmittedModelOpen(true);
        }
        if (response.data.code == 500) {
          toast.error(response.message);
          setIsQuizAvailable(true);
        }
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const getTodayQuizStatus = () => {
    setLoading(true);
    axios
      .get(apiService.API_URL() + `/api/v1/quiz-status?user_id=${user.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.data.is_elgible_quiz) {
          setIsQuizAvailable(true);
          setQuizIsSubmitted(false);
        } else if (response.data.is_elgible_quiz === false) {
          setIsQuizAvailable(false);
          setQuizIsSubmitted(true);
        }

        if (response.data.is_attended_quiz_this_week) {
          toast.info("This week's quiz result will be announced tomorrow");
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const getQuizQuestion = () => {
    setLoading(true);
    axios
      .get(apiService.API_URL() + `/api/v1/quiz?user_id=${user.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.data.quiz.length > 0) {
          setFirstQuestion(response.data.quiz[0]);
        }
        if (response.data.quiz.length > 1) {
          setSecondQuestion(response.data.quiz[1]);
        }
        setLoading(false);
        setShow(true);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const setTimer = () => {
    setResetTimer(setTimeout(() => setSeconds(seconds - 1), 1000));
  };

  const showQuizsSubmitted = () => {
    if (quizIsSubmitted == true) {
      setQuizIsSubmittedModelOpen(true);
    }
  };
  // Quiz end

  return (
    <>
      <HeaderLayout />
      <ToastContainer />
      <div className="container">

        <div className='banner mt-4 mb-4'>
          <img src={require('../../assets/images/AboutUs-header-banner-image.png')} />
        </div>

        <p className="fs-6">
          Plastic Free Rainy Season Challenge (Plafera Challenge) is a
          citizen-led voluntary challenge that encourages millions of people to
          join in the fight against plastic pollution, resulting in cleaner
          streets, drainage systems, oceans, reduced urban flooding, and
          beautiful communities.
        </p>
        <br />

        <h5 className="my-3 fw-bold">About Plafera Challenge</h5>
        <p className="fs-6">
          Plastic Free Rainy Season Sensitization Initiative (Plafera) believes
          that given the projected substantial increase in rainfall because of
          climate change, as well as the increased usage of single-use plastic
          items for takeaway products in recent years; people discard these
          items indiscriminately, thus, they clog drainages and increasing the
          risk of urban flooding.
        </p>

        <p className="fs-6">
          This has given impetus to implement a citizen-led voluntary challenge
          to reduce plastic waste and pollution in Nigeria. The Plastic Free
          Rainy Season Challenge (Plafera Challenge) encourages individuals to
          reduce their single-use plastics and foster healthy behaviours over a
          three-month period each year, reducing plastic pollution, which
          increases the risk of urban flooding in our cities.
        </p>
        <p className="fs-6">
          Plafera Challenge is Ejike C. Nwankwo's award-winning WAICA Re 2021
          CSR Competition project, which gained international recognition during
          the African Insurance Organization's 47th General Assembly in
          September 2021.
        </p>
        <p className="fs-6">
          The goal is to inspire people to adopt small, everyday actions that
          would keep single-use plastics out of landfills and lessen the risk of
          plastic polluting the environment or worsening urban flooding.
        </p>
        <p className="fs-6">
          Apart from using Plafera Challenge to transition our nation away from
          the single-use plastic menace, Plafera Progressive Web App provides a
          platform for local manufacturers of eco-friendly alternatives to
          single-use plastic items to showcase their products.{' '}
          <a href="https://shop.plafera.org/" target="_blank">
            Plafera Shop
          </a>
        </p>
        <p className="fs-6">
          The Plafera Quiz is yet another important feature of the Plafera App.
          By taking the Plafera quiz, you can test your knowledge about the
          environment and its problems, such as pollution, global warming, waste
          management, climate change, biodiversity, the ecosystem, etc.
          <div
            onClick={
              user === null
                ? goToSignIn
                : quizIsSubmitted === true
                  ? showQuizsSubmitted
                  : handleShow
            }
          >
            {user === null ? (
              <p
                className="fs-6 cursor-pointer d-inline"
                style={{ color: '#0067ff' }}
              >
                Please login to view today's quiz
              </p>
            ) : (
              <p
                className="fs-6 cursor-pointer d-inline"
                style={{ color: '#0067ff' }}
              >
                Please click to view today's quiz
              </p>
            )}
          </div>
        </p>
        <p className="fs-6">
          On November 30, 2021, the Plastic Free Rainy Season Sensitization
          Initiative (Plafera) was born from that ground-breaking idea. Within
          weeks, the effort had begun to reshape residents' attitudes about
          decreasing their plastic footprint not only in Nigeria but across West
          Africa and around the world.
        </p>
        <br />

        <h5 className="my-3 fw-bold">Project Team</h5>

        <div className='row'>
          <div className='col-md-4 mt-3 mb-3'>
            <div className="card" style={{ width: '18re,' }}>
              <img className="card-img-top fit_card_img" src={require('../../assets/images/1_Ejike.png')} alt="Project Manager" />
              <div className="card-body">
                <h5 className="card-title">Ejike C. Nwankwo</h5>
                <p className="card-text">Project Manager</p>
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-3 mb-3'>
            <div className="card" style={{ width: '18re,' }}>
              <img className="card-img-top fit_card_img" src={require('../../assets/images/0_BasseyEnwongo.jpg')} alt="Head, Brand Marketing" />
              <div className="card-body">
                <h5 className="card-title">Enwongo Bassey</h5>
                <p className="card-text">Head, Brand Marketing</p>
              </div>
            </div>
          </div>
          <div className='col-md-4 mt-3 mb-3'>
            <div className="card" style={{ width: '18re,' }}>
              <img className="card-img-top fit_card_img" src={require('../../assets/images/gideon-removebg-preview.png')} alt="Project Manager" />
              <div className="card-body">
                <h5 className="card-title">Gideon Ukomi</h5>
                <p className="card-text">Account Officer</p>
              </div>
            </div>
          </div>
        </div>

        <h5 className="my-3 fw-bold">Sponsor - Plafera App Development</h5>
        <p className="fs-6">
          WAICA Re is one of the fastest-growing reinsurance companies in
          Africa. WAICA Re is headquartered in Freetown (Sierra Leone) and
          operates in six countries in Africa and in the United Kingdom. As the
          winning project in the CSR competition, the Plafera App development
          was sponsored by WAICA Re.
        </p>
        <br />

        <h5 className="my-3 fw-bold">Partner with Plafera</h5>
        <p className="fs-6">
          Help end plastic pollution. Businesses interested in forming a
          partnership aligning with the global agenda of ending the plastic
          menace can contact us at{' '}
          <a href="mailto:partnerships@plafera.org">
            partnerships@plafera.org.
          </a>
        </p>
        <br />

        <div className="row">
          <div className="col-md-12">
            <h5 className="my-3 fw-bold">Why partner with Us?</h5>
            <p className="fs-6">
              Plafera is a non-profit organization that inspires people to do
              small, everyday actions to keep single-use plastics out of
              landfills, reducing the risk of plastic pollution and worsening
              urban flooding. We share Plastic Free solutions with individuals
              and organizations so that we can all work together to end plastic
              pollution and live in a healthy environment.
            </p>
            <br />

            <h5 className="my-3 fw-bold">
              Share your commitment to sustainability
            </h5>
            <p className="fs-6">
              Share your progress, successes, and insights with fellow Plafera
              Challengers during the Plafera Challenge. Change is a bit simpler
              — and more enjoyable — when you combine team inspiration,
              friendship, and friendly competition.
            </p>
            <br />

            <h5 className="my-3 fw-bold">Contact Us</h5>

            <p className="fs-6 fw-bold">Plafera Challenge</p>

            <p className="fs-6">
              <a href="mailto:info@plafera.org">
                <i className="fa fa-envelope-o contact_icn" aria-hidden="true" style={{ color: "#AC262C" }}></i>
              </a>
              <span className="fw-bold">Email: </span>
              <span className='mutted-text'>info@plafera.org</span>
            </p>

            <p className="fs-6">
              <address>
                <span className="fw-bold">Address:</span> 1, Murtala Muhammed Dr
                (former Bank Road), Ikoyi, Lagos.
              </address>
            </p>
            <br />

            <p className="fs-6 fw-bold">Follow us on:</p>

            <p className="fs-6">
              <a href="https://www.linkedin.com/company/plafera-challenge" target="_blank">
                <i className="fa fa-linkedin-square contact_icn" aria-hidden="true" style={{ color: "#0A66C2" }}></i>
              </a>
              <span className="fw-bold">LinkedIn:</span>{' '}
              <span className='mutted-text'>@plafera-challenge</span>
            </p>

            <p className="fs-6">
              <a href="https://www.facebook.com/plaferachallenge" target="_blank">
                <i className="fa fa-facebook-official contact_icn" aria-hidden="true" style={{ color: "#1877F2" }}></i>
              </a>
              <span className="fw-bold">Facebook:</span>{' '}
              <span className='mutted-text'>@plaferachallenge</span>
            </p>

            <p className="fs-6">
              <a href="https://twitter.com/PlaferaC" target="_blank">
                <i className="fa fa-twitter-square contact_icn" aria-hidden="true" style={{ color: "#1C9BE9" }}></i>
              </a>
              <span className="fw-bold">Twitter:</span>{' '}
              <span className='mutted-text'>@PlaferaC</span>
            </p>

            <p className="fs-6">
            <a href="https://www.instagram.com/plaferachallenge" target="_blank">
              <i className="fa fa-instagram contact_icn" aria-hidden="true" style={{ color: "#FED154" }}></i>
              </a>
              <span className="fw-bold">Instagram:</span>{' '}
              <span className='mutted-text'>@plaferachallenge</span>
            </p>
            <br />
          </div>
        </div>
      </div>

      {/* Quizs start */}
      {quizIsSubmittedModelOpen == false && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <>
            <Modal.Header>
              <Modal.Title>Today Quiz </Modal.Title>
              <span className="float-right">Time Left: {seconds}</span>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit(onSubmit)}>
                {firstQuestion && isShowFirstQuestion && (
                  <div className="m-4 row">
                    <h5 className="mb-3">First Question</h5>
                    <div className="bg-pla-gray row">
                      <div className={`my-2`}>
                        <label
                          htmlFor="terms-and-conditions"
                          className="form-label text-dark text-capitalize"
                        >
                          {firstQuestion.title}
                        </label>

                        {firstQuestion.options &&
                          firstQuestion.options.map((option, index) => {
                            return (
                              <div className="form-check my-2">
                                <input
                                  className="form-check-input"
                                  type="hidden"
                                  {...register(`first_question`)}
                                  value={firstQuestion.id}
                                />

                                <input
                                  className="form-check-input"
                                  type="hidden"
                                  {...register(`time_taken_first_question`)}
                                  value={answer1TimeTaken}
                                />

                                {firstQuestion.answer_type === 'one' && (
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    {...register(`first_question_answer`)}
                                    value={option.id}
                                  />
                                )}

                                {firstQuestion.answer_type === 'multiple' && (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    {...register(`first_question_answer`)}
                                    value={option.id}
                                  />
                                )}
                                <label
                                  className="form-check-label text-black"
                                  htmlFor="firstChallenge"
                                >
                                  {option.title}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}

                {secondQuestion && isShowSecondQuestion && (
                  <div className="m-4 row">
                    <h5 className="mb-3">Second Question</h5>
                    <div className="bg-pla-gray row">
                      <div className={`my-2`}>
                        <input
                          className="form-check-input"
                          type="hidden"
                          {...register(`second_question`)}
                          value={secondQuestion.id}
                        />

                        <label
                          htmlFor="terms-and-conditions"
                          className="form-label text-dark text-capitalize"
                        >
                          {secondQuestion.title}
                        </label>

                        {secondQuestion.options &&
                          secondQuestion.options.map((option, index) => {
                            return (
                              <div className="form-check my-2">
                                {secondQuestion.answer_type === 'one' && (
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    {...register(`second_question_answer`)}
                                    value={option.id}
                                  />
                                )}

                                {secondQuestion.answer_type === 'multiple' && (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    {...register(`second_question_answer`)}
                                    value={option.id}
                                  />
                                )}

                                <label
                                  className="form-check-label text-black"
                                  htmlFor="firstChallenge"
                                >
                                  {option.title}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}

                <Button
                  variant="success"
                  type="submit"
                  className="d-none"
                  ref={modalRef}
                  onClick={handleClose}
                >
                  save
                </Button>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleQuizSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </>
        </Modal>
      )}

      {quizIsSubmittedModelOpen == true && (
        <>
          <div className="popup_body">
            <div className="popup_content">
              <div className="popup_close" onClick={closePopup}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h5 className="payment_title text-center mt-4 mb-4">
                    Quiz is Done....
                  </h5>
                </div>
                <div className="col-md-12 mt-5 mb-4 text-center">
                  You have Done your Quiz, Please wait for the next announcement
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Quizs end */}
      <Footer />
    </>
  );
};

export default AboutScreen;
