import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import * as authActions from "../../store/actions/auth";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
// import stateOptions
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../hooks/use-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import apiService from "../../services/api.service";
import Select from "react-select";
import LoadingSpinner from "../../components/LoadingSpinner";
import HeaderLayout from "../../components/header-layout/header-layout";
import Footer from "../../components/footer-layout/footer";
import FacebookLoginComponent from "../../components/social-logins/facebook-login/FacebookLoginComponent";
import LinkedinLoginComponent from "../../components/social-logins/linkedin-login/LinkedinLoginComponent";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import TermsAndConditions from "../../components/modals/TermsAndConditions";

const UserSignupScreen = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const searchParams = useSearchParams();
  const challengeID = searchParams[0].get("challenge");
  const teamID = searchParams[0].get("team");
  const referralCode = searchParams[0].get("referral_code");
  const [showPassword, setShowPassword] = useState(false);
  const [notLoading, setNotLoading] = useState(true);
  const isNotEmpty = (value) => value.trim() !== "";
  const isEmail = (value) =>
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  // const passwordMatches = (value) => value === passwordValue;
  const passwordCheck = (value) => value.trim() !== "" && value.length >= 8;
  const [countries, setCountries] = useState([]);

  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  const [postalCode, setPostalCode] = useState([]);

  const [GenderHasError, setGenderHasError] = useState(null);
  const [countriesHasError, setCountriesHasError] = useState(null);
  const [stateHasError, setStateHasError] = useState(null);
  const [cityHasError, setCityHasError] = useState(null);
  const [postalCodeHasError, setPostalCodeHasError] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedPostalCode, setSelectedPostalCode] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Gender = [
    { value: "Gen_One", label: "Male" },
    { value: "Gen_Two", label: "Female" },
  ];
  useEffect(() => {
    countriesData();
  }, []);

  const changeKey = (arrayObj) => {
    for (var i = 0; i < arrayObj.length; i++) {
      arrayObj[i].value = arrayObj[i]["id"];
      arrayObj[i].label = arrayObj[i]["name"];
      if (arrayObj[i]["name"] == "Nigeria") {
        setSelectedCountry(arrayObj[i]);
      }
      delete arrayObj[i].id;
      delete arrayObj[i].name;
    }
  };

  const onChangeGender = async (value) => {
    setSelectedGender(value.label);
    setGenderHasError(false);
  };

  //New update
  const onChangeCountry = async (value) => {
    setSelectedCountry(value);
    setSelectedState(null);

    setCountriesHasError(false);
    getState(value.value);

    if (value.label != "Nigeria") {
      setSelectedArea(null);
      setValue("postal_code", null);
    }
  };

  const getState = async (value) => {
    axios
      .get(apiService.API_URL() + `/api/v1/states/${value}`)
      .then(function (response) {
        changeKey(response.data.data);
        setState(response.data.data);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const countriesData = async () => {
    axios
      .get(apiService.API_URL() + "/api/v1/countries")
      .then(function (response) {
        changeKey(response.data.data);
        setCountries(response.data.data);
        getState(160);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const onChangeState = async (value) => {
    setSelectedState(value);
    setSelectedCity(null);
    setStateHasError(false);
    setValue("postal_code", null);

    axios
      .get(apiService.API_URL() + `/api/v1/cities/${value.value}`)
      .then(function (response) {
        // changeKey(response.data.data);
        setCity(response.data.data);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const onChangeCity = async (value) => {
    setSelectedCity(value);
    setCityHasError(false);
    setValue("postal_code", null);

    if (selectedCountry.label === "Nigeria") {
      axios
        .get(apiService.API_URL() + `/api/v1/postal-code/${value.value}`)
        .then(function (response) {
          setArea(response.data.post_code);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setNotLoading(true);
        });
    }
  };

  const onChangeArea = async (value) => {
    setSelectedArea(value);
    setValue("postal_code", value.post_code);
  };

  // New update end

  let formIsValid = false;

  const onSignup = async (data) => {
    debugger;
    var formValue = {
      name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password: data.password,
      phone: data.phone,
      zip_code: data.postal_code,
    };

    // New changes
    if (selectedArea != null) {
      formValue["local_government_area"] = selectedArea.value.toString();
      // formValue['zip_code'] = data.postal_code;
    }
    // End new changes

    // if (selectedCountry.label == 'Nigeria') {
    //   formValue['zip_code'] = selectedPostalCode.value.toString();
    // }

    if (selectedCountry === null || !selectedCountry) {
      setCountriesHasError(true);
      return;
    } else {
      setCountriesHasError(false);
      formValue["country_id"] = selectedCountry.value.toString();
    }
    if (selectedState === null || !selectedState) {
      setStateHasError(true);
      return;
    } else {
      setStateHasError(false);
      formValue["state_id"] = selectedState.value.toString();
    }
    if (selectedCountry.label === "Nigeria") {
      if (selectedCity === null || !selectedCity) {
        setCityHasError(true);
        return;
      } else {
        setCityHasError(false);
        formValue["city"] = selectedCity.value.toString();
      }
    }
    if (selectedCity !== null) {
      formValue["city"] = selectedCity.value.toString();
    }
    if (selectedGender === null || !selectedGender) {
      setGenderHasError(true);
      return;
    } else {
      setGenderHasError(false);
      formValue["gender"] = selectedGender;
    }
    if (teamID) {
      formValue["team"] = teamID;
    }
    if (challengeID) {
      formValue["challenge"] = challengeID;
    }
    if (referralCode) {
      formValue["referral_code"] = referralCode;
    }
    if (data.password) {
      setPasswordValidationAlert(true);
      var p = data.password,
        errors = [];
      var specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
      var checkForSpecialChar = function (string) {
        for (let i = 0; i < specialChars.length; i++) {
          if (string.indexOf(specialChars[i]) > -1) {
            return true
          }
        }
        return false;
      }

      var str = p;

      if (p.length < 8) {
        setPasswordValidationAlert(true);
        setPasswordValidationAlertMsg('characters');
        return;
      }
      if (p.search(/[a-z]/i) < 0) {
        setPasswordValidationAlert(true);
        setPasswordValidationAlertMsg('letter');
        return;
      }
      if (p.search(/[0-9]/) < 0) {
        setPasswordValidationAlert(true);
        setPasswordValidationAlertMsg('digit');
        return;
      }

      if (p.length > 8) {
        formValue["password"] = data.password;
      }

      if (errors.length > 0) {
        alert(errors.join("\n"));
        return false;
      }

      if (checkForSpecialChar(str)) {
        // setPasswordValidationAlert(false);
        // setPasswordValidationAlertMsg(null);
      } else {
        setPasswordValidationAlert(true);
        setPasswordValidationAlertMsg('splcharcter');
        return;
      }
    }
    setNotLoading(false);
    debugger;
    axios
      .post(apiService.API_URL() + "/api/v1/register", formValue)
      .then(function (response) {
        setNotLoading(true);
        navigate("/confirm-signup", {
          state: { id: response.data.userDetails.id },
        });
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const [captcha, setCaptcha] = useState("");
  const [verified, setVerified] = useState(false);
  const [passwordValidationAlert, setPasswordValidationAlert] = useState(false);
  const [passwordValidationAlertMsg, setPasswordValidationAlertMsg] = useState(null);

  const onSubmit = () => {
    debugger;
    if (validateCaptcha(captcha) == true) {
      setVerified(true);
      setCaptcha("");
      loadCaptchaEnginge(6);
    } else {
      setVerified(false);
      setCaptcha("");
      loadCaptchaEnginge(6);
    }
  };

  const passwordValidation = (e) => {
    debugger;
    setPasswordValidationAlert(true);

    var p = e.target.value,
      errors = [];

    var specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
    var checkForSpecialChar = function (string) {
      for (let i = 0; i < specialChars.length; i++) {
        if (string.indexOf(specialChars[i]) > -1) {
          return true
        }
      }
      return false;
    }

    var str = p;

    if (p.length < 8) {
      setPasswordValidationAlert(true);
      setPasswordValidationAlertMsg('characters');
    }
    if (p.search(/[a-z]/i) < 0) {
      setPasswordValidationAlert(true);
      setPasswordValidationAlertMsg('letter');
    }
    if (p.search(/[0-9]/) < 0) {
      setPasswordValidationAlert(true);
      setPasswordValidationAlertMsg('digit');
    }

    if (p.length > 8) {
      setPasswordValidationAlert(false);
      setPasswordValidationAlertMsg(null);
    }

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return false;
    }

    if (checkForSpecialChar(str)) {
      // setPasswordValidationAlert(false);
      // setPasswordValidationAlertMsg(null);
    } else {
      setPasswordValidationAlert(true);
      setPasswordValidationAlertMsg('splcharcter');
    }


  }
  return (
    <>
      <HeaderLayout />
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="mt-4 pt-5 mt-md-0 pt-md-0"></div>
        <LoadingSpinner hidden={notLoading} />
        <ToastContainer />

        <div className="text-center mt-3">
          <h2>Sign Up</h2>
          <p className="text-pla-gray">Create your Plafera Challenge account</p>
        </div>
        <div className="container auth-form">
          <form className="row g-3" onSubmit={handleSubmit(onSignup)}>
            <div className="col-md-6">
              <label htmlFor="firstName" className="form-label">
                First Name <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                className="form-control"
                id="firstName"
                {...register("firstName", { required: true })}
              />
              {errors.firstName && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the first name.
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="form-label">
                Last Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                {...register("lastName", { required: true })}
              />

              {errors.lastName && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the last name.
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phonenumber" className="form-label">
                Phone Number <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="phonenumber"
                {...register("phone", {
                  required: true,
                })}
              />

              {errors.phone && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the phone Number.
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="gender" className="form-label">
                Gender <span className="text-danger">*</span>
              </label>
              <Select
                defaultValue={selectedGender}
                onChange={onChangeGender}
                options={Gender}
                className="react-select-container"
              />
              {GenderHasError && (
                <div className="text-danger my-2 fst-italic">
                  This field is required!
                </div>
              )}
            </div>
            {countries.length > 0 && (
              <>
                <div className="col-md-6">
                  <label htmlFor="country" className="form-label">
                    Country <span className="text-danger">*</span>
                  </label>
                  <Select
                    defaultValue={selectedCountry}
                    onChange={onChangeCountry}
                    options={countries}
                    className="react-select-container"
                    name="country"
                  />
                  {errors.country && (
                    <div className="text-danger my-2 fst-italic">
                      Please select your country.
                    </div>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="state" className="form-label">
                    State <span className="text-danger">*</span>
                  </label>

                  {selectedCountry.label !== "Nigeria" && (
                    <>
                      <CreatableSelect
                        isClearable
                        options={state}
                        onChange={onChangeState}
                        defaultValue={selectedState}
                      />
                      <span className="help_text">Select or Create State</span>
                    </>
                  )}
                  {selectedCountry.label === "Nigeria" && (
                    <Select
                      defaultValue={selectedState}
                      onChange={onChangeState}
                      options={state}
                      className="react-select-container"
                    />
                  )}

                  {/* <Select
                    defaultValue={selectedState}
                    onChange={onChangeState}
                    options={state}
                    className="react-select-container"
                    name="state"
                  /> */}
                  {stateHasError && (
                    <div className="text-danger my-2 fst-italic">
                      Please select your state.
                    </div>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="city" className="form-label">
                    {selectedCountry.label === "Nigeria" ? "City/LGA" : "City"}{" "}
                    <span className="text-danger">
                      {selectedCountry.label === "Nigeria" && "*"}
                    </span>
                  </label>
                  {selectedCountry.label !== "Nigeria" && (
                    <>
                      <CreatableSelect
                        isClearable
                        options={city}
                        onChange={onChangeCity}
                        defaultValue={selectedCity}
                      />
                      <span className="help_text">Select or Create City</span>
                    </>
                  )}
                  {selectedCountry.label === "Nigeria" && (
                    <Select
                      defaultValue={selectedCity}
                      onChange={onChangeCity}
                      options={city}
                      className="react-select-container"
                    />
                  )}
                  {cityHasError && (
                    <div className="text-danger my-2 fst-italic">
                      Please select the city.
                    </div>
                  )}
                </div>
                {selectedCountry.label === "Nigeria" && (
                  <>
                    <div className="col-md-6">
                      <label
                        htmlFor="local_government_area"
                        className="form-label"
                      >
                        Locality <span className="text-danger">*</span>
                      </label>

                      <Select
                        defaultValue={selectedArea}
                        onChange={onChangeArea}
                        options={area}
                        className="react-select-container"
                      />
                      {cityHasError && (
                        <div className="text-danger my-2 fst-italic">
                          Please select the local government area.
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="postal_code" className="form-label">
                        Postal Code <span className="text-danger">*</span>
                      </label>
                      <input
                        type="test"
                        className="form-control"
                        placeholder="Post code"
                        id="postCode"
                        {...register("postal_code")}
                      // disabled
                      />
                    </div>
                  </>
                )}
                {selectedCountry.label !== "Nigeria" && (
                  <>
                    <div className="col-md-6">
                      <label htmlFor="postal_code" className="form-label">
                        Postal Code <span className="text-danger"></span>
                      </label>
                      <input
                        type="test"
                        className="form-control"
                        placeholder="Post code"
                        id="postCode"
                        {...register("postal_code")}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            <div className="col-md-6">
              <label htmlFor="emailId" className="form-label">
                Email address <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="emailId"
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors.email && (
                <div className="text-danger my-2 fst-italic">
                  Please enter a valid email address.
                </div>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="password" className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              <div className="form-control d-flex align-items-center">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="w-100 border-none"
                  {...register("password")}
                  onChange={(e) => passwordValidation(e)}
                />
                {showPassword ? (
                  <FaEyeSlash
                    className="fs-5 cursor-pointer"
                    onClick={togglePassword}
                  />
                ) : (
                  <FaEye
                    className="fs-5 cursor-pointer"
                    onClick={togglePassword}
                  />
                )}
              </div>

              <small className="my-2 fst-italic">Build Your password min to 8 characters, numeric value, special characters </small>

              {passwordValidationAlert &&
                <div>
                  {passwordValidationAlertMsg == 'characters' &&
                    <div className="text-danger my-2 fst-italic">Your password must be at least 8 characters</div>
                  }
                  {passwordValidationAlertMsg == 'letter' &&
                    <div className="text-danger my-2 fst-italic">Your password must contain at least one letter.</div>
                  }
                  {passwordValidationAlertMsg == 'digit' &&
                    <div className="text-danger my-2 fst-italic">Your password must contain at least one digit.</div>
                  }
                  {passwordValidationAlertMsg == 'splcharcter' &&
                    <div className="text-danger my-2 fst-italic">Your password must contain at least one special characters.</div>
                  }
                </div>
              }

              {/* {errors.password && (
                <div className="text-danger my-2 fst-italic">
                  Please enter a valid password with minimum of 8 characters.
                  {errors.password}
                </div>
              )} */}
            </div>
            <div className="text-center mb-5">
              <div className="text-center my-2">
                <input
                  className="form-check-input mx-2"
                  type="checkbox"
                  id="terms-and-conditions"
                  value=""
                  aria-label="Checkbox htmlFor following text input"
                  {...register("terms", { required: true })}
                />

                <label htmlFor="terms-and-conditions" className="form-label">
                  I agree with{" "}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={handleShow}
                  >
                    the terms and conditions.
                  </span>{" "}
                  <span className="text-danger">*</span>
                </label>
                {errors.terms && (
                  <div className="text-danger my-2 fst-italic">
                    Please agree the terms and conditions to continue.
                  </div>
                )}
              </div>

              {!verified && (
                <div className="shadow-lg p-3 mb-5 bg-body rounded">
                  <div className="col mt-3">
                    <LoadCanvasTemplate />
                  </div>

                  <div className="col mt-3 text-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <input
                        className="form-control w-50"
                        placeholder="Enter Captcha Value"
                        id="user_captcha_input"
                        name="user_captcha_input"
                        type="text"
                        value={captcha}
                        onChange={(e) => setCaptcha(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div>
                    <button
                      className="btn px-3 py-1 bg-success text-white my-3"
                      type="button"
                      onClick={onSubmit}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              )}

              <button
                className="btn bg-pla-primary text-white px-5 rounded-pill py-2 my-2"
                type="submit"
                disabled={!verified}
              >
                Submit
              </button>
              <p className="my-4">Or connect with Social Media</p>

              <div>
                <FacebookLoginComponent />

                <LinkedinLoginComponent />
              </div>
              <p className="my-3">
                Already have an Account{" "}
                <Link className="text-primary cursor-pointer" to="/sign-in">
                  Sign in
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <TermsAndConditions
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
      />
      <Footer />
    </>
  );
};

export default UserSignupScreen;

export const stateOptions = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FM", label: "Federated States Of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VI", label: "Virgin Islands" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];
