import { SET_ACTION_POINTS, GET_ACTION_POINTS } from "../actions/points";

const initialState = {
  actionPoints: null,
  actionPointsByUser: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTION_POINTS:
      return {
        ...state,
        actionPoints: action.data,
      };
    case GET_ACTION_POINTS:
      return {
        ...state,
        actionPointsByUser: action.data,
      };
  }
  return state;
};
