export const SET_PARTICIPANTS = "SET_PARTICIPANTS";
export const SET_IMPACTS = "SET_IMPACTS";

export const setParticipants = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_PARTICIPANTS, data: data });
  };
};

export const setimpacts = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_IMPACTS, data: data });
  };
};
