import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import apiService from '../../services/api.service';
import HeaderLayout from '../../components/header-layout/header-layout';
import Footer from '../../components/footer-layout/footer';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from '../../components/LoadingSpinner';


function EventSlugComponent() {

    let { EventSlug } = useParams();
    const navigate = useNavigate();

    const [eventSlug, setEventSlug] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getEventSlug();

    }, []);

    const getEventSlug = () => {
        setLoading(true);
        axios.get(apiService.API_URL() + '/api/v1/notice/' + EventSlug)
            .then(function (response) {
                setEventSlug(response.data.noticeBySlug);
                setLoading(false);
            })
            .catch(function (error) {
                if (error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                        toast.error(error.response.data.errors[i]);
                    }
                }
                setLoading(false);
            });

    }

    const goToEvents = () => {
        navigate("/events-list");
    }


    return (
        <div>
            <HeaderLayout />
            <div className='container'>
                <section className="bg-white rounded-3 my-5 mx-2 mx-md-5 d-flex flex-column">
                    <div className="py-2">
                        <h3 className="text-center fs-2 fw-bold title">Event information</h3>
                    </div>
                    <div className='mt-3 mb-2 text-center'>
                        <button className="theme_btn" onClick={goToEvents}>All Event</button>
                    </div>
                    <div className="m-4 row">
                        <div className="col-md-12 col-sm-12 col-xs-12 my-3 tab_wid_100 d-flex flex-column">
                            <div className="d-flex flex-column align-items-center p-4 bg-white border m-2 flex-grow-1 Blog_Body">
                                <div>
                                    <div className="title">
                                        {eventSlug?.title}
                                    </div>
                                    <div className='tags mb-3'><b>Type : </b>{eventSlug?.type.charAt(0).toUpperCase() + eventSlug?.type.slice(1)}</div>
                                    {eventSlug?.banner_image != null &&
                                        <div className='banner_img col-md-10 '>
                                            <img src={eventSlug?.banner_image} />
                                        </div>
                                    }
                                    <div className='tags mb-3'><b>Summary : </b>{eventSlug?.summary}</div>
                                    <div className='tags mb-3'><b>Content : </b><div className="content" dangerouslySetInnerHTML={{ __html: eventSlug?.content }} />
                                    </div>

                                </div>
                                <div className='mt-3 mb-3'>
                                    <button className="theme_btn" onClick={goToEvents}>All Events</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
            <LoadingSpinner hidden={!loading} />
        </div>
    )
}

export default EventSlugComponent;