import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import Footer from '../../components/footer-layout/footer';
import HeaderLayout from '../../components/header-layout/header-layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import apiService from '../../services/api.service';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const searchParams = useSearchParams();
  const email = searchParams[0].get('email');
  const token = searchParams[0].get('token');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onResetPassword = async (data) => {
    setLoading(true);
    axios
      .post(apiService.API_URL() + '/api/v1/user/reset-password-token-check', {
        email: email,
        token: token,
        password: data.password,
      })
      .then(function (response) {
        setLoading(false);
        reset();
        toast.success(response.data.msg);
        setTimeout(() => {
          navigate('/sign-in');
        }, 4000);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          toast.error(error.response.data.errors);
        }
      });
  };
  return (
    <>
      <HeaderLayout />
      <div className="d-flex flex-column align-items-center justify-content-center ">
        <LoadingSpinner hidden={!loading} />
        <ToastContainer />

        <div className="text-center mt-3">
          <h2>Reset Password</h2>
          <p className="text-pla-gray">Choose your password wisely.</p>
        </div>
        <div className="container auth-form">
          <form className="row g-3" onSubmit={handleSubmit(onResetPassword)}>
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Email <span className="text-danger">*</span>
              </label>

              <input
                type="email"
                className="form-control"
                id="email"
                disabled={true}
                value={email}
              />
            </div>
            <div className="col-12">
              <label htmlFor="password" className="form-label">
                Password <span className="text-danger">*</span>
              </label>

              <div className="form-control d-flex align-items-center">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  className="w-100 border-none"
                  {...register('password', { required: true, minLength: 8 })}
                />
                {showPassword ? (
                  <FaEyeSlash
                    className="fs-5 cursor-pointer"
                    onClick={togglePassword}
                  />
                ) : (
                  <FaEye
                    className="fs-5 cursor-pointer"
                    onClick={togglePassword}
                  />
                )}
              </div>
              {errors.password && (
                <div className="text-danger my-2 fst-italic">
                  Please enter a valid password.
                </div>
              )}
            </div>

            <div className="text-center mb-5">
              <button
                className="btn bg-pla-primary text-white px-5 rounded-pill py-2"
                type="submit"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
