import React, { useEffect, useState } from "react";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha,} from "react-simple-captcha";
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import './style.css';
import 'react-toastify/dist/ReactToastify.css';
import useInput from '../../hooks/use-input';
import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import apiService from '../../services/api.service';
import HeaderLayout from '../../components/header-layout/header-layout';
import Footer from '../../components/footer-layout/footer';
import * as authActions from '../../store/actions/auth';
import GoogleLoginComponent from '../../components/social-logins/google-login/GoogleLoginComponent';
import FacebookLoginComponent from '../../components/social-logins/facebook-login/FacebookLoginComponent';
import LinkedinLoginComponent from '../../components/social-logins/linkedin-login/LinkedinLoginComponent';

const UserSigninScreen = ({ authenticate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notLoading, setNotLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);


  const [captcha, setCaptcha] = useState("");
  const [verified, setVerified] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onLogin = async (data) => {
    setNotLoading(false);
    const challlenge = localStorage.getItem('challenge');
    const challlengeData = JSON.parse(challlenge);

    var formValue = {
      email: data.email,
      password: data.password,
      challenge_id: challlengeData == null ? null : challlengeData.id,
    };
    axios
      .post(apiService.API_URL() + '/api/v1/login', formValue)
      .then(function (response) {
        setNotLoading(true);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.userDetails));
        localStorage.setItem('challenge_list', JSON.stringify(response.data.user_challenges));
        
        dispatch(authActions.setToken(response.data.token));
        dispatch(authActions.setUser(response.data.userDetails));
        setNotLoading(true);
        authenticate();
        if (challlengeData == null) {
          navigate('/dashboard/home');
        }
        else {
          if (response?.data?.user_challenges[0]?.team && response?.data?.user_challenges[0].status == 1) {
            navigate('/challenge/allactioncategory');
          } else {
            navigate('/join-team/' + challlengeData.id);
          }
        }

      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };


  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const onSubmit = () => {
    if (validateCaptcha(captcha) == true) {
      setVerified(true);
      setCaptcha("");
      loadCaptchaEnginge(6);
    } else {
      setVerified(false);
      setCaptcha("");
      loadCaptchaEnginge(6);
    }
  };

  return (
    <>
      <HeaderLayout />
      <div className="d-flex flex-column align-items-center justify-content-center ">
        <LoadingSpinner hidden={notLoading} />
        <ToastContainer />
        <div className="text-center mt-5">
          <h2>Sign In</h2>
          <p className="text-pla-gray">Sign in to stay connected.</p>
        </div>
        <div className="container auth-form">
          <form className="row g-3" onSubmit={handleSubmit(onLogin)}>
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Email <span className="text-danger">*</span>
              </label>

              <input
                type="email"
                className="form-control"
                id="email"
                {...register('email', {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors.email && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the valid mail.
                </div>
              )}
            </div>

            <div className="col-12">
              <label htmlFor="password" className="form-label">
                Password <span className="text-danger">*</span>
              </label>

              <div className="form-control d-flex align-items-center">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  className="w-100 border-none"
                  {...register('password', { required: true, minLength: 8 })}
                />
                {showPassword ? (
                  <FaEyeSlash
                    className="fs-5 cursor-pointer"
                    onClick={togglePassword}
                  />
                ) : (
                  <FaEye
                    className="fs-5 cursor-pointer"
                    onClick={togglePassword}
                  />
                )}
              </div>
              {errors.password && (
                <div className="text-danger my-2 fst-italic">
                  Please enter a valid password.
                </div>
              )}
            </div>
            <div className="col-12 text-center mb-5">
            {!verified && (
                <div className="shadow-lg p-3 mb-5 bg-body rounded">
                  <div className="col mt-3">
                    <LoadCanvasTemplate />
                  </div>

                  <div className="col mt-3 text-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <input
                        className="form-control w-50"
                        placeholder="Enter Captcha Value"
                        id="user_captcha_input"
                        name="user_captcha_input"
                        type="text"
                        value={captcha}
                        onChange={(e) => setCaptcha(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div>
                    <button
                      className="btn px-3 py-1 bg-success text-white my-3"
                      type="button"
                      onClick={onSubmit}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mb-5">
              <button
                className="btn bg-pla-primary text-white px-5 rounded-pill py-2"
                type="submit"
                disabled={!verified}
              >
                Sign in
              </button>

              <p className="my-3">
                Forgot your password?{' '}
                <Link
                  className="text-primary cursor-pointer"
                  to="/generate-link"
                >
                  Reset Password.
                </Link>
              </p>

              <p className="my-4">Or connect with Social Media</p>

              <div>
                <FacebookLoginComponent authenticate={authenticate} />

                <LinkedinLoginComponent authenticate={authenticate} />
              </div>
              <p className="my-3">
                Don’t have an account?{' '}
                <Link className="text-primary cursor-pointer" to="/sign-up">
                  Click here to sign up.
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserSigninScreen; 
