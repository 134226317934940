import React from "react";
import Footer from "../../components/footer-layout/footer";
import HeaderLayout from "../../components/header-layout/header-layout";
import { FaFacebook, FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const navigate = useNavigate();

  return (
    <div>
      <HeaderLayout />
      <div className="w-md-50 m-auto">
        <h1 className="fs-1 title text-center my-4">Thank you!</h1>
        <div className="card p-4 mb-4 shadow">
          <h3 className="fs-5 sub-title-login text-center">
            Thanks for taking part in the Plafera Challenge. Together we can
            make a difference and be a part of the solution. Will you help
            spread the word? Choose to share with your family, friends and
            colleagues.
          </h3>
        </div>
        <div className="card p-4 mb-4 shadow">
          <h3 className="fs-4 sub-title-login text-center">Share this on</h3>
          <div className="d-flex align-items-center justify-content-center gap-4 my-2">
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A//www.plafera.org/"
              target="_blank"
            >
              <FaFacebook className="fs-3 text-primary cursor-pointer" />
            </a>
            <a
              href="https://twitter.com/intent/tweet?text=I%20am%20taking%20the%20Plastic%20Free%20July%20challenge%20that%20helps%20millions%20of%20people%20be%20part%20of%20the%20solution%20to%20plastic%20pollution%20%E2%80%93%20so%20we%20can%20have%20cleaner%20streets,%20oceans,%20and%20beautiful%20communities.%20Will%20you%20join%20Plastic%20Free%20July%20by%20choosing%20to%20refuse%20single-use%20plastics?&url=https://www.plafera.org/"
              target="_blank"
            >
              <FaTwitter className="fs-3 text-info cursor-pointer" />
            </a>
            <a
              href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A//www.plafera.org/&title=Will%20you%20join%20the%20Plastic%20Free%20July%20Challenge?&summary=I%20am%20joining%20the%20Plastic%20Free%20July%20challenge%20that%20helps%20millions%20of%20people%20be%20part%20of%20the%20solution%20to%20plastic%20pollution%20%E2%80%93%20so%20we%20can%20have%20cleaner%20streets,%20oceans,%20and%20beautiful%20communities.%20Will%20you%20join%20Plastic%20Free%20July%20by%20choosing%20to%20refuse%20single-use%20plastics?&source="
              target="_blank"
            >
              <FaLinkedin className="fs-3 text-primary cursor-pointer" />
            </a>
            <a
              href="mailto:?subject=Will%20you%20join%20the%20Plastic%20Free%20July%20Challenge?&body=I%20am%20joining%20the%20Plastic%20Free%20July%20challenge%20that%20helps%20millions%20of%20people%20be%20part%20of%20the%20solution%20to%20plastic%20pollution%20%E2%80%93%20so%20we%20can%20have%20cleaner%20streets,%20oceans,%20and%20beautiful%20communities.%20Will%20you%20join%20Plastic%20Free%20July%20by%20choosing%20to%20refuse%20single-use%20plastics?"
              target="_blank"
            >
              <FaEnvelope className="fs-3 text-warning cursor-pointer" />
            </a>
          </div>
        </div>

        <div className="text-center mb-4">
          <button
            className="btn text-white bg-pla-primary px-4"
            onClick={() => navigate("/how-it-works")}
          >
            Proceed
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Notification;
