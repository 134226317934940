import React, { useState, useEffect } from 'react';
import axios from 'axios';
import apiService from '../../services/api.service';
import HeaderLayout from '../../components/header-layout/header-layout';
import Footer from '../../components/footer-layout/footer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import Pagination from "react-js-pagination";

function EventComponent() {

    const navigate = useNavigate();
    const [getAllEvent, setAllEvent] = useState(null);
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordPerPage, setRecordPerPage] = useState();
    const [totalRecords, setTotalRecords] = useState();
    const [pageRange, setPageRange] = useState();

    useEffect(() => {
        getAllEvents();

    }, []);

    const getAllEvents = () => {
        setLoading(true);
        axios.get(apiService.API_URL() + '/api/v1/allNotice')
            .then(function (response) {
                setAllEvent(response.data.AllNotices.data);
                setRecordPerPage(response.data.AllNotices.per_page);
                setTotalRecords(response.data.AllNotices.total);
                setPageRange(response.data.AllNotices.per_page);
                setLoading(false);
            })
            .catch(function (error) {
                if (error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                        toast.error(error.response.data.errors[i]);
                    }
                }
                setLoading(false);
            });
    }

    const OnclickEventSlug = (event) => {
        navigate("/event/" + event);
    }

    const GetAllFeed = (pageNumber) => {
        if (pageNumber == 1) {
            setCurrentPage(pageNumber);
        } else {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <div>
            <HeaderLayout />
            <div className='container'>
                <section className="bg-white rounded-3 my-5 mx-2 mx-md-5 d-flex flex-column">
                    <div className="py-2">
                        <h3 className="text-center fs-2 title">Events</h3>
                    </div>
                    <div className="m-4 row">
                        {getAllEvent?.map((items, i) => {
                            return (
                                <div className="col-md-3 col-sm-12 col-xs-12 my-3 tab_wid_100 d-flex flex-column" onClick={(e) => OnclickEventSlug(items.title_slug)}>
                                    <div className="d-flex flex-column align-items-center p-4 bg-white border m-2 flex-grow-1 Blog_Body">
                                        <div>
                                            <div className="title">
                                                {items.title.length > 80 ? items.title.substring(0, 80) + "..." : items.title}
                                            </div>
                                            <p>{items.summary.length > 150 ? items.summary.substring(0, 150) + "..." : items.summary}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {getAllEvent?.length == 0 && (
                            <div className="no_data"> No Data For the Posts</div>
                        )}

                    </div>

                    <div className="mar_auto mt-5">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={currentPage}
                            itemsCountPerPage={recordPerPage}
                            totalItemsCount={totalRecords}
                            pageRangeDisplayed={pageRange}
                            onChange={(pageNumber) => GetAllFeed(pageNumber)}
                        />
                    </div>

                </section>
            </div>
            <LoadingSpinner hidden={!loading} />
            <Footer />
        </div>
    )
}

export default EventComponent;