import { SET_ALL_PARTICIPANTS } from "../actions/participants";

const initialState = {
  allParticipants: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_PARTICIPANTS:
      return {
        ...state,
        allParticipants: action.data,
      };
  }
  return state;
};
