import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import apiService from '../../services/api.service';
import HeaderLayout from '../../components/header-layout/header-layout';
import Footer from '../../components/footer-layout/footer';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import Pagination from 'react-js-pagination';

function BlogComponent() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); 

  //  useQuery hook for fetching data
  const { data: allPostsData, isLoading, isError } = useQuery('allPosts', getAllPosts);

  const recordPerPage = allPostsData?.per_page;
  const totalRecords = allPostsData?.total;
  const pageRange = allPostsData?.per_page;

  function getAllPosts() {
    return axios.get(apiService.API_URL() + '/api/v1/allposts').then((response) => response.data.AllPosts);
  }

  const OnclickPostSlug = (event) => {
    navigate('/blog-slug/' + event);
  };

  const GetAllFeed = (pageNumber) => {
    setCurrentPage(pageNumber); // Set the current page when the page changes
  };

  return (
    <div>
      <HeaderLayout />
      <div className="container">
        <section className="bg-white rounded-3 my-5 mx-2 mx-md-5 d-flex flex-column">
          <div className="py-2">
            <h3 className="text-center fs-2 title">Blog</h3>
          </div>
          <div className="m-4 row">
            {allPostsData?.data?.map((items, i) => (
              <div
                key={i}
                className="col-md-3 col-sm-12 col-xs-12 my-3 tab_wid_100 d-flex flex-column"
                onClick={() => OnclickPostSlug(items.title_slug)}
              >
                <div className="d-flex flex-column align-items-center p-4 bg-white border m-2 flex-grow-1 Blog_Body">
                  <div>
                    <div className="title">{items.title}</div>
                    {items?.banner_image != null && (
                      <div className="banner_img">
                        <img src={items.banner_image} alt={`Banner for ${items.title}`} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {allPostsData?.data?.length === 0 && <div className="no_data"> No Data For the Posts</div>}
          </div>

          <div className="mar_auto mt-5">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={currentPage}
              itemsCountPerPage={recordPerPage}
              totalItemsCount={totalRecords}
              pageRangeDisplayed={pageRange}
              onChange={(pageNumber) => GetAllFeed(pageNumber)}
            />
          </div>
        </section>
      </div>
      <LoadingSpinner hidden={!isLoading} />
      <Footer />
    </div>
  );
}

export default BlogComponent;
