import React, { useRef, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPowerOff } from "react-icons/fa";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../store/actions/auth";
import * as eventActions from "../../store/actions/events";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import "./dashboard.css";
import axios from "axios";
import apiService from "../../services/api.service";
import { parse } from "date-fns";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import LoadingSpinner from "../LoadingSpinner";

import * as dashboardAction from "../../store/actions/dashboard";

function Dashboard({ logout }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [focus, setFocus] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setFocus);

  const [loading, setLoading] = useState(false);
  const [activeToggle, setActiveToggle] = useState(false);
  const [peopleActiveToggle, setPeopleActiveToggle] = useState(false);
  const [challengeActiveToggle, setChallengeActiveToggle] = useState(false);
  const [userData, setUserData] = useState({});
  const [teamData, setTeamData] = useState({});
  const [isShowMobMenuData, setisShowMobMenuData] = useState(false);
  const [myChallengeActive, setMyChallengeActive] = useState(null);

  // const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const points = useSelector((state) => state.events.points);
  const userPoints = useSelector((state) => state.events.userPoints);
  const weeklyPoints = useSelector((state) => state.events.weeklyPoints);

  var today = new Date();
  var year = today.getFullYear();

  const userdata = localStorage.getItem("user");
  const user = JSON.parse(userdata);

  const challenge = localStorage.getItem("challenge");
  const challengedata = JSON.parse(challenge);

  const challengeList = localStorage.getItem("challenge_list");
  const challengedataList = JSON.parse(challengeList);

  //Basker Quiz start
  const [isShowFirstQuestion, setIsShowFirstQuestion] = useState(true);
  const [isShowSecondQuestion, setIsShowSecondQuestion] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [firstQuestion, setFirstQuestion] = useState({});
  const [secondQuestion, setSecondQuestion] = useState({});
  const [answer1TimeTaken, setAnswer1TimeTaken] = useState(null);
  const [answer2TimeTaken, setAnswer2TimeTaken] = useState(null);
  const [seconds, setSeconds] = useState(15);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (isQuizAvailable === true) {
      setLoading(true);
      getQuizQuestion();
    }
  };
  const modalRef = useRef(null);
  const [resetTimer, setResetTimer] = useState();
  const [isQuizAvailable, setIsQuizAvailable] = useState(false);
  //Basker Quiz end

  const onMenuToggle = () => {
    setActiveToggle(!activeToggle);
  };

  function useOutsideAlerter(ref, setFocus) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFocus(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, setFocus]);
  }

  useEffect(() => {
    if (show) {
      if (isShowFirstQuestion === true) {
        setAnswer1TimeTaken(seconds);
      }

      if (isShowSecondQuestion === true) {
        setAnswer2TimeTaken(seconds);
      }

      setTimer();
      if (seconds === 0) {
        handleQuizSubmit();
      }
    }
  }, [show, seconds]);

  useEffect(() => {
    if (user) {
      getUserDetails();
      getTodayQuizStatus();
      if(challengedata!=null){
        setMyChallengeActive(challengedata.id);
      }
    }
  }, []);
  // }, [user]);

  const getUserDetails = () => {
    setLoading(true);
    axios
      .get(
        apiService.API_URL() +
        `/api/v1/dashboard?challenge_slug=${challengedata?.challenge_slug}&user_id=${user?.id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(function (response) {
        if (response?.data?.challenge) {
          dispatch(authActions.setTeamId(user?.team));
          getPoints(user?.id, user?.team, challengedata?.id);
          getUserPoints(user?.id, challengedata?.id);
          getWeeklyPoints(user?.id, challengedata?.id);
        }
        setUserData(response.data.user_data);
        setTeamData(response.data.challenge[0]);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setLoading(false);
      });
  };

  const getPoints = (userId, teamId, challengeid) => {
    axios
      .get(
        apiService.API_URL() +
        `/api/v1/get-user-points-by-team/${userId}/${teamId}/${challengeid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        dispatch(eventActions.setPoints(response.data));
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const getUserPoints = (userId, challengeid) => {
    axios
      .get(apiService.API_URL() + `/api/v1/get-user-points/${userId}/${challengeid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(eventActions.setUserPoints(response.data));
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const getWeeklyPoints = (userId, challengeid) => {
    axios
      .get(apiService.API_URL() + `/api/v1/get-user-points/${userId}/${challengeid}/week`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        dispatch(eventActions.setWeeklyPoints(response.data));
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const onLogout = () => {
    toast.success("Logout successfully!");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("challenge_list");
    dispatch(authActions.setToken(null));
    dispatch(authActions.setToken(null));
    logout();
    navigate("/");
  };

  const onDroupDownToggle = (value) => {
    if (value === "people") {
      setPeopleActiveToggle(!peopleActiveToggle);
      setChallengeActiveToggle(false)
    }
    else if (value === "challenge") {
      setPeopleActiveToggle(false);
      setChallengeActiveToggle(!challengeActiveToggle);
    }
  };
  const onNavigateToPeople = (value) => {
    navigate("/dashboard/people/" + value.toLowerCase());
    setisShowMobMenuData(false);
  };
  const isShowMobmenu = () => {
    setisShowMobMenuData(true);
    setTimeout(() => {
      setFocus(true);
    }, 100);
    setActiveToggle(false);
  };
  const navigateToHome = () => {
    navigate("/dashboard/home");
    setisShowMobMenuData(false);
  };
  const navigateToPoints = () => {
    navigate("/dashboard/points");
    setisShowMobMenuData(false);
  };
  const navigateToFeeds = () => {

    if (challengedata == null | challengedata?.status == 0) {

    } else {
      navigate("/dashboard/feeds");
      setisShowMobMenuData(false);
    }
  };
  const navigateToProfile = () => {
    navigate("/dashboard/edit-profile");
    setisShowMobMenuData(false);
  };
  const navigateToViewProfile = () => {
    navigate(`/dashboard/user-profile/${user?.name}`, {
      state: { id: user?.id, teamId: teamData?.team_id },
    });
    setActiveToggle(false);
  };
  const navigateToEditProfile = () => {
    if (challengedata == null ) {
      // console.log(challengedata);
      toast.error('Join a challenge to edit your profile');
      return;
    }
    navigate("/dashboard/edit-profile");
    setActiveToggle(false);
  };
  const navigateToManageAction = () => {
    if (challengedata == null | challengedata?.status == 0) {
      // toast.error('Join a challenge to manage actions');
      // return;
    } else {
      navigate("/challenge/allactioncategory");
      setActiveToggle(false);
    }
  };
  const navigateToEditTeamProfile = () => {
    if(challengedata == null || challengedata?.status == 0){

    }
    else{
      navigate(`/dashboard/edit-team/${teamData?.name.replace(/\s/g, "")}`, {
        state: { teamId: teamData?.team_id },
      });
      setActiveToggle(false);
    }
  };
  const navigateToEmailTeammates = () => {
    if (challengedata == null | challengedata?.status == 0) {

    } else {
      navigate(`/dashboard/email-teammates`, {
        state: { teamId: teamData?.team_id },
      });
      setActiveToggle(false);
    }
  };
  const navigateToInviteTeammates = () => {
    if (challengedata == null | challengedata?.status == 0) {

    } else {
      navigate(
        `/dashboard/team-invitations/${teamData?.name.replace(/\s/g, "")}`,
        { state: { teamId: teamData?.team_id } }
      );
      setActiveToggle(false);
    }
  };
  const navigateToCreateMoreTeam = () => {
    // navigate('/dashboard/edit-profile')
    setActiveToggle(false);
  };
  const navigateToTeamProfile = () => {
    navigate(`/dashboard/teamprofile/${teamData?.name.replace(/\s/g, "")}`, {
      state: { teamId: teamData?.team_id },
    });
    setActiveToggle(false);
  };
  const goToStaticPage = () => {
    navigate("/");
  };

  // Quiz start

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleQuizSubmit = () => {
    clearTimeout(resetTimer);
    if (isShowFirstQuestion && Object.keys(secondQuestion).length === 0) {
      modalRef.current.click();
    }
    if (isShowFirstQuestion) {
      setIsShowSecondQuestion(true);
      setIsShowFirstQuestion(false);
      setSeconds(15);
    }

    if (isShowSecondQuestion) {
      setAnswer2TimeTaken(seconds);
      setIsShowSecondQuestion(false);
      modalRef.current.click();
    }
  };

  const onSubmit = async (data) => {
    let time_taken_question_1 = 0;
    let time_taken_question_2 = 0;

    if (answer1TimeTaken !== null) {
      time_taken_question_1 = 12 - answer1TimeTaken;
    }

    if (answer2TimeTaken !== null) {
      time_taken_question_2 = 12 - answer2TimeTaken;
    }

    const formData = new FormData();
    formData.append("first_question_id", data.first_question);
    formData.append("first_question_answer", data.first_question_answer);
    formData.append("second_question_id", data.second_question);
    formData.append("second_question_answer", data.second_question_answer);
    formData.append("time_taken_question_1", time_taken_question_1);
    formData.append("time_taken_question_2", time_taken_question_2);
    formData.append("user_id", user.id);

    axios
      .post(apiService.API_URL() + "/api/v1/save-quiz", formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.data.code == 200) {
          toast.success("Thank you for participating in the quiz");
          setIsQuizAvailable(false);
        }
        if (response.data.code == 500) {
          toast.error(response.message);
          setIsQuizAvailable(true);
        }
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const getTodayQuizStatus = () => {
    axios
      .get(apiService.API_URL() + `/api/v1/quiz-status?user_id=${user.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.data.is_elgible_quiz) {
          setIsQuizAvailable(true);
        }

        // if (response.data.is_attended_quiz_this_week) {
        //   toast.info(`This week's quiz result will be announced tomorrow`);
        // }
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const getQuizQuestion = () => {
    axios
      .get(apiService.API_URL() + `/api/v1/quiz?user_id=${user.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.data.quiz.length > 0) {
          setFirstQuestion(response.data.quiz[0]);
        }
        if (response.data.quiz.length > 1) {
          setSecondQuestion(response.data.quiz[1]);
        }
        setLoading(false);
        setShow(true);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const setTimer = () => {
    setResetTimer(setTimeout(() => setSeconds(seconds - 1), 1000));
  };
  // Quiz end

  const activeChallenge = (challenge) => {
    setMyChallengeActive(challenge.id);
    getActiveUserDetails(challenge);
    let updatearry = localStorage.getItem('user');
    let parsearray = JSON.parse(updatearry)
    parsearray.team = challenge.team;

    localStorage.setItem('user', JSON.stringify(parsearray));
    localStorage.setItem("challenge", JSON.stringify(challenge));


    setLoading(true);
    axios.get(apiService.API_URL() + `/api/v1/userActionsByChallenge?challenge_slug=${challenge.challenge_slug}&user_id=${parsearray.id}`, { headers: { Authorization: `Bearer ${token}`, }, })
      .then(function (response) {
        setLoading(false);
        dispatch(dashboardAction.setUserAction(response.data.actions));
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });

  }

  const getActiveUserDetails = (challenge) => {
    setLoading(true);
    axios.get(apiService.API_URL() + `/api/v1/dashboard?challenge_slug=${challenge.challenge_slug}&user_id=${user?.id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    ).then(function (response) {
      if (response?.data?.challenge) {
        dispatch(authActions.setTeamId(challenge?.team));
        getPoints(user?.id, challenge?.team, challenge.id);
        getUserPoints(user?.id, challenge.id);
        getWeeklyPoints(user?.id, challenge.id);
      }
      setUserData(response.data.user_data);
      setTeamData(response.data.challenge[0]);
      setLoading(false);
    })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setLoading(false);
      });
  };
  return (
    <>
      <ToastContainer />
      <div
        className="das_nav_header" style={activeToggle ? { padding: "7px 20px 7px 20px" } : { padding: "7px 20px 7px 20px" }}>
        <div className="logo">
          <img src="https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/image.png" alt="logo" onClick={goToStaticPage} />{" "}
          <span onClick={goToStaticPage}>PLAFERA</span>
          <i className="fa fa-arrow-left" id="OpenSideMenu" aria-hidden="true" onClick={() => onMenuToggle()} style={activeToggle ? { transform: "rotate(180deg)" } : { transform: "rotate(0deg)" }}></i>
          <ReactTooltip anchorId="OpenSideMenu" place="bottom" content="Click To View Menus" style={{ fontWeight: "100" }} />
        </div>

        <div className="menulist webMenu">
          <ul>
            <li onClick={() => navigate("/dashboard/home")}>
              <span>Dashboard</span>{" "}
            </li>
            <li onClick={() => navigate(`/dashboard/event-list/${user.id}`)}>
              <span>Events</span>{" "}
            </li>
            <li onClick={() => navigate("/dashboard/points")}>
              <span>Points</span>{" "}
            </li>
            <li id="FeedWebMenu" onClick={() => challengedata == null || challengedata?.status == 0 ? "" : navigate("/dashboard/feeds")}>
              <span>feed</span>{" "}
            </li>
            {challengedata == null || challengedata?.status == 0 &&
              <ReactTooltip anchorId="FeedWebMenu" place="bottom" content="This challenge has been completed already. So, this action cannot be allowed." />
            }

            <li onClick={() => onDroupDownToggle("people")} style={{ padding: "7px 27px !important" }} className="mob_dis_block">
              <span>People </span>
              <i className="fa fa-angle-down droup_down_icn" aria-hidden="true" style={peopleActiveToggle ? { transform: "rotate(180deg)" } : { transform: "rotate(0deg)" }}></i>

              {peopleActiveToggle && (
                <ul className="droupdown_body">
                  <li onClick={() => onNavigateToPeople("Participants")}>
                    <span>Participants </span>
                  </li>
                  <li onClick={() => onNavigateToPeople("Teams")}>
                    <span>Teams </span>
                  </li>
                  <li onClick={() => onNavigateToPeople("Organizations")}>
                    <span>Organizations </span>
                  </li>
                </ul>
              )}
            </li>
            {isQuizAvailable === true && (
              <li onClick={handleShow}>
                <span>quiz</span>{" "}
              </li>
            )}
            <li onClick={() => navigate("/dashboard/quiz-result")}>
              <span>quiz result</span>{" "}
            </li>

            <li onClick={() => onDroupDownToggle("challenge")} style={{ padding: "7px 27px !important" }} className="mob_dis_block">
              <span>My Challenges </span>
              <i className="fa fa-angle-down droup_down_icn" aria-hidden="true" style={challengeActiveToggle ? { transform: "rotate(180deg)" } : { transform: "rotate(0deg)" }}></i>

              {challengeActiveToggle && (
                <ul className="droupdown_body">
                  {challengedataList.map((challenge, index) => {
                    return (
                      <li onClick={(e) => activeChallenge(challenge)} key={index} className={myChallengeActive == challenge.id ? "ActiveMyChallenge" : ""}>
                        <span>{challenge.challenge_name} </span>
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>

          </ul>
        </div>
        {isShowMobMenuData && focus && (
          <div className="menulist mobMenu" ref={wrapperRef}>
            <ul>
              <li onClick={navigateToHome}>
                <span>Dashboard</span>{" "}
              </li>
              <li onClick={() => navigate(`/dashboard/event-list/${user.id}`)}>
                <span>Events</span>{" "}
              </li>
              <li onClick={navigateToPoints}>
                <span>Points</span>{" "}
              </li>
              <li id="FeedMobMenu" onClick={() => challengedata == null || challengedata?.status == 0 ? "" : navigate("/dashboard/feeds")}>
                <span>feed</span>{" "}
              </li>
              {challengedata == null || challengedata?.status == 0 &&
                <ReactTooltip anchorId="FeedMobMenu" place="bottom" content="This challenge has been completed already. So, this action cannot be allowed." />
              }

              <li onClick={() => onDroupDownToggle("people")} className="mob_dis_block">
                <span>People </span>
                <i className="fa fa-angle-down droup_down_icn" aria-hidden="true" style={peopleActiveToggle ? { transform: "rotate(180deg)" } : { transform: "rotate(0deg)" }}></i>

                {peopleActiveToggle && (
                  <ul className="droupdown_body">
                    <li onClick={() => onNavigateToPeople("Participants")}>
                      <span>Participants </span>
                    </li>
                    <li onClick={() => onNavigateToPeople("Teams")}>
                      <span>Teams </span>
                    </li>
                    <li onClick={() => onNavigateToPeople("Organizations")}>
                      <span>Organizations </span>
                    </li>
                  </ul>
                )}
              </li>
              {isQuizAvailable === true && (
                <li onClick={handleShow}>
                  <span>quiz</span>{" "}
                </li>
              )}
              <li onClick={() => navigate("/dashboard/quiz-result")}>
                <span>quiz result</span>{" "}
              </li>

              <li onClick={() => onDroupDownToggle("challenge")} style={{ padding: "7px 27px !important" }} className="mob_dis_block">
                <span>My Challenges </span>
                <i className="fa fa-angle-down droup_down_icn" aria-hidden="true" style={challengeActiveToggle ? { transform: "rotate(180deg)" } : { transform: "rotate(0deg)" }}></i>

                {challengeActiveToggle && (
                  <ul className="droupdown_body">

                    {challengedataList.map((challenge, index) => {
                      return (
                        <li onClick={(e) => activeChallenge(challenge)} key={index} className={myChallengeActive == challenge.id ? "ActiveMyChallenge" : ""}>
                          <span>{challenge.challenge_name} </span>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </li>

            </ul>
          </div>
        )}

        <div className="flex-grow"></div>
        <div className="content_group">
          <i
            className="fa fa-bars humbergermenu"
            aria-hidden="true"
            onClick={isShowMobmenu}
            ref={wrapperRef}
          ></i>
          <FaPowerOff onClick={onLogout} className="logout_icn" id="Logout" />
          <ReactTooltip anchorId="Logout" place="left" content="Logout" />

        </div>
      </div>
      <div className="sidemenu" style={activeToggle ? { width: "100%", display: "block" } : { width: "200px" }} >
        <div className="menulist" style={{ overflow: "auto" }}>
          <div className="">
            <div className="d-flex flex-column align-items-center justify-content-center my-4">
              {user?.avatar ? (
                <img
                  src={`data:image/png;base64,${user?.avatar}`}
                  className="mx-auto d-block rounded-circle"
                  alt="Cinque Terre"
                  style={{ height: "90px", width: "90px" }}
                />
              ) : (
                <div
                  className="p-4 rounded-circle bg-success d-flex align-items-center justify-content-center text-uppercase fs-3 text-white dashboard_round"
                  style={{ height: "90px", width: "90px" }}
                >
                  {user?.name?.charAt(0)}
                  {user?.name != null ? user?.name?.charAt(0) : ""}
                </div>
              )}
              <p className="text-capitalize mt-3 fs-5 text-center">
                {user?.name + "  "}
                {user?.last_name != null ? user?.last_name : ""}
              </p>
              <p
                className="text-capitalize fs-6 cursor-pointer"
                onClick={navigateToViewProfile}
              >
                View Profile
              </p>
            </div>
            {points && (
              <div className="d-flex gap-2">
                <div className="d-flex flex-column align-items-center justify-content-center bg-light p-2 flex-1">
                  <p className="fs-4">{weeklyPoints?.total_points}</p>
                  <p className="text-uppercase text-center fw-bold">
                    Points this week
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center bg-light p-2 flex-1">
                  <p className="fs-4">{userPoints?.total_points}</p>
                  <p className="text-uppercase text-center fw-bold">
                    Total Points
                  </p>
                </div>
              </div>
            )}

            <div className="my-3 d-flex flex-column gap-2 align-items-center justify-content-center mx-2">
              <button
                type="button"
                className="text-dark text-uppercase border bg-white border-dark rounded-pill fs-sm wid_fit_content pad_20px"
                onClick={navigateToEditProfile}
            
              >
                Edit Profile
              </button>
              <button
                type="button"
                id="manageaction"
                className="text-dark text-uppercase border bg-white border-dark rounded-pill fs-sm wid_fit_content pad_20px"
                onClick={navigateToManageAction}
              >
                Manage Actions
              </button>
              {challengedata == null || challengedata?.status == 0 &&
                <ReactTooltip anchorId="manageaction" place="bottom" content="This challenge has been completed already. So, this action cannot be allowed." style={{width: "190px"}}/>
              }
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center">
              <p className="text-center text-capitalize mt-3 fs-5 text-break" >{teamData?.name}</p>
              <p
                className="text-uppercase fs-6 cursor-pointer"
                onClick={navigateToTeamProfile}
              >
                {teamData?.organization_or_school}
              </p>
            </div>

            {points && (
              <div className="d-flex gap-2">
                <div className="d-flex flex-column align-items-center justify-content-center bg-light p-2 flex-1">
                  <p className="fs-4">{points?.team_users_count}</p>
                  <p className="text-uppercase text-center fw-bold">
                    Team members
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center bg-light p-2 flex-1">
                  <p className="fs-4">{points?.total_team_point}</p>
                  <p className="text-uppercase text-center fw-bold">
                    Total team Points
                  </p>
                </div>
              </div>
            )}

            {points && (
              <div className="my-3 d-flex flex-column gap-2 align-items-center justify-content-center mx-2">
                <button
                  type="button"
                  id="EditTeamProfile"
                  className="text-dark text-uppercase border bg-white border-dark rounded-pill fs-sm wid_fit_content pad_20px"
                  onClick={navigateToEditTeamProfile}
                >
                  Edit team Profile
                </button>
                {challengedata == null || challengedata?.status == 0 &&
                  <ReactTooltip anchorId="EditTeamProfile" place="bottom" content="This challenge has been completed already. So, this action cannot be allowed." style={{width: "190px"}} />
                }
                <button
                  type="button"
                  id="EmailTeamates"
                  className="text-dark text-uppercase border bg-white border-dark rounded-pill fs-sm wid_fit_content pad_20px"
                  onClick={navigateToEmailTeammates}
                >
                  Email Teammates
                </button>
                {challengedata == null || challengedata?.status == 0 &&
                  <ReactTooltip anchorId="EmailTeamates" place="bottom" content="This challenge has been completed already. So, this action cannot be allowed." style={{width: "190px"}} />
                }
                <button
                  type="button"
                  id="InviteTeammates"
                  className="text-dark text-uppercase border bg-white border-dark rounded-pill fs-sm wid_fit_content pad_20px"
                  onClick={navigateToInviteTeammates}
                >
                  Invite Teammates
                </button>
                {challengedata == null || challengedata?.status == 0 &&
                  <ReactTooltip anchorId="InviteTeammates" place="bottom" content="This challenge has been completed already. So, this action cannot be allowed." style={{width: "190px"}}/>
                }
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="content_view"
        style={
          activeToggle
            ? { padding: "77px 0px 0px 0px" }
            : { padding: "66px 0px 0px 200px" }
        }
      >
        <Outlet />
      </div>

      <div
        className="das_footer"
        style={
          activeToggle
            ? { padding: "7px 20px 7px 20px" }
            : { padding: "7px 20px 7px 225px" }
        }
      >
        <p
          className="cursor-pointer privacy-terms me-md-3 mb-2"
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </p>
        <p
          className="cursor-pointer privacy-terms mb-2"
          onClick={() => navigate("/terms-and-conditions")}
        >
          Terms of Use
        </p>
        <div className="flex-grow"></div>

        <div className="logo cursor-pointer" onClick={goToStaticPage}>
          <img src="https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/image.png" alt="logo" /> PLAFERA
        </div>
        <p className="copy_right">Copyright by {year} Plafera</p>
      </div>

      {/* Quizs start */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Today Quiz </Modal.Title>
          <span className="float-right">Time Left: {seconds}</span>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            {firstQuestion && isShowFirstQuestion && (
              <div className="m-4 row">
                <h5 className="mb-3">First Question</h5>
                <div className="bg-pla-gray row">
                  <div className={`my-2`}>
                    <label
                      htmlFor="terms-and-conditions"
                      className="form-label text-dark text-capitalize"
                    >
                      {firstQuestion.title}
                    </label>

                    {firstQuestion.options &&
                      firstQuestion.options.map((option, index) => {
                        return (
                          <div className="form-check my-2">
                            <input
                              className="form-check-input"
                              type="hidden"
                              {...register(`first_question`)}
                              value={firstQuestion.id}
                            />

                            <input
                              className="form-check-input"
                              type="hidden"
                              {...register(`time_taken_first_question`)}
                              value={answer1TimeTaken}
                            />

                            {firstQuestion.answer_type === "one" && (
                              <input
                                className="form-check-input"
                                type="radio"
                                {...register(`first_question_answer`)}
                                value={option.id}
                              />
                            )}

                            {firstQuestion.answer_type === "multiple" && (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                {...register(`first_question_answer`)}
                                value={option.id}
                              />
                            )}
                            <label
                              className="form-check-label text-black"
                              htmlFor="firstChallenge"
                            >
                              {option.title}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}

            {secondQuestion && isShowSecondQuestion && (
              <div className="m-4 row">
                <h5 className="mb-3">Second Question</h5>
                <div className="bg-pla-gray row">
                  <div className={`my-2`}>
                    <input
                      className="form-check-input"
                      type="hidden"
                      {...register(`second_question`)}
                      value={secondQuestion.id}
                    />

                    <label
                      htmlFor="terms-and-conditions"
                      className="form-label text-dark text-capitalize"
                    >
                      {secondQuestion.title}
                    </label>

                    {secondQuestion.options &&
                      secondQuestion.options.map((option, index) => {
                        return (
                          <div className="form-check my-2">
                            {secondQuestion.answer_type === "one" && (
                              <input
                                className="form-check-input"
                                type="radio"
                                {...register(`second_question_answer`)}
                                value={option.id}
                              />
                            )}

                            {secondQuestion.answer_type === "multiple" && (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                {...register(`second_question_answer`)}
                                value={option.id}
                              />
                            )}

                            <label
                              className="form-check-label text-black"
                              htmlFor="firstChallenge"
                            >
                              {option.title}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}

            <Button
              variant="success"
              type="submit"
              className="d-none"
              ref={modalRef}
              onClick={handleClose}
            >
              save
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleQuizSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Quizs end */}

      <LoadingSpinner hidden={!loading} />
    </>
  );
}
export default Dashboard;
