import { SET_IMPACTS, SET_PARTICIPANTS } from "../actions/pie-chart";

const initialState = {
  participants: {},
  impacts: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.data,
      };
    case SET_IMPACTS:
      return {
        ...state,
        impacts: action.data,
      };
  }
  return state;
};
