import { useNavigate } from "react-router-dom";
import apiService from "../../services/api.service";
import HeaderLayout from "../header-layout/header-layout";

import Footer from "../footer-layout/footer";
function OmniCalculator() {

    const navigate = useNavigate();

    const joinChallenge = () => {
        navigate("/events");
    };

    return (
        <>
            <HeaderLayout />
            <div>
                <div className="title text-center mt-4 mb-4">Omni Calculator</div>
                <a href={apiService.onmiCalc_Iframe_URL()} target="_blank"/>
                {/* <iframe src={apiService.onmiCalc_Iframe_URL()} className="omni_calc" height="100%" width="fit-content" title="omni calculator"></iframe> */}
            </div>
            <Footer />
        </>
    )
}

export default OmniCalculator;