import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";

import * as teamsAction from "../../store/actions/teams";

const TeamInvitations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { teamId } = state;
  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState(null);
  const [points, setPoints] = useState(null);

  const user = useSelector((state) => state.auth.user);

  const challlenge = localStorage.getItem("challenge");
  const challlengeData = JSON.parse(challlenge);

  const teamDataStore = useSelector((state) => state.teams.teamDataToInvite);

  useEffect(() => {
    fetchTeamData();
  }, []);

  useEffect(() => {
    if (teamDataStore) {
      setTeam(teamDataStore);
    }
  }, [teamDataStore]);

  const fetchTeamData = () => {
    setLoading(true);
    if (token) {
      axios
        .get(apiService.API_URL() + `/api/v1/team/${teamId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          setLoading(false);
          dispatch(teamsAction.setTeamDataInvite(response.data));
          // setTeam(response.data);
          //   fetchTeamPoints();
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setLoading(false);
        });
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    var submitData = {
      challenge_id: challlengeData.id,
      team_id: teamId,
      user_id: user.id,
      refered_email: data.email,
      message: '' //data.message,
    };

    setLoading(true);
    axios
      .post(apiService.API_URL() + "/api/v1/invite", submitData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        setLoading(false);
        reset();
        toast.success(response.data.message);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };
  return (
    <div>
      <ToastContainer />
      <LoadingSpinner hidden={!loading} />
      {team && (
        <section
          className="d-flex align-items-center justify-content-center pad_lr_15px"
          style={{ minHeight: "35vh" }}
        >
          <div
            className="d-flex flex-column h-75 wid-50 bg-dark align-items-center justify-content-center"
            style={{
              minHeight: "26vh",
              backgroundImage: `url(${`${team?.banner[0]?.image}`})`,
              backgroundPosition: "center",
            }}
          >
            {team?.logo[0]?.image ? (
              <img
                src={team?.logo[0]?.image}
                alt="logo "
                style={{ height: "100px", width: "100px" }}
                className="rounded-circle"
              />
            ) : (
              <div
                className="px-5 py-4 rounded-circle bg-success d-flex align-items-center justify-content-center text-uppercase fs-3 text-white"
                style={{ height: "100px", width: "100px" }}
              >
                {team?.name?.charAt(0)}
              </div>
            )}

            <h3 className="text-white text-capitalize fs-3 fw-light my-3">
              {team.name}
            </h3>
          </div>
        </section>
      )}
      <section className="bg-white">
        <div className="container">
          {/* <div className="d-flex flex-column align-items-center justify-content-center pt-5">
            <h3 className="fs-2 fw-light text-capitalize">
              Share your referral link
            </h3>
            <a className="text-uppercase my-2 text-decoration-none fw-bold text-pla-secondary fs-6 text-dark">
              Click URL to copy your unique invitation link
            </a>
            <p className="w-50 text-center fs-6">
              https://www.computerhope.com/jargon/u/url.htm#:~:text=
              The%20URL%20makes%20it%20possible,for%20the%20Computer%20Hope%20website.
            </p>
          </div> */}
          <div className="d-flex flex-column align-items-center justify-content-center py-5">
            <h3 className="fs-2 fw-light">Invite people to your team.</h3>
            <a className="text-uppercase my-2 text-decoration-none fw-bold text-pla-secondary text-dark">
              + 5 points for every invited team member who joins.
            </a>
          </div>
        </div>
        <form
          className="d-flex align-items-center justify-content-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="wid-50 pad_lr_15px">
            <div className="col-12 mb-3">
              <label htmlFor="name" className="form-label text-dark">
                Email address
              </label>

              <input
                type="email"
                className="form-control"
                id="name"
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />

              {errors.email && (
                <div className="text-danger my-2 fst-italic">
                  This field is required.
                </div>
              )}
            </div>
            {/* <div className="col-12 mb-3">
              <label htmlFor="name" className="form-label text-dark">
                Personalized message
              </label>

              <textarea
                type="test"
                className="form-control"
                id="name"
                rows={7}
                {...register("message", { required: true })}
              ></textarea>

              {errors.message && (
                <div className="text-danger my-2 fst-italic">
                  This field is required.
                </div>
              )}
            </div> */}
            <div className=" pb-5 mb-5">
              <button
                className="btn bg-pla-primary text-white btn-sm me-2"
                type="submit"
              >
                Send Message
              </button>
              <button
                className="btn btn-secondary text-white btn-sm me-2"
                type="button"
                onClick={() => navigate("/dashboard/home")}
              >
                Return to team page
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default TeamInvitations;
