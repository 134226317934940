import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './nav-header.css';
import { format, parseISO } from 'date-fns';
import sponsoredlogo from '../../assets/images/waica.png';
import VideoModal from '../modals/VideoModal';

export default function NavHeader(props) {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);
  const handleVideoShow = () => setShowVideo(true);
  const handleVideoClose = () => setShowVideo(false);

  const goToSignUp = () => {
    navigate('/sign-up');
  };
  return (
    <>
      <VideoModal
        handleShow={handleVideoShow}
        handleClose={handleVideoClose}
        show={showVideo}
        data={props.videoData}
      />
      <div
        className="nav_header"
        style={{ backgroundImage: `url(${'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/BG_Group.png'})` }}
      >
        <div className="content web_banner">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-sm-12 col-xs-12">
                <h2 className="content_header fnt_22_5px">
                  Don't pollute; be a part of the solution.
                </h2>
                <p className="content_sub_header">
                  Plafera Challenge (Plastic Free Rainy Season Challenge) is a
                  3-month citizen-led voluntary challenge that inspires millions
                  of people to join in the fight against plastic pollution by
                  adopting small, everyday actions that would keep single-use
                  plastics out of the landfills and lessen the risk of plastic
                  polluting the environment or worsening urban flooding.
                  Together, we shall make our world a better place!
                </p>
                <div className="d-flex">
                  <button className="theme_btn" onClick={goToSignUp}>
                    {props.challengeData?.challenge_name
                      ? 'Join Challenge'
                      : 'Get started'}
                  </button>
                  {props.videoData?.data.length > 0 && (
                    <button className="flat_btn" onClick={handleVideoShow}>
                      <i className="fa fa-play-circle" aria-hidden="true"></i>{' '}
                      Watch video
                    </button>
                  )}
                  <button className="flat_btn">
                    <a href="https://www.waicare.com/" target="blank">
                      <img src={sponsoredlogo} style={{ width: '100px' }} />
                    </a>
                  </button>
                </div>
              </div>
              <div className="col col-md-6 col-sm-6 col-xs-12 mob_hide pos_rel">
                <div
                  className="globe_img"
                  style={{
                    backgroundImage: `url(${'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/globe.png'})`,
                  }}
                ></div>
                {props.challengeData && (
                  <div className="event_calender text-center">
                    {props.challengeData?.challenge_name}{' '}
                    {format(
                      new Date(props.challengeData?.start_date),
                      'MMMM d'
                    )}{' '}
                    –{' '}
                    {format(new Date(props.challengeData?.end_date), 'MMMM d')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          id="carouselExampleCaptions"
          className="carousel slide mob_banner"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="content">
                <div className="container">
                  <div className="row">
                    <div className="col col-md-12 col-sm-12 col-xs-12">
                      <div className="content_header fnt_22_5px">
                        Don't pollute; be a part of the solution.
                      </div>
                      <p className="content_sub_header">
                        Plafera Challenge (Plastic Free Rainy Season Challenge)
                        is a 3-month citizen-led voluntary challenge that
                        inspires millions of people to join in the fight against
                        plastic pollution by adopting small, everyday actions
                        that would keep single-use plastics out of the landfills
                        and lessen the risk of plastic polluting the environment
                        or worsening urban flooding. Together, we shall make our
                        world a better place!
                      </p>
                      <div className="mt-4 mb-4 mar_auto">
                        <a href="https://www.waicare.com/" target="blank">
                          <img src={sponsoredlogo} style={{ width: '120px' }} />
                        </a>
                      </div>
                      <div className="d-flex">
                        <button className="theme_btn" onClick={goToSignUp}>
                          Get started
                        </button>
                        {props.videoData?.data.length && (
                          <button
                            className="flat_btn"
                            onClick={handleVideoShow}
                          >
                            <i
                              className="fa fa-play-circle"
                              aria-hidden="true"
                            ></i>{' '}
                            Watch video
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="col col-md-12 col-sm-12 col-xs-12 mob_hide">
                      <div
                        className="globe_img"
                        style={{
                          backgroundImage: `url(${'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/globe.png'})`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="content">
                <div className="container">
                  <div className="row">
                    <div className="col col-md-12 col-sm-12 col-xs-12">
                      <div className="content_header fnt_22_5px">
                        Don't pollute; be a part of the solution.
                      </div>
                      <p className="content_sub_header">
                        Plafera Challenge (Plastic Free Rainy Season Challenge)
                        is a 3-month citizen-led voluntary challenge that
                        inspires millions of people to join in the fight against
                        plastic pollution by adopting small, everyday actions
                        that would keep single-use plastics out of the landfills
                        and lessen the risk of plastic polluting the environment
                        or worsening urban flooding. Together, we shall make our
                        world a better place!
                      </p>
                      <div className="mt-4 mb-4  mar_auto">
                        <a href="https://www.waicare.com/" target="blank">
                          <img src={sponsoredlogo} style={{ width: '120px' }} />
                        </a>
                      </div>
                      <div className="d-flex">
                        <button className="theme_btn" onClick={goToSignUp}>
                          Get started
                        </button>
                        {props.videoData?.data.length && (
                          <button
                            className="flat_btn"
                            onClick={handleVideoShow}
                          >
                            <i
                              className="fa fa-play-circle"
                              aria-hidden="true"
                            ></i>{' '}
                            Watch video
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="col col-md-12 col-sm-12 col-xs-12 mob_hide">
                      <div
                        className="globe_img"
                        style={{
                          backgroundImage: `url(${'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/globe.png'})`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="content">
                <div className="container">
                  <div className="row">
                    <div className="col col-md-12 col-sm-12 col-xs-12">
                      <div className="content_header fnt_22_5px">
                        Don't pollute; be a part of the solution.
                      </div>
                      <p className="content_sub_header">
                        Plafera Challenge (Plastic Free Rainy Season Challenge)
                        is a 3-month citizen-led voluntary challenge that
                        inspires millions of people to join in the fight against
                        plastic pollution by adopting small, everyday actions
                        that would keep single-use plastics out of the landfills
                        and lessen the risk of plastic polluting the environment
                        or worsening urban flooding. Together, we shall make our
                        world a better place!
                      </p>
                      <div className="mt-4 mb-4  mar_auto">
                        <a href="https://www.waicare.com/" target="blank">
                          <img src={sponsoredlogo} style={{ width: '120px' }} />
                        </a>
                      </div>
                      <div className="d-flex">
                        <button className="theme_btn" onClick={goToSignUp}>
                          Get started
                        </button>
                        {props.videoData?.data.length > 0 && (
                          <button
                            className="flat_btn"
                            onClick={handleVideoShow}
                          >
                            <i
                              className="fa fa-play-circle"
                              aria-hidden="true"
                            ></i>{' '}
                            Watch video
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="col col-md-12 col-sm-12 col-xs-12 mob_hide">
                      <div
                        className="globe_img"
                        style={{
                          backgroundImage: `url(${'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/globe.png'})`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* mobile banner carsole start */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
            <img
              src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/icon/icn_arrow_nextarrow.svg'}
              alt="carsole_next_btn"
              className="carsole_banner_img_left"
            />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
            <img
              src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/icon/icn_arrow_nextarrow.svg'}
              alt="carsole_next_btn"
              className="carsole_banner_img_right"
            />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}
