import React, { useRef, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "./home.css";
import NavHeader from "../header-layout/nav-header";
import HeaderLayout from "../header-layout/header-layout";
import axios from "axios";
import apiService from "../../services/api.service";
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import SwiperCore, { Autoplay } from "swiper";
import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";

import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css";

import LoadingSpinner from "../LoadingSpinner";
// import sponsoredlogo from "../../assets/images/waica.png";
// import FlutterWavePayment from "../payment/flutter-wave-payment";
// import challenge1 from "../../assets/images/challenge1.png";
// import challenge2 from "../../assets/images/challenge2.png";
// import challenge3 from "../../assets/images/challenge3.png";
// import challenge4 from "../../assets/images/challenge4.png";
import QuizTermsAndConditions from "../modals/QuizTermsAndConditions";

import PieChart from "../../helper/chart/pie-chart";
import * as homeAction from "../../store/actions/home";

function Home({ logout }) {
  SwiperCore.use([Autoplay]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, hash, key } = useLocation();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(null);
  const [homeData, setHomeData] = useState({});
  const [impact, setImpact] = useState(null);

  const [impactQueryData, setImpactQueryData] = useState(null);

  const [isShowToPaymentGatewayModel, setToPaymentGatewayModel] =
    useState(false);
  const [paymentValues, setpaymentValues] = useState(null);
  const [isShowPaymentBtn, setShowPaymentBtn] = useState(false);
  const [getWinnerOfTheWeek, settWinnerOfTheWeek] = useState(null);
  const [getWinnerOfTheMonth, settWinnerOfTheMonth] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);

  // const homeDataStore = useSelector((state) => state.home.homeData);
  const shopCountStore = useSelector((state) => state.home.storeData);
  // const impactsDataStore = useSelector((state) => state.home.impactsData);

  //Home data using the react Query//

  const {
    data: homeQueryData,
    isLoading: homeQueryLoading,
    error: homeQueryError,
    refetch: refetchHomeQuery,
  } = useQuery(
    "homeData",
    () =>
      axios
        .get(apiService.API_URL() + "/api/v1/home")
        .then((response) => response.data),
    {
      enabled: false, // Disable automatic execution
      onSuccess: (data) => {
        // console.log("HomeQueryData", data);
        dispatch(homeAction.setHomeData(data));
        localStorage.setItem("challenge", JSON.stringify(data.challenge));
      },
      onError: (error) => {
        if (error.message) {
          toast.error(error.message);
        }
      },
    }
  );

  useEffect(() => {
    if (homeQueryData) {
      dispatch(homeAction.setHomeData(homeQueryData));
      localStorage.setItem(
        "challenge",
        JSON.stringify(homeQueryData.challenge)
      );
    }
  }, [homeQueryData, dispatch]);

  useEffect(() => {
    if (homeQueryError) {
      if (homeQueryError.message) {
        toast.error(homeQueryError.message);
      }
    }
  }, [homeQueryError]);

  useEffect(() => {
    if (!homeQueryData && !homeQueryLoading && !homeQueryError) {  // Manually trigger the query when the component mounts
      refetchHomeQuery();
    }
  }, [homeQueryData, homeQueryLoading, homeQueryError, refetchHomeQuery]);

  //---------End of the HomeQueryData in top -------//


  //impact data//

  //impact data//
  const {
    data: impactsData,
    isLoading: impactsLoading,
    error: impactsError,
    refetch: refetchImpacts,
  } = useQuery(
    "impactData",
    () =>
      axios
        .get(apiService.API_URL() + "/api/v1/impact")
        .then((response) => response.data),
    {
      enabled: false, // Disable automatic execution
      onSuccess: (data) => {
        dispatch(homeAction.setImpactsData(data.impact));
        setImpactQueryData(data.impact); // Set impact data to state variable
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        if (error.message) {
          toast.error(error.message);
        }
      },
    }
  );

  useEffect(() => {
    if (impactsData) {
      dispatch(homeAction.setImpactsData(impactsData.impact));
      setLoading(false);
    }
  }, [impactsData, dispatch]);

  useEffect(() => {
    if (impactsError) {
      if (impactsError.message) {
        toast.error(impactsError.message);
      }
    }
  }, [impactsError]);

  useEffect(() => {
    if (!impactsData && !impactsLoading && !impactsError) {
      refetchImpacts();
    }
  }, [impactsData, impactsLoading, impactsError, refetchImpacts]);

  //--End of the Impact --//

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  // useEffect(() => {
  //   setImpact(impactsDataStore);
  // }, [impactsDataStore]);

  var today = new Date();
  var year = today.getFullYear();

  const bigChallengeSlider = [
    {
      id: 1,
      key: "Blog1",
      img_url: "https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/Content/Blog1.png",
    },
    {
      id: 2,
      key: "Blog2",
      img_url: "https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/Content/Blog2.png",
    },
    {
      id: 3,
      key: "Blog3",
      img_url: "https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/Content/ShareYourCommitment.png",
    },
  ];

  //Basker Quiz start
  const [isShowFirstQuestion, setIsShowFirstQuestion] = useState(true);
  const [isShowSecondQuestion, setIsShowSecondQuestion] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [firstQuestion, setFirstQuestion] = useState({});
  const [secondQuestion, setSecondQuestion] = useState({});
  const [answer1TimeTaken, setAnswer1TimeTaken] = useState(null);
  const [answer2TimeTaken, setAnswer2TimeTaken] = useState(null);
  const [seconds, setSeconds] = useState(15);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showTandC, setShowTandC] = useState(false);

  const handleShow = () => {
    if (isQuizAvailable === true) {
      setLoading(true);
      getQuizQuestion();
    }
  };

  const handleTandCShow = () => setShowTandC(true);
  const handleTandCClose = () => setShowTandC(false);

  const modalRef = useRef(null);
  const [resetTimer, setResetTimer] = useState();
  const [isQuizAvailable, setIsQuizAvailable] = useState(false);

  const [quizIsSubmitted, setQuizIsSubmitted] = useState(false);
  const [quizIsSubmittedModelOpen, setQuizIsSubmittedModelOpen] =
    useState(false);

  //Basker Quiz end

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (show) {
      if (isShowFirstQuestion === true) {
        setAnswer1TimeTaken(seconds);
      }

      if (isShowSecondQuestion === true) {
        setAnswer2TimeTaken(seconds);
      }
      setTimer();
      if (seconds === 0) {
        handleQuizSubmit();
      }
    }
  }, [show, seconds]);

  useEffect(() => {
    if (user) {
      setLoading(true);
      // getTodayQuizStatus();
    }
  }, [user]);


  const navigateToOverAllImpact = () => {
    navigate("/overall-impact");
  };

  const goToSignUp = () => {
    navigate("/sign-up");
  };

  const goToSignIn = () => {
    navigate("/sign-in");
  };

  const goToCalculator = () => {
    navigate("/omnicalculator");
  };

  const goToStaticPage = () => {
    navigate("/");
  };

  const joinChallenge = () => {
    navigate("/challenge/allactioncategory");
  };


  // Store data using the React Query
  const {
    data: storeData,
    isLoading: storeLoading,
    error: storeError,
    refetch: refetchStore,
  } = useQuery(
    "storeData",
    () =>
      axios
        .get(apiService.API_URL() + "/api/v1/shop_total_count")
        .then((response) => response.data),
    {
      enabled: false, // Disable automatic execution
      onSuccess: (data) => {
        dispatch(homeAction.setStoreData(data.total_count));
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        if (error.message) {
          toast.error(error.message);
        }
      },
    }
  );

  useEffect(() => {
    if (storeData) {
      dispatch(homeAction.setStoreData(storeData.total_count));
      setLoading(false);
    }
  }, [storeData]);

  useEffect(() => {
    if (storeError) {
      if (storeError.message) {
        toast.error(storeError.message);
      }
    }
  }, [storeError]);

  useEffect(() => {
    if (!storeData && !storeLoading && !storeError) {
      refetchStore();
    }
  }, [storeData, storeLoading, storeError, refetchStore]);

  // payment start
  const closePopup = () => {
    setToPaymentGatewayModel(false);
    setpaymentValues(null);
    setShowPaymentBtn(false);
    setQuizIsSubmittedModelOpen(false);
  };
  const isShowToPaymentGateway = () => {
    setToPaymentGatewayModel(true);
  };

  const onPaymentSumbit = (data) => {
    var formvalue = {
      name: data.customername,
      emailId: data.EmailId,
      phoneNo: data.PhoneNumber,
      donateAmt: data.Amount,
    };
    setpaymentValues(formvalue);
    setShowPaymentBtn(true);
  };

  const config = {
    public_key: apiService.paymentPublickKey(), // 'FLWPUBK_TEST-aec09808d78e8e4c36fcd2c0df4b3728-X'
    tx_ref: Date.now(),
    amount:
      paymentValues?.donateAmt != null ? Number(paymentValues.donateAmt) : 0,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: paymentValues?.emailId != null ? paymentValues.emailId : "",
      phonenumber: paymentValues?.phoneNo != null ? paymentValues.phoneNo : "",
      name: paymentValues?.name != null ? paymentValues.name : "",
    },
    customizations: {
      title: "plafera",
      description: "Donation",
      logo: "https://plafera.org/assets/images/image.png",
    },
  };

  const fwConfig = {
    ...config,
    text: "Pay Now...",
    callback: (response) => {
      closePaymentModal(); // this will close the modal programmatically

      setToPaymentGatewayModel(false);
      setpaymentValues(null);
      setShowPaymentBtn(false);
      toast.success("Payment is" + " " + response.status);
    },
    onClose: () => { },
  };

  // payment end

  // Quiz start

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleQuizSubmit = () => {
    clearTimeout(resetTimer);
    if (isShowFirstQuestion && Object.keys(secondQuestion).length === 0) {
      modalRef.current.click();
    }
    if (isShowFirstQuestion) {
      setIsShowSecondQuestion(true);
      setIsShowFirstQuestion(false);
      setSeconds(15);
    }

    if (isShowSecondQuestion) {
      setAnswer2TimeTaken(seconds);
      setIsShowSecondQuestion(false);
      modalRef.current.click();
    }
  };

  const onSubmit = async (data) => {
    let time_taken_question_1 = 0;
    let time_taken_question_2 = 0;

    if (answer1TimeTaken !== null) {
      time_taken_question_1 = 12 - answer1TimeTaken;
    }

    if (answer2TimeTaken !== null) {
      time_taken_question_2 = 12 - answer2TimeTaken;
    }

    const formData = new FormData();
    formData.append("first_question_id", data.first_question);
    formData.append("first_question_answer", data.first_question_answer);
    formData.append("second_question_id", data.second_question);
    formData.append("second_question_answer", data.second_question_answer);
    formData.append("time_taken_question_1", time_taken_question_1);
    formData.append("time_taken_question_2", time_taken_question_2);
    formData.append("user_id", user.id);

    axios
      .post(apiService.API_URL() + "/api/v1/save-quiz", formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.data.code == 200) {
          toast.success("Thank you for participating in the quiz");
          setIsQuizAvailable(false);
          setQuizIsSubmitted(true);
          setQuizIsSubmittedModelOpen(true);
        }
        if (response.data.code == 500) {
          toast.error(response.message);
          setIsQuizAvailable(true);
        }
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  // const getTodayQuizStatus = () => {
  //   setLoading(true);
  //   axios
  //     .get(apiService.API_URL() + `/api/v1/quiz-status?user_id=${user.id}`, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then(function (response) {
  //       if (response.data.is_elgible_quiz) {
  //         setIsQuizAvailable(true);
  //         setQuizIsSubmitted(false);
  //       } else if (response.data.is_elgible_quiz === false) {
  //         setIsQuizAvailable(false);
  //         setQuizIsSubmitted(true);
  //       }

  //       if (response.data.is_attended_quiz_this_week) {
  //         toast.info(`This week's quiz result will be announced tomorrow`);
  //       }
  //       setLoading(false);
  //     })
  //     .catch(function (error) {
  //       setLoading(false);
  //       if (error.response.data.errors) {
  //         for (let i = 0; i < error.response.data.errors.length; i++) {
  //           toast.error(error.response.data.errors[i]);
  //         }
  //       }
  //     });
  // };

  const getQuizQuestion = () => {
    setLoading(true);
    axios
      .get(apiService.API_URL() + `/api/v1/quiz?user_id=${user.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        if (response.data.quiz.length > 0) {
          setFirstQuestion(response.data.quiz[0]);
        }
        if (response.data.quiz.length > 1) {
          setSecondQuestion(response.data.quiz[1]);
        }
        setLoading(false);
        setShow(true);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const setTimer = () => {
    setResetTimer(setTimeout(() => setSeconds(seconds - 1), 1000));
  };

  const showQuizsSubmitted = () => {
    if (quizIsSubmitted == true) {
      setQuizIsSubmittedModelOpen(true);
    }
  };
  // Quiz end



  // Quiz result data using the react Query//
  //Get-quiz-result alter to the React Query//

  const {
    data: quizResultData,
    isLoading: quizResultLoading,
    error: quizResultError,
    refetch: refetchQuizResult,
  } = useQuery(
    "quizResultData",
    () =>
      axios
        .get(apiService.API_URL() + `/api/v1/quiz/get-quiz-result`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.data),
    {
      enabled: false, // Disable automatic execution
      onSuccess: (data) => {
        settWinnerOfTheWeek(data.quiz_result);
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        if (error.message) {
          toast.error(error.message);
        }
      },
    }
  );

  useEffect(() => {
    if (quizResultData) {
      settWinnerOfTheWeek(quizResultData.quiz_result);
      setLoading(false);
    }
  }, [quizResultData]);

  useEffect(() => {
    if (quizResultError) {
      if (quizResultError.message) {
        toast.error(quizResultError.message);
      }
    }
  }, [quizResultError]);

  useEffect(() => {
    if (!quizResultData && !quizResultLoading && !quizResultError) {
      refetchQuizResult();
    }
  }, [quizResultData, quizResultLoading, quizResultError, refetchQuizResult]);

  //--End of the QuizResultData --//


  //Get-ser-result alter to the React Query//
  const {
    data: winnerOfTheMonthData,
    isLoading: winnerOfTheMonthLoading,
    error: winnerOfTheMonthError,
    refetch: refetchWinnerOfTheMonth,
  } = useQuery(
    "winnerOfTheMonthData",
    () =>
      axios
        .get(apiService.API_URL() + `/api/v1/quiz/get-user-result`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.data),
    {
      enabled: false, // Disable automatic execution
      onSuccess: (data) => {
        settWinnerOfTheMonth(data.quiz_result);
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        if (error.message) {
          toast.error(error.message);
        }
      },
    }
  );

  useEffect(() => {
    if (winnerOfTheMonthData) {
      settWinnerOfTheMonth(winnerOfTheMonthData.quiz_result);
      setLoading(false);
    }
  }, [winnerOfTheMonthData]);

  useEffect(() => {
    if (winnerOfTheMonthError) {
      if (winnerOfTheMonthError.message) {
        toast.error(winnerOfTheMonthError.message);
      }
    }
  }, [winnerOfTheMonthError]);

  useEffect(() => {
    if (!winnerOfTheMonthData && !winnerOfTheMonthLoading && !winnerOfTheMonthError) {
      refetchWinnerOfTheMonth();
    }
  }, [winnerOfTheMonthData, winnerOfTheMonthLoading, winnerOfTheMonthError, refetchWinnerOfTheMonth]);

  //End of get-user-result using the react query//

  return (
    <>
      <ToastContainer />
      <LoadingSpinner hidden={!loading} />
      <HeaderLayout logout={logout} />
      <NavHeader
        challengeData={homeQueryData?.challenge}
        videoData={homeQueryData?.video}
      />
      <QuizTermsAndConditions
        handleShow={handleTandCShow}
        handleClose={handleTandCClose}
        show={showTandC}
      />
      <section className="big_challenge">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <h3 className="title mrg_t_60  fnt_22_5px">
                Small steps lead to
              </h3>
              <h3 className="big_change mb-4 fnt_34px">Big Change</h3>
              <p className="content">
                We recognize that going plastic-free may appear difficult;
                however, we have made it simple for you to get started and
                commit to reducing your reliance on single-use plastics. Through
                the Plafera Progressive Web App, participants would choose
                actions that are consistent with their individual lifestyles,
                from the beginning of the rainy season each year and make a
                pledge to complete those actions, while practicing and
                reinforcing healthy habits. For every completed action,
                participants will earn points and create impact
              </p>
              <div className="row">
                <div className="col-md-4 col-sm-4 col-xs-12 tab_wid_100 text-center">
                  <div
                    className="big_card mt-4 cursor-pointer"
                    onClick={goToSignUp}
                  >
                    <img
                      src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/BIg_change_icn_1.svg"}
                      alt="BIg_change_icn_1"
                    />
                    <h4 key="title-sign-up" className="title">
                      Sign up
                    </h4>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12 tab_wid_100 text-center">
                  <div className="big_card mt-4 cursor-pointer">
                    <img
                      src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/BIg_change_icn_2.svg"}
                      alt="BIg_change_icn_2"
                    />
                    <h4 key="plastic-footprint-calculator" className="title">
                      <a
                        href={apiService.onmiCalc_Iframe_URL()}
                        className="mb-5 mt-5"
                        target="_blank"
                        style={{ textDecoration: "none", color: "#2c2c2c" }}
                      >
                        Plastic Footprint Calculator
                      </a>
                    </h4>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-12 tab_wid_100 text-center">
                  <div
                    className="big_card mt-4 cursor-pointer"
                    onClick={goToSignUp}
                  >
                    <img
                      src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/BIg_change_icn_3.svg"}
                      alt="BIg_change_icn_3"
                    />
                    <h4 key="title-join-challenge" className="title">
                      Join Challenge
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 col-xs-12">
              <Swiper
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="my-4"
                style={{ minHeight: "180px" }}
                breakpoints={{
                  576: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                }}
              >
                {bigChallengeSlider.map((data, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      navigation="true"
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={data.img_url}
                          alt="data"
                          className="mt-4"
                          style={{ height: "500px", objectFit: "cover" }}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="challenge">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="title text-center"> </div>
              <h3 key="title-fnt_22_5px" className="title fnt_22_5px">
                Plastic Footprint
              </h3>
              <h3 key="title-mb-4-fnt_34px" className="title mb-4 fnt_34px">
                Calculator
              </h3>
              <p className="content">
                {" "}
                The ubiquity of plastic is a terrifying fact - everywhere around
                us: in the ground and in the oceans, in our food and water. More
                than 8 million tons of plastic are dumped in our oceans yearly;
                by 2050, we will have more plastic waste than fish.
                <br />
                <br />
                It is estimated that only 5-10 % of plastic worldwide is
                recycled. More than 30 % of it ends up in a landfill, and around
                one-third - in the oceans. How embarrassing.
                <br />
                <br />
                Did you know that microplastic - if inhaled or eaten by a person
                - increases the risk of cancer, asthma, infertility, and
                developing mental disorders?
                <br />
                <br />
                Find out how much plastic you will be using in a year. Believe
                it or not, we can live without all of these plastic bags,
                disposable cups, straws, and plastic bottles, so we encourage
                you to quit some bad habits and reduce your plastic footprint.
              </p>
              <br />
              <a
                href={apiService.onmiCalc_Iframe_URL()}
                className="theme_btn theme_btn_atag mb-5 mt-5"
                target="_blank"
              >
                Plastic Footprint
              </a>
              <div className="row">
                <div className="col-md-12">
                  <div className="mrg_t_60 mt-5">
                    <h3 className="title mob_mrg_t_70 fnt_34px">Our Sponsor</h3>
                    <div className="big_challenge">
                      <Swiper
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Pagination]}
                        className="my-4"
                        style={{ minHeight: "180px" }}
                        breakpoints={{
                          576: {
                            slidesPerView: 2,
                          },
                          768: {
                            slidesPerView: 1,
                          },
                        }}
                      >
                        {homeQueryData?.sponsors?.map((data, index) => {
                          return (
                            <SwiperSlide
                              key={index}
                              navigation="true"
                              modules={[Navigation]}
                              className="mySwiper"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={data.sponsor_logo}
                                  alt="waica"
                                  className="waica mt-4"
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                    <p className="mt-4 fnt_16">
                      Plafera App development was sponsored by{" "}
                      <a href="http://www.waicare.com" target="_blank">
                        WAICA Re
                      </a>
                    </p>

                    <p className="text-center mt-5">
                      <button className="theme_btn" onClick={goToSignUp}>
                        {homeQueryData?.challenge?.challenge_name
                          ? "Join Challenge"
                          : "Get started"}
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row palfera-challenge-slider">
                <h5 className="col-md-12 title-big text-center fnt_34px">
                  {homeQueryData?.challenge?.challenge_name}
                </h5>
                <Swiper
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                  style={{ minHeight: "180px" }}
                  breakpoints={{
                    576: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  <SwiperSlide
                    navigation="true"
                    modules={[Navigation]}
                    className="mySwiper"
                    key="SwiperSlideHome"
                  >
                    <div>
                      <img
                        src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/Home.png"}
                        alt="data"
                        className="mt-4"
                      />
                      <div className="card-body text-center">
                        <h6 className="card-title">At Home </h6>
                        <button
                          className="theme_btn mb-5 mt-5"
                          onClick={goToSignUp}
                        >
                          {homeQueryData?.challenge?.challenge_name
                            ? "Join Challenge"
                            : "Get Started"}
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide
                    navigation="true"
                    modules={[Navigation]}
                    className="mySwiper"
                    key="mySwiperWork"
                  >
                    <div>
                      <img
                        src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/Work.png"}
                        alt="data"
                        className="mt-4"
                        style={{ objectFit: "cover" }}
                      />
                      <div className="card-body text-center">
                        <h6 className="card-title">At Workplace </h6>
                        <button
                          className="theme_btn mb-5 mt-5"
                          onClick={goToSignUp}
                        >
                          {homeQueryData?.challenge?.challenge_name
                            ? "Join Challenge"
                            : "Get Started"}
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    navigation="true"
                    modules={[Navigation]}
                    className="mySwiper"
                    key="mySwiperSchool"
                  >
                    <div>
                      <img
                        src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/School.png"}
                        alt="data"
                        className="mt-4"
                      />
                      <div className="card-body text-center">
                        <h6 className="card-title">At School </h6>
                        <button
                          className="theme_btn mb-5 mt-5"
                          onClick={goToSignUp}
                        >
                          {homeQueryData?.challenge?.challenge_name
                            ? "Join Challenge"
                            : "Get Started"}
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    navigation="true"
                    modules={[Navigation]}
                    className="mySwiper"
                    key="mySwiperIndividual"
                  >
                    <div>
                      <img
                        src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/Individual.png"}
                        alt="data"
                        className="mt-4"
                      />
                      <div className="card-body text-center">
                        <h6 className="card-title">As Individual </h6>
                        <button
                          className="theme_btn mb-5 mt-5"
                          onClick={goToSignUp}
                        >
                          {homeQueryData?.challenge?.challenge_name
                            ? "Join Challenge"
                            : "Get Started"}
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    navigation="true"
                    modules={[Navigation]}
                    className="mySwiper"
                    key="mySwiperCommunity"
                  >
                    <div>
                      <img
                        src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/Community.png"}
                        alt="data"
                        className="mt-4"
                      />
                      <div className="card-body text-center">
                        <h6 className="card-title">At Community </h6>
                        <button
                          className="theme_btn mb-5 mt-5"
                          onClick={goToSignUp}
                        >
                          {homeQueryData?.challenge?.challenge_name
                            ? "Join Challenge"
                            : "Get Started"}
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide
                    navigation="true"
                    modules={[Navigation]}
                    className="mySwiper"
                    key="mySwiperEvent"
                  >
                    <div>
                      <img
                        src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/Event.png"}
                        alt="data"
                        className="mt-4"
                      />
                      <div className="card-body text-center">
                        <h6 className="card-title">At Event </h6>
                        <button
                          className="theme_btn mb-5 mt-5"
                          onClick={goToSignUp}
                        >
                          {homeData?.challenge?.challenge_name
                            ? "Join Challenge"
                            : "Get Started"}
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="oursponsors" id="oursponsors">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="title fnt_34px">Partners</div>
              <p className="mt-4 fnt_16">
                They motivate us with their firm commitment to sustainability,
                which enables us to band together to take action and broaden our
                collective impact. Contact us at{" "}
                <a href="partnerships@plafera.org">partnerships@plafera.org</a>{" "}
                if your organization is interested in supporting our efforts to
                end plastic pollution.
              </p>
            </div>

            <Swiper
              autoplay={{ delay: 3000 }}
              // slidesPerView={3}
              // spaceBetween={80}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="bg-white my-4"
              breakpoints={{
                576: {
                  // width: 576,
                  slidesPerView: 1,
                },
                768: {
                  // width: 768,
                  slidesPerView: 3,
                },
              }}
            >
              {homeQueryData?.partners?.map((partner, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 20,
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={partner.image}
                        alt={partner.title}
                        style={{ height: "200px" }}
                      />
                      <a href={partner.link} target="_blank" className="my-3">
                        {partner.title}
                      </a>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>

      <section className="ourevents" id="ourevents">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="title fnt_34px">Notification</div>
              <p className="mt-4 fnt_16">
                Important Notice and Events section. Here, you will find crucial
                information and updates that you need to be aware of.
              </p>
            </div>

            <Swiper
              autoplay={{ delay: 3000 }}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              effect="fade"
              className="bg-white my-4"
              breakpoints={{
                576: {
                  // width: 576,
                  slidesPerView: 1,
                },
                768: {
                  // width: 768,
                  slidesPerView: 3,
                },
              }}
            >
              {homeQueryData?.notices?.map((notification, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 30,
                        paddingTop: 10,
                        flexDirection: "column",
                        borderRadius: 10,
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        maxHeight: "80%",
                      }}
                    >
                      <Link
                        to={`/event/${notification.title_slug}`}
                        target="_blank"
                        className="my-3"
                      >
                        {notification.title.length > 80
                          ? notification.title.substring(0, 80) + "..."
                          : notification.title}
                      </Link>
                      <p>
                        {notification.summary.length > 150
                          ? notification.summary.substring(0, 150) + "..."
                          : notification.summary}
                      </p>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
      <section className="changeismadeby">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 tab_wid_100">
              <div className="title mrg_t_60 fnt_34px ">
                Change is made by all of us
              </div>
              <p className="content mt-4 fnt_16">
                Change is a bit simpler — and more enjoyable — when combined
                with team inspiration, friendship, and friendly competition.
                Participants share their progress, successes, and insights with
                fellow Plafera Challengers during the Plafera Challenge.
              </p>
              <p className="content mt-4 fnt_16">
                The points earned by participants add to the team's overall
                score, and their collective points increase the seasonal
                challenge's overall impact.
              </p>
              <p className="content mt-4 fnt_16">
                We are now switching from single-use plastics to environmentally
                friendly alternatives thanks to the Plafera Challenge. It’s a
                win for our nations and the planet.
              </p>
              <p className="content mt-4 fnt_16">
                Taking the Plafera quiz will help you find out how much you know
                about the environment and its problems, such as environmental
                pollution, global warming, waste management, climate change,
                biodiversity, the ecosystem, etc. Would you like to test your
                knowledge now?
              </p>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 tab_wid_100">
              <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12">
                  <div className="changeismadeby_card mrg_t_60">
                    <div className="mar_auto">
                      <img src="https://storage.googleapis.com/plafera_bucket-1/plaferaImages/assets/images/challenge4.png" />
                    </div>
                    <div className="title">
                      {homeQueryData?.challenge_participants_count} Participants
                    </div>
                    <p className="content">Number of participants</p>
                  </div>
                  <a
                    href="https://shop.plafera.org/"
                    target="blank"
                    style={{ textDecoration: "none", color: "#2C2C2C" }}
                    className="changeismadeby_card mrg_t_20 cursor-pointer"
                  // onClick={() => navigate('https://shop.plafera.org/')}
                  >
                    <div className="mar_auto">
                      <img src="https://storage.googleapis.com/plafera_bucket-1/plaferaImages/assets/images/challenge2.png" />
                    </div>
                    <div className="title">
                      {shopCountStore ? shopCountStore : 0} Single-use
                      Alternatives
                    </div>
                    <p className="content">Number of products available</p>
                  </a>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 mrg_t_60">
                  <div className="changeismadeby_card mrg_l_20">
                    <div className="mar_auto">
                      <img src="https://storage.googleapis.com/plafera_bucket-1/plaferaImages/assets/images/challenge3.png" />
                    </div>
                    <div className="title">
                      {homeQueryData?.actions_count
                        ? homeQueryData?.actions_count[0]?._count
                        : "0"}{" "}
                      Actions
                    </div>
                    <p className="content">Completed</p>
                  </div>
                  <div
                    className="mrg_l_20 changeismadeby_card mt-4 cursor-pointer"
                    onClick={
                      user === null
                        ? goToSignIn
                        : quizIsSubmitted === true
                          ? showQuizsSubmitted
                          : handleShow
                    }
                  >
                    <div className="mar_auto">
                      <img src="https://storage.googleapis.com/plafera_bucket-1/plaferaImages/assets/images/challenge1.png" />
                    </div>
                    <div className="title">Plafera Quiz</div>
                    {user === null ? (
                      <p className="content" style={{ color: "#0067ff" }}>
                        Please login to view today's quiz
                      </p>
                    ) : (
                      <p className="content" style={{ color: "#0067ff" }}>
                        Please click to view today's quiz
                      </p>
                    )}
                  </div>
                </div>

                <div className="text-center">
                  <span
                    className="text-primary cursor-pointer me-1"
                    onClick={handleTandCShow}
                  >
                    Terms and Conditions
                  </span>
                  for Plafera Quiz
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="plafera_impact">
        <div className="container">
          <div className="row">
            <div className="title mb-5 fnt_34px">Winners of the Week</div>
            {getWinnerOfTheWeek?.map((item, index) => {
              return (
                <div className="col-md-4 mb-5 mt-3" key={index}>
                  <div className="winner_card">
                    {/* <p className="small_txt"><i>Weekly</i> </p> */}
                    <p className="upto"> {item.user_name}</p>
                    <p className="small_txt"> {item.email} </p>
                    <p className="result"> {item.result_rank}</p>
                  </div>
                </div>
              );
            })}

            {getWinnerOfTheWeek?.length == 0 && (
              <div className="no_data"> No Data For Winner Of The Week</div>
            )}
          </div>

          <div className="row mt-4">
            <div className="title mb-5 fnt_34px">Winner of the Month</div>
            {getWinnerOfTheMonth?.map((item, index) => {
              return (
                <div className="col-md-12 mb-5 mt-3" key={index}>
                  <div className="winner_card">
                    <p className="upto"> {item.user_name}</p>
                    <p className="small_txt"> {item.email}</p>
                  </div>
                </div>
              );
            })}

            {getWinnerOfTheMonth?.length == 0 && (
              <div className="no_data"> No Data For Winner Of The Month</div>
            )}
          </div>
        </div>
      </section>

      {homeQueryData?.challenge?.challenge_name != null && (
        <>
          <section className="plafera_impact">
            <div className="container">
              <div className="row">
                {impactQueryData && (
                  <div className="title fnt_34px">Plafera Impact</div>
                )}

                {impactQueryData &&
                  impactQueryData.map((item, index) => (
                    <div key={index} className="col-md-6">
                      <div className="impact_card">
                        <div className="img_set">
                          <img src={item.image} alt="water-pollution" />
                        </div>
                        <p className="upto">Up to</p>
                        <p
                          className="number"
                          style={{
                            color:
                              item.slug === "skip-plastic-bags"
                                ? "red"
                                : item.slug === "skip-pure-water"
                                  ? "#807500"
                                  : item.slug === "say-no-to-styrofoam"
                                    ? "#238563"
                                    : "#007FAD",
                          }}
                        >
                          {item.count}
                        </p>
                        <p className="content">{item.name}</p>
                        <p className="fw-bold">{item.description}</p>
                      </div>
                    </div>
                  ))}

                <div className="col-md-12">
                  <div className="mar_auto mt-5 mb-4">
                    <button
                      className="theme_btn mb-5 mt-5"
                      onClick={navigateToOverAllImpact}
                      style={{ width: "unset" }}
                    >
                      View OverAll Impact
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pie_chart">
            <PieChart />
          </section>
        </>
      )}

      <section className="happy_customers">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <img
                src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/HappyParticipantsV2.png"}
                alt="happycustomer"
              />
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="mrg_r_60 mrg_t_60 mt-4 ">
                <div className="title fnt_34px">
                  We have thousands of happy participants
                </div>
                <p className="content mt-4 fnt_16">
                  Whether at home, work, or events, the Plafera Challenge offers
                  a variety of ways for people to reduce their usage of plastic.
                </p>
                <p className="content mt-4 fnt_16">
                  Be part of the solution to plastic pollution and not part of
                  the problem, join Plastic Free Rainy Season Challenge (Plafera
                  Challenge), and together we make the difference.
                </p>
                <button className="theme_btn mb-5 mt-5" onClick={goToSignUp}>
                  {homeQueryData?.challenge?.challenge_name
                    ? "Join Challenge"
                    : "Get Started"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="happy_customers" style={{ paddingTop: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="title fnt_34px">Make A Donation</div>
              <p className="content mt-4 fnt_16">
                Your donations enable us to support people and organizations
                taking the Plafera challenge, to help create a world without
                plastic waste. To make a donation, simply use your preferred
                method from the options below. We greatly appreciate your
                donation and continued support for the Plafera challenge.
                Donations are tax-deductible to the extent allowed by law and
                are graciously accepted online, by phone, or via mail. Please
                contact us with any questions. Thank you!
              </p>
              <button
                className="theme_btn mt-5"
                onClick={isShowToPaymentGateway}
              >
                Donate
              </button>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer" style={{ marginTop: "90px" }}>
        <div className="center">
          <div className="row">
            <div className="col-md-5 col-sm-12 col-xs-12">
              <h4 key="create-more-environmental-social-good">
                create more environmental + social good
              </h4>
            </div>
            <div className="col-md-3 col-sm-12 col-xs-12 curvedarrowbody">
              <img
                src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/curvedarrow.svg"}
                alt="curvedarrow"
                className="curvedarrow"
              />
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
              <div className="btn_group">
                <img
                  src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/btn_highlighter1.svg"}
                  alt="curvedarrow"
                  className="btn_decorator1"
                />
                <button onClick={joinChallenge}>Join Plafera Challenge</button>
                <img
                  src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/btn_highlighter1.svg"}
                  alt="curvedarrow"
                  className="btn_decorator2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="copy_right">
          <div className="left">
            <img
              src={"https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/image.png"}
              alt="logo"
              className="cursor-pointer"
            />
            <span className="logo_txt cursor-pointer">PLAFERA</span>
            <span className="copy_right_txt">Copyright by {year} Plafera</span>
          </div>
          <div className="flex_grow1"></div>
          <div className="footer_sponsor">
            <img src="https://storage.googleapis.com/plafera_bucket-1/plaferaImages/assets/images/waica.png" alt="logo" className="mob_view" />
            <p className="copy_right_txt">Plafera App sponsored by WAICA Re</p>
            <img src="https://storage.googleapis.com/plafera_bucket-1/plaferaImages/assets/images/waica.png" alt="logo" className="web_view" />
          </div>
          <div className="right">
            <div className="container">
              <ul>
                <li onClick={isShowToPaymentGateway}>
                  <i className="fa fa-id-card-o mob_icn" aria-hidden="true"></i>{" "}
                  <span>DONATE</span>
                </li>
                <li onClick={goToStaticPage}>
                  {/* <a href="/"> */}
                  <i className="fa fa-home mob_icn" aria-hidden="true"></i>{" "}
                  <span>HOME</span>
                  {/* </a> */}
                </li>
                <li>
                  <a href="https://shop.plafera.org/" target="blank">
                    <i
                      className="fa fa-shopping-cart mob_icn"
                      aria-hidden="true"
                    ></i>{" "}
                    <span> STORE </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      {/* payment popup start */}

      {isShowToPaymentGatewayModel && (
        <div className="popup_body">
          <div className="popup_content">
            <div className="popup_close" onClick={closePopup}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5 className="payment_title text-center mt-4 mb-4">DONATE</h5>
              </div>
            </div>

            {isShowPaymentBtn == true && (
              <div className="payment_subtitle">Proceed To Pay...</div>
            )}
            <form className="row" onSubmit={handleSubmit(onPaymentSumbit)}>
              {isShowPaymentBtn == false && (
                <>
                  <div className="col-md-6">
                    <label htmlFor="Name" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      {...register("customername", { required: true })}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="EmailId" className="form-label">
                      Email Id <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="EmailId"
                      {...register("EmailId", { required: true })}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="PhoneNumber" className="form-label">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PhoneNumber"
                      {...register("PhoneNumber", { required: true })}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="Amount" className="form-label">
                      Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="Amount"
                      {...register("Amount", { required: true })}
                    />
                  </div>
                </>
              )}

              {isShowPaymentBtn == false && (
                <div className="col-md-12 colsm-12 col-xs-12">
                  <div className="mar_auto mt-4 mb-4">
                    <button className="theme_btn">Submit</button>
                  </div>
                </div>
              )}

              {isShowPaymentBtn == true && (
                <div className="col-md-12 colsm-12 col-xs-12">
                  <div className="mar_auto mt-4 mb-4 payment_btn">
                    <FlutterWaveButton {...fwConfig} />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      )}

      {/* Quizs start */}
      {quizIsSubmittedModelOpen == false && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <>
            <Modal.Header>
              <Modal.Title>Today Quiz </Modal.Title>
              <span className="float-right">Time Left: {seconds}</span>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit(onSubmit)}>
                {firstQuestion && isShowFirstQuestion && (
                  <div className="m-4 row">
                    <h5 className="mb-3">First Question</h5>
                    <div className="bg-pla-gray row">
                      <div className={`my-2`}>
                        <label
                          htmlFor="terms-and-conditions"
                          className="form-label text-dark text-capitalize"
                        >
                          {firstQuestion.title}
                        </label>

                        {firstQuestion.options &&
                          firstQuestion.options.map((option, index) => {
                            return (
                              <div key={index} className="form-check my-2">
                                <input
                                  className="form-check-input"
                                  type="hidden"
                                  {...register(`first_question`)}
                                  value={firstQuestion.id}
                                />

                                <input
                                  className="form-check-input"
                                  type="hidden"
                                  {...register(`time_taken_first_question`)}
                                  value={answer1TimeTaken}
                                />

                                {firstQuestion.answer_type === "one" && (
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    {...register(`first_question_answer`)}
                                    value={option.id}
                                  />
                                )}

                                {firstQuestion.answer_type === "multiple" && (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    {...register(`first_question_answer`)}
                                    value={option.id}
                                  />
                                )}
                                <label
                                  className="form-check-label text-black"
                                  htmlFor="firstChallenge"
                                >
                                  {option.title}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}

                {secondQuestion && isShowSecondQuestion && (
                  <div className="m-4 row">
                    <h5 className="mb-3">Second Question</h5>
                    <div className="bg-pla-gray row">
                      <div className={`my-2`}>
                        <input
                          className="form-check-input"
                          type="hidden"
                          {...register(`second_question`)}
                          value={secondQuestion.id}
                        />

                        <label
                          htmlFor="terms-and-conditions"
                          className="form-label text-dark text-capitalize"
                        >
                          {secondQuestion.title}
                        </label>

                        {secondQuestion.options &&
                          secondQuestion.options.map((option, index) => {
                            return (
                              <div key={index} className="form-check my-2">
                                {secondQuestion.answer_type === "one" && (
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    {...register(`second_question_answer`)}
                                    value={option.id}
                                  />
                                )}

                                {secondQuestion.answer_type === "multiple" && (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    {...register(`second_question_answer`)}
                                    value={option.id}
                                  />
                                )}

                                <label
                                  className="form-check-label text-black"
                                  htmlFor="firstChallenge"
                                >
                                  {option.title}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}

                <Button
                  variant="success"
                  type="submit"
                  className="d-none"
                  ref={modalRef}
                  onClick={handleClose}
                >
                  save
                </Button>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={handleQuizSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </>
        </Modal>
      )}

      {quizIsSubmittedModelOpen == true && (
        <>
          <div className="popup_body">
            <div className="popup_content">
              <div className="popup_close" onClick={closePopup}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h5 className="payment_title text-center mt-4 mb-4">
                    Quiz is Done....
                  </h5>
                </div>
                <div className="col-md-12 mt-5 mb-4 text-center">
                  You have Done your Quiz, Please wait for the next announcement
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Quizs end */}

      {/* add to home screen start */}

      {/* add to home screen end */}
    </>
  );
}

export default Home;
