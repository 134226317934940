import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";
import { useForm } from "react-hook-form";

import Select from "react-select";
import HeaderLayout from "../../components/header-layout/header-layout";
import Footer from "../../components/footer-layout/footer";
import DateTimePicker from "react-datetime-picker";
import "./style.css";

import * as eventActions from "../../store/actions/events";

import CreatableSelect from "react-select/creatable";

const UpdateTeamEvent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  let { id } = useParams();
  const [countries, setCountries] = useState([]);
  const [teams, setTeams] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [area, setArea] = useState([]);

  const [countriesHasError, setCountriesHasError] = useState(null);
  const [stateHasError, setStateHasError] = useState(null);
  const [cityHasError, setCityHasError] = useState(null);
  const [teamHasError, setTeamHasError] = useState(null);
  const [postalCodeHasError, setPostalCodeHasError] = useState(null);

  const [eventData, setEventData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState({});
  const [selectedPostalCode, setSelectedPostalCode] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  const userIdResult = localStorage.getItem("user");
  const userData = JSON.parse(userIdResult);

  const user = useSelector((state) => state.auth.user);

  const [notLoading, setNotLoading] = useState(true);
  const [eventDatetime, onChange] = useState(new Date());

  const eventDataStore = useSelector((state) => state.events.eventById);

  const changeKey = (arrayObj) => {
    for (var i = 0; i < arrayObj.length; i++) {
      arrayObj[i].value = arrayObj[i]["id"];
      arrayObj[i].label = arrayObj[i]["name"];
      if (arrayObj[i]["name"] == "Nigeria") {
        setSelectedCountry(arrayObj[i]);
      }
      delete arrayObj[i].id;
      delete arrayObj[i].name;
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setSelectedTeam(1);
    countriesData();
    teamData();
    fetchTeamData();
  }, []);

  useEffect(() => {
    if (eventDataStore) {
      setEventData(eventDataStore);
    }
  }, [eventDataStore]);

  useEffect(() => {
    if (Object.keys(eventData).length > 0 && teams.length > 0) {
      let selectedTeam = teams.find(
        (team) => parseInt(eventData.team_id) === parseInt(team.value)
      );

      onChangeTeam(selectedTeam);
    }
  }, [eventData, teams]);

  useEffect(() => {
    if (Object.keys(eventData).length > 0 && countries.length > 0) {
      let selectedCountry = countries.find(
        (country) => parseInt(eventData.country) === parseInt(country.value)
      );
      onChangeCountry(selectedCountry);
    }
  }, [eventData, countries]);

  useEffect(() => {
    if (Object.keys(eventData).length > 0 && state.length > 0) {
      let selectedState = state.find(
        (s) => parseInt(eventData.state) === parseInt(s.value)
      );
      selectedState && onChangeState(selectedState);
    }
  }, [eventData, state]);

  const fetchTeamData = async () => {
    axios
      .get(apiService.API_URL() + `/api/v1/get-event-details/${id}`, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        dispatch(eventActions.getEventById(response.data.data));
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const onChangeFile = (e) => {};

  const onChangeCountry = async (value) => {
    setSelectedCountry(value);
    setPostalCode([]);
    setSelectedState({});
    setSelectedCity({});
    setSelectedPostalCode(null);
    setCountriesHasError(false);
    getState(value.value);

    if (value.label != "Nigeria") {
      setSelectedArea(null);
      setValue("postal_code", null);
    }
  };

  const getState = async (value) => {
    axios
      .get(apiService.API_URL() + `/api/v1/states/${value}`)
      .then(function (response) {
        changeKey(response.data.data);
        setState(response.data.data);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const getLocalArea = async (value) => {
    axios
      .get(apiService.API_URL() + `/api/v1/get-areas/${value}`, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        changeKey(response.data.areas);
        setArea(response.data.areas);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const countriesData = async () => {
    axios
      .get(apiService.API_URL() + "/api/v1/countries")
      .then(function (response) {
        changeKey(response.data.data);
        setCountries(response.data.data);
        getState(160);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const teamData = async () => {
    axios
      .get(apiService.API_URL() + `/api/v1/get-teams-by-captain/${userData.id}`, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        changeKey(response.data.teams);
        setTeams(response.data.teams);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const onChangeState = async (value) => {
    setSelectedState(value);
    setSelectedCity({});
    setStateHasError(false);
    setSelectedPostalCode(null);
    setValue("postal_code", null);

    axios
      .get(apiService.API_URL() + `/api/v1/cities/${value.value}`)
      .then(function (response) {
        setCity(response.data.data);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
    if (selectedCountry.label === "Nigeria") {
      axios
        .get(apiService.API_URL() + `/api/v1/postal-code/${value.value}`)
        .then(function (response) {
          changeKey(response.data.post_code);
          setPostalCode(response.data.post_code);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setNotLoading(true);
        });
    }
  };

  const onChangeCity = async (value) => {
    setSelectedCity(value.value);
    setCityHasError(false);
    setValue("postal_code", null);

    if (selectedCountry.label === "Nigeria") {
      axios
        .get(apiService.API_URL() + `/api/v1/postal-code/${value.value}`)
        .then(function (response) {
          setArea(response.data.post_code);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setNotLoading(true);
        });
    }
  };

  const onChangePostalCode = async (value) => {
    setSelectedPostalCode(value);
    setPostalCodeHasError(false);
    getLocalArea(value.value);
  };

  const onChangeArea = async (value) => {
    setSelectedArea(value);
    setValue("postal_code", value.post_code);
  };

  const onChangeTeam = (value) => {
    setTeamHasError(false);
    setSelectedTeam(value);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("cleanup_location", data.cleanUpLocation);
    formData.append("city", selectedCity);
    formData.append("description", data.description);
    formData.append("event_date_time", eventDatetime);
    formData.append("event_id", id);

    if (selectedTeam != 1) {
      formData.append("team_id", selectedTeam.value.toString());
    } else {
      return toast.warning("please select Team");
    }

    if (selectedCountry.value.toString() != null) {
      formData.append("country_id", selectedCountry.value.toString());
    } else {
      return toast.warning("please select country");
    }

    if (selectedState != null) {
      formData.append("state_id", selectedState.value.toString());
    } else {
      return toast.warning("please select State");
    }

    if (data.cleanUpLocation != null) {
      formData.append("cleanup_location", data.cleanUpLocation);
    } else {
      return toast.warning("please select Locality");
    }

    if (selectedArea != null && selectedPostalCode != null) {
      formData.append("local_government_area", selectedArea.value.toString());
      formData.append("postal_code", selectedPostalCode.value.toString());
    }

    for (let i = 0; i < data.images.length; i++) {
      formData.append("attachments[]", data.images.item(i));
    }

    setNotLoading(false);
    axios
      .post(apiService.API_URL() + "/api/v1/update-event", formData, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        toast.success(response.data.status);
        setNotLoading(true);
        navigate("/dashboard/event-list/" + id);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  return (
    <>
      <div className="d-flex flex-column bg-light">
        <section className="d-flex flex-column align-items-center justify-content-center my-4">
          <h2 className="fs-1 fw-light">Update Event</h2>
        </section>

        <section>
          {eventData.id && (
            <div className="container team-form">
              <p className="text-capitalize">
                <span className="text-danger">*</span> indicates required field
              </p>
              <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                  <label htmlFor="team" className="form-label">
                    Team
                  </label>
                  <Select
                    value={selectedTeam}
                    options={teams}
                    onChange={onChangeTeam}
                    className="react-select-container"
                    name="team"
                  />
                  {errors.team && (
                    <div className="text-danger my-2 fst-italic">
                      Please select a team.
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="cleanUpLocation" className="form-label">
                    Cleanup Location <span className="text-danger">*</span>
                  </label>

                  <input
                    type="test"
                    className="form-control"
                    placeholder="Cleanup location"
                    id="cleanUpLocation"
                    {...register("cleanUpLocation", {
                      required: true,
                      value: eventData.cleanup_location
                        ? eventData.cleanup_location
                        : "",
                    })}
                  />

                  {errors.cleanUpLocation && (
                    <div className="text-danger my-2 fst-italic">
                      Please enter the valid clean up location.
                    </div>
                  )}
                </div>
                {countries.length > 0 && (
                  <div>
                    <div className="col-12">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <Select
                        value={selectedCountry}
                        onChange={onChangeCountry}
                        options={countries}
                        className="react-select-container"
                        name="country"
                      />
                      {errors.country && (
                        <div className="text-danger my-2 fst-italic">
                          Please select your country.
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <label htmlFor="state" className="form-label">
                        State <span className="text-danger">*</span>
                      </label>
                      {selectedCountry?.label !== "Nigeria" && (
                        <>
                          <CreatableSelect
                            isClearable
                            options={state}
                            onChange={onChangeState}
                            defaultValue={selectedState}
                            className="react-select-container"
                            name="state"
                          />
                          <span className="help_text">
                            Select or Create State
                          </span>
                        </>
                      )}
                      {selectedCountry?.label === "Nigeria" && (
                        <Select
                          value={selectedState}
                          onChange={onChangeState}
                          options={state}
                          className="react-select-container"
                          name="state"
                        />
                      )}

                      {errors.state && (
                        <div className="text-danger my-2 fst-italic">
                          Please select your state.
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <label htmlFor="city" className="form-label">
                        City <span className="text-danger">*</span>
                      </label>

                      {selectedCountry?.label !== "Nigeria" && (
                        <>
                          <CreatableSelect
                            isClearable
                            options={city}
                            onChange={onChangeCity}
                            defaultValue={selectedCity}
                            className="react-select-container"
                          />
                          <span className="help_text">
                            Select or Create State
                          </span>
                        </>
                      )}
                      {selectedCountry?.label === "Nigeria" && (
                        <Select
                          value={selectedCity}
                          onChange={onChangeCity}
                          options={city}
                          className="react-select-container"
                        />
                      )}

                      {cityHasError && (
                        <div className="text-danger my-2 fst-italic">
                          Please select the city.
                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      {selectedCountry.label === "Nigeria" && (
                        <>
                          <div className="col-md-12">
                            <label
                              htmlFor="local_government_area"
                              className="form-label"
                            >
                              Locality <span className="text-danger">*</span>
                            </label>

                            <Select
                              value={selectedArea}
                              onChange={onChangeArea}
                              options={area}
                              className="react-select-container"
                            />
                            {cityHasError && (
                              <div className="text-danger my-2 fst-italic">
                                Please select the local government area.
                              </div>
                            )}
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="postal_code" className="form-label">
                              Postal Code <span className="text-danger">*</span>
                            </label>
                            <input
                              type="test"
                              className="form-control"
                              placeholder="Post code"
                              id="postCode"
                              {...register("postal_code")}
                              disabled
                            />
                          </div>
                        </>
                      )}
                      {selectedCountry.label !== "Nigeria" && (
                        <>
                          <div className="col-md-6">
                            <label htmlFor="postal_code" className="form-label">
                              Postal Code <span className="text-danger"></span>
                            </label>
                            <input
                              type="test"
                              className="form-control"
                              placeholder="Post code"
                              id="postCode"
                              {...register("postal_code")}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div></div>
                  </div>
                )}

                <div className="col-12">
                  <label htmlFor="event_date_time" className="form-label">
                    Event Date time
                  </label>

                  <DateTimePicker
                    wrapperClassName="datePicker"
                    name="event_date_time"
                    className="form-control"
                    onChange={onChange}
                    value={eventData.event_date_time}
                  />
                  {errors.event_date_time && (
                    <div className="text-danger my-2 fst-italic">
                      Please choose date and time
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>

                  <textarea
                    className="form-control"
                    id="description"
                    placeholder="Description"
                    {...register("description", {
                      required: true,
                      value: eventData.description ? eventData.description : "",
                    })}
                  ></textarea>

                  {errors.description && (
                    <div className="text-danger my-2 fst-italic">
                      Please add description.
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="images" className="form-label">
                    Images before event
                  </label>

                  <div
                    className="input-group file-input-group"
                    data-controller="file-input"
                  >
                    <input
                      type="file"
                      multiple
                      className="form-control"
                      onChange={onChangeFile}
                      accept="image/png, image/jpeg"
                      {...register("images", {
                        required: true,
                      })}
                    />
                  </div>
                  {errors.images && (
                    <div className="text-danger my-2 fst-italic">
                      Please upload event location images.
                    </div>
                  )}
                </div>

                <div className="my-4 pb-5">
                  <button
                    className="btn bg-pla-primary text-white px-5 rounded-pill py-2"
                    type="submit"
                  >
                    Update Event
                  </button>
                </div>
              </form>
            </div>
          )}
        </section>
      </div>
      <LoadingSpinner hidden={notLoading} />
    </>
  );
};

export default UpdateTeamEvent;
