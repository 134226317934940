import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as eventActions from '../../store/actions/events';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";

import apiService from "../../services/api.service";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";

const EditProfile = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [userData, setUserData] = useState(null);

  const challenge = localStorage.getItem("challenge");
  const challengedata = JSON.parse(challenge);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchUserData();
  }, [token, user?.id]); // Added null check for user.id

// console.log("check",user.id);

  const fetchUserData = () => {
    setLoading(true);
    if (token) {
      axios
        .get(apiService.API_URL() + `/api/v1/profile/edit/${user?.id}`, { // Added null check for user.id
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          setUserData(response.data);
          setLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setLoading(false);
        });
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("first_name", data.firstName);
    formData.append("last_name", data.lastName);
    formData.append("mission", data.missionStatement);
    formData.append("challenge_id", challengedata?.id); // Added null check for challengedata.id
    if (data.avatar[0]) {
      formData.append("avatar", data.avatar[0]);
    }
    if (data.bannerImage[0]) {
      formData.append("banner", data.bannerImage[0]);
    }

    axios
      .post(
        apiService.API_URL() + `/api/v1/profile/edit/${user?.id}`, // Added null check for user.id
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        toast.success(response.data.msg);
        getWeeklyPoints(user?.id); // Added null check for user.id
        navigate("/dashboard/home");
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      })
      .finally(function () {
        setLoading(false);
      });
  };

  const getWeeklyPoints = (userId) => {
    axios
      .get(apiService.API_URL() + `/api/v1/get-user-points/${userId}/${challengedata?.id}/week`, { // Added null check for challengedata.id
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        dispatch(eventActions.setWeeklyPoints(response.data));
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  return (
    <>
      <LoadingSpinner hidden={!loading} />
      {!loading && (
        <div className="d-flex flex-column bg-white">
          <section className="d-flex flex-column align-items-center justify-content-center my-4">
            <h2 className="fw-light fs-1 my-2 text-capitalize">
              Edit Profile: {userData.name} {userData.last_name}
            </h2>

            <Link
              className="text-primary cursor-pointer text-uppercase fw-bold my-2"
              to="/sign-up"
            >
              BACK TO DASHBOARD
            </Link>
          </section>

          <section>
            <div className="container team-form">
              <p className="text-capitalize">
                <span className="text-danger">*</span> Indicates required field
              </p>
              <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                  <label htmlFor="name" className="form-label">
                    Mission Statement <span className="text-danger">*</span>
                  </label>

                  <textarea
                    type="test"
                    className="form-control"
                    id="name"
                    placeholder="Mission Statement"
                    {...register("missionStatement", {
                      required: true,
                      value: userData.mission ? userData.mission : "",
                    })}
                  ></textarea>

                  <div>
                    <p className="my-2 fw-bold text-pla-gray">
                      TIP: MISSION STATEMENTS APPEAR AT THE TOP OF YOUR PROFILE
                      PAGE. KEEP IT BRIEF!
                    </p>
                  </div>

                  {errors.missionStatement && (
                    <div className="text-danger my-2 fst-italic">
                      This field is required!
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="name" className="form-label">
                    First Name <span className="text-danger">*</span>
                  </label>

                  <input
                    type="test"
                    className="form-control"
                    id="name"
                    placeholder="First Name"
                    {...register("firstName", {
                      required: true,
                      value: userData.name ? userData.name : "",
                    })}
                  />

                  {errors.firstName && (
                    <div className="text-danger my-2 fst-italic">
                      This field is required!
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="name" className="form-label">
                    Last Name <span className="text-danger">*</span>
                  </label>

                  <input
                    type="test"
                    className="form-control"
                    id="name"
                    placeholder="Last Name"
                    {...register("lastName", {
                      required: true,
                      value: userData.last_name ? userData.last_name : "",
                    })}
                  />

                  {errors.lastName && (
                    <div className="text-danger my-2 fst-italic">
                      This field is required!
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="password" className="form-label">
                    Avatar
                  </label>

                  <div
                    className="input-group file-input-group"
                    data-controller="file-input"
                  >
                    <input
                      type="file"
                      className="form-control"
                      {...register("avatar")}
                    />
                  </div>
                  <p className="my-2 fw-bold text-pla-gray">
                    SQUARE AVATARS WILL WORK BEST. THE IMAGE WILL BE CROPPED TO
                    220X220 PIXELS.
                  </p>

                  {/* <img
                    src={`data:image/png;base64,${userData.avatar}`}
                    style={{ height: "220px", width: "220px" }}
                  /> */}

                  {errors.avatar && (
                    <div className="text-danger my-2 fst-italic">
                      This field is required!
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="password" className="form-label">
                    Profile banner image
                  </label>

                  <div
                    className="input-group file-input-group"
                    data-controller="file-input"
                  >
                    <input
                      type="file"
                      className="form-control"
                      {...register("bannerImage")}
                    />
                  </div>
                  <p className="my-2 fw-bold text-pla-gray">
                    LANDSCAPE IMAGES WILL WORK BEST. BANNERS WILL BE RESIZED TO
                    APPROXIMATELY 900 PIXELS WIDE, AND SOME CROPPING MAY OCCUR.
                  </p>

                  {errors.bannerImage && (
                    <div className="text-danger my-2 fst-italic">
                      This field is required!
                    </div>
                  )}
                </div>

                {/* <div className="col-12">
                  <label htmlFor="password" className="form-label">
                    Notifications
                  </label>

                  <div className="my-4">
                    <input
                      className="form-check-input mx-2"
                      type="checkbox"
                      id="terms-and-conditions"
                      value=""
                      aria-label="Checkbox htmlFor following text input"
                    />

                    <label
                      htmlFor="terms-and-conditions"
                      className="form-label"
                    >
                      I would like to receive participant updates and
                      notifications via email including check-in reminders and
                      replies to my posts.
                    </label>
                  </div>
                  <div className="mt-4">
                    <input
                      className="form-check-input mx-2"
                      type="checkbox"
                      id="terms-and-conditions"
                      value=""
                      aria-label="Checkbox htmlFor following text input"
                    />

                    <label
                      htmlFor="terms-and-conditions"
                      className="form-label"
                    >
                      I would like to receive participant updates and
                      notifications via SMS including check-in reminders and
                      replies to my posts.
                      <p className="my-2 fw-bold text-pla-gray">
                        AVAILABLE ONLY IN THE UNITED STATES AND CANADA. MOBILE
                        MESSAGING RATES MAY APPLY.
                      </p>
                    </label>
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="password" className="form-label">
                    Email Subscription
                  </label>

                  <div className="my-4">
                    <input
                      className="form-check-input mx-2"
                      type="checkbox"
                      id="terms-and-conditions"
                      value=""
                      aria-label="Checkbox htmlFor following text input"
                    />

                    <label
                      htmlFor="terms-and-conditions"
                      className="form-label"
                    >
                      I would like to stay connected with Ecochallenge.org via
                      email and receive share-worthy announcements and resources
                      every month or so.
                    </label>
                  </div>
                </div> */}

                <div className="d-flex flex-column flex-md-row my-3  gap-4 pb-4">
                  <button
                    className="btn bg-pla-primary text-white px-5 rounded-pill py-2 "
                    type="submit"
                  >
                    Update Profile
                  </button>
                  <button
                    className="btn bg-pla-primary text-white px-5 rounded-pill py-2"
                    type="button"
                    onClick={() => navigate("/dashboard/home")}
                  >
                    Cancel
                  </button>
                </div>
                <Link
                  className="text-pla-primary cursor-pointer text-uppercase fw-bold my-2"
                  to="/dashboard/home"
                >
                  BACK TO DASHBOARD
                </Link>

                {/* <Link
                  className="cursor-pointer text-uppercase fw-bold my-2 pb-5 text-danger"
                  to="/sign-up"
                >
                  PLEASE REMOVE ME FROM THIS EVENT
                </Link> */}
              </form>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default EditProfile;
