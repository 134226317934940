import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

import * as teamsAction from '../../store/actions/teams';
import * as authActions from '../../store/actions/auth';
import Footer from '../../components/footer-layout/footer';
import HeaderLayout from '../../components/header-layout/header-layout';
import apiService from '../../services/api.service';
import LoadingSpinner from '../../components/LoadingSpinner';

const JoinTeam = ({ logout }) => {
  let { challengeID } = useParams();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const challenge = useSelector((state) => state.events.challenge);
  const [showTeams, setShowTeams] = useState(false);
  const [notLoading, setNotLoading] = useState(true);

  const createTeamNavigate = (value) => {
    navigate('/create-team/' + value);
  };

  const ListTeam = (props) => {
    const [searchText, setSearchText] = useState('');

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.auth.user);
    const teams = useSelector((state) => state.teams.teams);

    useEffect(() => {
      fetchTeams();
    }, [token]);

    const filterData = (event) => {
      setSearchText(event.target.value);
    };

    const fetchTeams = async () => {
      if (token) {
        axios
          .get(apiService.API_URL() + `/api/v1/teams`, {headers: { Authorization: `Bearer ${token}` },})
          .then(function (response) {
            dispatch(teamsAction.setTeams(response.data));
            setLoading(false);
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                toast.error(error.response.data.errors[i]);
              }
            }
            setLoading(false);
          });
      } else {
        axios
          .get(apiService.API_URL() + `/api/v1/teams`)
          .then(function (response) {
            dispatch(teamsAction.setTeams(response.data));
            setLoading(false);
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                toast.error(error.response.data.errors[i]);
              }
            }
            setLoading(false);
          });
      }
    };

    const onJoinTeam = async (id) => {
      setLoading(true);
      let body = {
        user_id: user.id,
        team_id: id,
        challenge_id: challengeID
      };

      if (token) {
        axios
          .post(apiService.API_URL() + `/api/v1/joinTeam`, body,  {headers: { Authorization: `Bearer ${token}` },})
          .then(function (response) {
            setLoading(false);
            localStorage.setItem('user',JSON.stringify(response.data.userDetail));
            localStorage.setItem('challenge_list',JSON.stringify(response.data.user_challenges));
            dispatch(authActions.setUser(response.data.userDetail));
            navigate('/dashboard/home');
          })
          .catch(function (error) {
            if (error.response.data.errors) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                toast.error(error.response.data.errors[i]);
              }
            }
            setLoading(false);
          });
      }
    };

    return (
      <>
        <LoadingSpinner hidden={!loading} />
        <div className={`${props.show ? 'd-flex flex-column' : 'd-none'}`}>
          <div className="input-group mb-3 w-100">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              onChange={filterData}
            />
            <span
              className="input-group-text bg-white border-pla-primary"
              id="basic-addon2"
            >
              <FaSearch />
            </span>
          </div>
          <p className="fw-bold text-uppercase text-center">
            Type team name to search
          </p>
          {teams && (
            <>
              <p className="fs-6 fw-bold border-bottom pb-2">Teams</p>
              {teams
                .filter((val) => {
                  if (searchText === '') {
                    return val;
                  } else if (
                    val.team.toLowerCase().includes(searchText.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((team, index) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center border-bottom p-2"
                      key={index}
                    >
                      <div>
                        <p>{team.team}</p>
                        <p>{team.country}</p>
                      </div>

                      <button
                        className="btn btn-secondary px-4"
                        onClick={() => onJoinTeam(team.id)}
                      >
                        Join
                      </button>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </>
    );
  };

  const goToStaticPage = () => {
    navigate('/');
  };
  return (
    <div>
      <LoadingSpinner hidden={notLoading} />
      <HeaderLayout logout={logout} />
      <section className=" bg-white py-4">
        <div className="container d-flex flex-column align-items-center justify-content-center">
          <p
            className="fs-5 text-primary cursor-pointer my-4 hover-underline"
            onClick={goToStaticPage}
          >
            plafera.org
          </p>
          {/* <h1 className="fs-1 fw-light text-lowercase pb-4">Event title</h1> */}
        </div>
      </section>
      <section className="bg-light">
        <div className="container d-flex flex-column align-items-center justify-content-center">
          <div className="w-md-50 d-flex flex-column align-items-center justify-content-center">
            <h2 className="fs-2 fw-light py-4 text-capitalize">
              create or join a team
            </h2>
            <p className="my-2 text-center fs-6">
              We make sure every participant has a home team.
            </p>
            <p className="my-2 text-center fs-6">
              Below you can join an existing team, create a new one, or click
              “Can’t find a team” to join the Community team.
            </p>
            <p className="my-2 text-center fs-6">
              Don’t worry — you can switch teams after registration.
            </p>

            <div className="d-flex flex-column pt-4">
              <button
                className="btn bg-pla-primary text-white px-5 my-2 text-uppercase fw-bold"
                onClick={() => createTeamNavigate(challengeID)}
              >
                Create Your Own team
              </button>
            </div>
          </div>
          {!showTeams ? (
            <div className="pb-4">
              <button
                className="btn bg-pla-primary text-white px-5 my-2 text-uppercase fw-bold"
                onClick={() => setShowTeams(true)}
              >
                Join an existing Team
              </button>
            </div>
          ) : (
            <div className="w-md-50 d-flex flex-column justify-content-center">
              <ListTeam show={showTeams} />
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default JoinTeam;
