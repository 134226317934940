const config = {
 api: "https://plafera-admin-dev.cgstagingsite.com",
  // api: "http://127.0.0.1:8000",
 // api: "https://admin.plafera.org",
  options: {
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
  },
};

const API_URL = () => {
  
  return "https://plafera-admin-dev.cgstagingsite.com";
 //return "http://127.0.0.1:8000";
  
  // Live URL
  //return "https://admin.plafera.org";
};
const onmiCalc_Iframe_URL = () => {
  return "https://plafera-pwa-dev.cgstagingsite.com/plastic-footprint-calculator/";
  //return "https://plafera.org/plastic-footprint-calculator/";
};

const paymentPublickKey = () => {
  return "FLWPUBK-90a0370a8bdd854c9019b32a7b958230-X";
};

const handleResponse = (response) => {
  // You can handle 400 errors as well.
  if (response.status === 200) {
    return response.json();
  } else {    
    response.json().then((body) => console.log(body));
    return response.json();
    //throw Error(response.json() | "error");
  }
};

export default { onmiCalc_Iframe_URL, API_URL, paymentPublickKey };
