export const SET_TEAMS = "SET_TEAMS";
export const SET_ALL_TEAMS = "SET_ALL_TEAMS";
export const SET_TEAM = "SET_TEAM";
export const SET_TEAM_POINTS = "SET_TEAM_POINTS";
export const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS";
export const SET_TEAM_DATA = "SET_TEAM_DATA";
export const SET_TEAM_DATA_TO_INVITE = "SET_TEAM_DATA_TO_INVITE";

export const setTeams = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_TEAMS, data: data });
  };
};

export const setAllTeams = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_ALL_TEAMS, data: data });
  };
};

export const setTeam = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_TEAM, data: data });
  };
};

export const setTeamPoints = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_TEAM_POINTS, data: data });
  };
};

export const setTeamMembers = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_TEAM_MEMBERS, data: data });
  };
};

export const setTeamData = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_TEAM_DATA, data: data });
  };
};

export const setTeamDataInvite = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_TEAM_DATA_TO_INVITE, data: data });
  };
};
