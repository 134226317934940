import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';

import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";

const CreateTeam = () => {
  let navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [state, setState] = useState([]);
  const [countriesHasError, setCountriesHasError] = useState(null);
  const [stateHasError, setStateHasError] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [notLoading, setNotLoading] = useState(true);

  const changeKey = (arrayObj) => {
    for (var i = 0; i < arrayObj.length; i++) {
      arrayObj[i].value = arrayObj[i]["id"];
      arrayObj[i].label = arrayObj[i]["name"];
      delete arrayObj[i].id;
      delete arrayObj[i].name;
    }
  };

  const onChangeCountry = async (value) => {
    setSelectedCountry(value);
    setSelectedState(null);
    setCountriesHasError(false);

    axios.get(apiService.API_URL() + `/api/v1/states/${value.value}`)
      .then(function (response) {
        changeKey(response.data.data);
        setState(response.data.data);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const onChangeState = async (value) => {
    setSelectedState(value);
    setStateHasError(false);
  };

  useEffect(() => {
    const countriesData = async () => {
      axios.get(apiService.API_URL() + '/api/v1/countries')
        .then(function (response) {
          changeKey(response.data.data);
          setCountries(response.data.data);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setNotLoading(true);
        });
    };
    countriesData();
  }, []);

  const {register,handleSubmit,formState: { errors }} = useForm();

  const onSubmit = (data) => console.log(data);
  
  return (
    <div className="d-flex flex-column bg-light">
      <section className="d-flex flex-column align-items-center justify-content-center my-4">
        <h2 className="text-lowercase fs-2 fw-light">
          Create a new team to manage
        </h2>
        <p className="text-uppercase fw-bold mt-2 team-form mx-2 mx-md-auto text-center">
          creating a new team to manage will not remove you from your current
          team, and you will not recieve any additional points. You will have
          all other team captain privileges such as inviting new teammates /
          Team captains, messaging teammates and viewing team activity.
        </p>
      </section>

      <section>
        <div className="container team-form">
          <p className="text-capitalize">* indicates required field</p>
          <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-12">
              <label htmlFor="name" className="form-label">
                Name
              </label>

              <input
                type="test"
                className="form-control"
                placeholder="Team Name"
                {...register("name", { required: true })}
              />

              {errors.name && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the valid team name.
                </div>
              )}
            </div>

            <div className="col-12">
              <label htmlFor="country" className="form-label">
                Country
              </label>
              <Select
                defaultValue={selectedCountry}
                onChange={onChangeCountry}
                options={countries}
                className="react-select-container"
                name="county_id"
              />
              {errors.country_id && (
                <div className="text-danger my-2 fst-italic">
                  Please select your country.
                </div>
              )}
            </div>
            <div className="col-12">
              <label htmlFor="state" className="form-label">
                State/LGA
              </label>
              <Select
                defaultValue={selectedState}
                onChange={onChangeState}
                options={state}
                className="react-select-container"
              />
              {errors.state_id && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the State/LGA.
                </div>
              )}
            </div>

            <div className="col-12">
              <label htmlFor="name" className="form-label">
              Organization or School
              </label>

              <input
                type="test"
                className="form-control"
                placeholder="Oraganization or Company"
                {...register("organization_or_school", { required: true })}
              />
              {errors.organization_or_school && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the organization or school.
                </div>
              )}
            </div>

            <div className="col-12">
              <label htmlFor="name" className="form-label">
                Which of the following best describes your team?
              </label>

              <input
                type="test"
                className="form-control"
                {...register("team_mate_type", { required: true })}
              />
              {errors.team_mate_type && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the team_mate_type.
                </div>
              )}
            </div>

            <div className="col-12">
              <label htmlFor="name" className="form-label">
                Website
              </label>

              <input
                type="test"
                className="form-control"
                placeholder="URL"
                {...register("website", { required: true })}
              />
              {errors.website && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the valid website.
                </div>
              )}
            </div>

            <div className="col-12">
              <label htmlFor="password" className="form-label">
                Password (optional)
              </label>

              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Team Password"
                {...register("password")}
              />
            </div>

            <div className="col-12">
              <label htmlFor="password" className="form-label">
                Team logo or Avatar
              </label>

              <div
                className="input-group file-input-group"
                data-controller="file-input"
              >
                <input
                  type="file"
                  className="form-control"
                  {...register("logo")}
                />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="password" className="form-label">
                Team banner image
              </label>

              <div
                className="input-group file-input-group"
                data-controller="file-input"
              >
                <input
                  type="file"
                  className="form-control"
                  {...register("banner")}
                />
              </div>
            </div>

            <div className="my-4 pb-5">
              <button
                className="btn bg-pla-primary text-white px-5 rounded-pill py-2"
                type="submit"
              >
                
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default CreateTeam;
