import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

import apiService from '../../services/api.service';
import LoadingSpinner from '../../components/LoadingSpinner';

const EventSuccess = () => {
    let { id } = useParams();
    const token = useSelector((state) => state.auth.token);
    const [notLoading, setNotLoading] = useState(false);
    // const [loading, setLoading] = useState(true);
    const [eventSuccessVideo, seteventSuccessVideo] = useState(null);
    const [eventSuccessImage, seteventSuccessImage] = useState(null);

    const onChangeEventSuccessVideo = (event) => {
        var bytes = (event.target.files[0].size / 1048576) + ' MB';
        if (bytes >= '2') {
            return toast.error("Please select below 2 MB files");
        }
        else if (event.target.files[0].type == "video/mp4" || event.target.files[0].type == "video/x-m4v") {
            seteventSuccessVideo(event.target.files[0]);
        }
    };

    const onChangeEventSuccessImage = (event) => {
        var bytes = (event.target.files[0].size / 1048576) + ' MB';
        if (bytes >= '2') {
            return toast.error("Please select below 2 MB files");
        }
        else {
            seteventSuccessImage(event.target.files[0]);
        }
    };
    const submitEvent = () => {
        setNotLoading(false);
        if (eventSuccessVideo == null) {
            return toast.error("Please select your video");
        }
        if (eventSuccessImage == null) {
            return toast.error("Please select your image");
        }

        const formData = new FormData();
        formData.append('image', eventSuccessImage);
        formData.append('video', eventSuccessVideo);
        formData.append('event_id', id);

        axios.post(apiService.API_URL() + `/api/v1/completed-event`, formData, { headers: { Authorization: `Bearer ${token}` }, })
            .then(function (response) {
                toast.success(response.data.msg);
                setNotLoading(false);
            })
            .catch(function (error) {
                if (error.response.data.errors) {
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                        toast.error(error.response.data.errors[i]);
                    }
                }
                setNotLoading(true);
            });
    }

    return (
        <>
            <div className="container team-form  mt-5">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <h2 className="text-center mt-4 mb-5">Event Success</h2>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <label htmlFor="images" className="form-label">
                            Upload Your Video
                        </label>

                        <div className="input-group file-input-group" data-controller="file-input">
                            <input type="file" multiple className="form-control" accept="video/mp4,video/x-m4v,video/*" onChange={onChangeEventSuccessVideo} />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <label htmlFor="images" className="form-label">
                            Upload Your Image
                        </label>

                        <div className="input-group file-input-group" data-controller="file-input">
                            <input type="file" multiple className="form-control" accept="image/png, image/jpeg" onChange={onChangeEventSuccessImage} />
                        </div>
                    </div>

                    <div className="col-md-12 col-sm-12 col-xs-12 my-4 pb-5">
                        <button className="btn bg-pla-primary text-white px-5 rounded-pill py-2" type="submit" onClick={submitEvent}>Submit</button>
                    </div>
                </div>
            </div>
            {/* <LoadingSpinner hidden={notLoading} /> */}
        </>
    )
}

export default EventSuccess;