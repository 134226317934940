import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const TermsAndConditions = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      scrollable={true}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5 className="my-3 fw-bold">
            1. About Plafera Progress Web App (Plafera App)
          </h5>

          <ol type="a" className="mb-3">
            <li>
              The Plafera App is owned and operated by the Plastic Free Rainy
              Season Sensitization Initiative (IT - 170255) (Plafera) under the
              domain name plafera.org and is referred to as the Plafera App
              throughout this document.
            </li>
            <li>
              Through the Plafera App, you may view information and resources
              about Plafera Challenge (Plastic Free Rainy Season Challenge) and
              its operations, donate to Plafera, and access third-party
              websites.
            </li>
            <li>
              These Terms of Use set out the terms and conditions which govern
              your use of the Plafera App and its Content. By accessing or using
              the Plafera App, you agree to be bound by these Terms of Use and
              our Privacy Policy.
            </li>
          </ol>

          <br />
          <h5 className="my-3 fw-bold">
            2. Information available on the Plafera App{' '}
          </h5>

          <h6 className="my-3 fw-bold">2.1 Content</h6>

          <ol type="a">
            <li>
              The Plafera App provides general information only. Some parts of
              the Plafera App’s Content may be provided by third parties or
              other users of the Plafera App.
            </li>
            <li>
              We do not warrant the reliability, accuracy, correctness, or
              completeness of any of the Content. Users must rely solely on
              their assessments and verification of the Content.
            </li>
            <li>
              All Content is provided without guarantees or warranties of any
              kind, either express or implied. All terms implied by law, except
              those that cannot be lawfully excluded, are excluded. All Content
              is subject to change without notice.
            </li>
          </ol>

          <h6 className="my-3 fw-bold">2.2 Rights to use Content</h6>

          <ol type="a">
            <li>
              All Content is protected by copyright and owned by us or a third
              party. The compilation of all Content as presented on the Plafera
              App is owned by us.
            </li>
            <li>
              Your use of the Plafera App does not alter the ownership of any
              intellectual property in the Content.
            </li>
            <li>
              You can view the Content solely to use the Plafera App per these
              Terms of Use.
            </li>
            <li>
              Except where necessary for viewing the Content through the Plafera
              App, using the Services or as permitted under the Copyright Act
              LFN 2004, other applicable laws, or these Terms of Use, you must
              not reproduce, modify, translate, publish, upload to a third
              party, link to, frame, perform in public, distribute or transmit
              any of the Content in any form by any process without our specific
              prior written consent.
            </li>
          </ol>

          <h6 className="my-3 fw-bold">2.3 Use of branded materials</h6>

          <p className="fs-6">
            Plafera provides resources on the Plafera App to share the Plafera
            challenge including posters, badges, social media assets, and other
            branding materials. These brand materials can be used only to share
            the Plastic challenge and cannot be used to promote the sale or use
            of products or services.
          </p>
          <h6 className="my-3 fw-bold">
            2.4 Links to other websites (including for donations)
          </h6>

          <ol type="a">
            <li>
              The Plafera App may contain links or may otherwise direct or
              connect you to third-party websites. For example, the Plafera App
              may link to third-party websites that provide an online payment
              gateway to allow you to make donations to Plafera – any donations
              that you make through those websites are governed by the terms and
              conditions of those websites.
            </li>
            <li>
              We are not responsible for and do not sponsor, endorse, recommend,
              or approve any information, products, or services referred to on
              such linked websites. When using these other websites, you will be
              bound by the terms and conditions of those websites.
            </li>
            <li>
              This Plafera App on occasion may accept paid sponsorship or
              collaborations, or other forms of compensation. Where this occurs,
              the Content will be identified as paid, sponsored, or
              collaborative Content as appropriate. Any paid collaboration will
              only be entered into with organizations aligned with our values
              and ethical policy, whose products and services are considered to
              align with our vision of a world without plastic waste.
            </li>
            <li>
              This Plafera App uses affiliate links which means if you click a
              unique link and choose to make a purchase, we may be compensated a
              small amount at no extra cost to you. All web pages containing
              affiliate links will be acknowledged as such on the page.
            </li>
          </ol>

          <h6 className="my-3 fw-bold">2.5 Cookies</h6>

          <p className="fs-6">
            The Plafera App uses cookies and may access your local storage
            through HTML Web Storage or other browser-based software. By using
            the Plafera App, you consent to our use of cookies and other local
            storage methods by these Terms of Use and the Privacy Policy.
          </p>
          <br />
          <h5 className="my-3 fw-bold">3. Availability of the Plafera App </h5>

          <ol type="a">
            <li>
              As electronic services are subject to interruption or breakdown,
              access to the Plafera App is offered on an “as is” and “as
              available” basis.
            </li>
            <li>
              We do not guarantee that access to the Plafera App will function
              as intended or be uninterrupted.
            </li>
            <li>
              We do not represent or warrant that the Content on the Plafera
              App, or the facilities that make it available, will not cause
              damage or are free from any computer virus or any other defects or
              errors.
            </li>
            <li>
              We may withdraw the Plafera App at any time and without notice to
              you. We may limit or restrict the use you make of the Plafera App.
            </li>
            <li>
              We may make changes to the Plafera App and any Content at any
              time, without notice.
            </li>
          </ol>
          <br />
          <h5 className="my-3 fw-bold">4. Your use of the Plafera App </h5>
          <h6 className="my-3 fw-bold">4.1 Carrier charges</h6>
          <p className="fs-6">
            You are responsible for all carrier, text/SMS, data, or other
            related fees or charges you incur from your carrier or other service
            providers in connection with or related to your use of the Plafera
            App.
          </p>
          <h6 className="my-3 fw-bold">4.2 System Security</h6>
          <p className="fs-6">
            We recommend that you obtain and maintain up-to-date virus,
            security, intrusion prevention, and scanning software that is
            specifically designed for any device you use to access the Plafera
            App. We are not liable to you for any interference with or damage to
            your computer system, for any data loss, or for any equipment or
            software malfunction that occurs in connection with your use of the
            Plafera App or a linked website.
          </p>
          <h6 className="my-3 fw-bold">4.3 No lifting of Content</h6>

          <p className="fs-6">
            You must not use any robot, spider, scraper, data aggregation tool,
            deep-link, or other automatic or manual process to:
          </p>
          <ol type="a">
            <li>
              access, monitor, copy or extract any part of the Plafera App or
              any Content;
            </li>
            <li>
              in any way reproduce or circumvent the navigational structure or
              presentation of the Plafera App or any Content; or
            </li>
            <li>
              obtain or attempt to obtain any materials, documents, information,
              or Content through any means other than the methods clearly and
              intentionally provided for your use on the Plafera App.
            </li>
          </ol>

          <h6 className="my-3 fw-bold">4.4 No hacking</h6>

          <ol type="a">
            <li>
              You must not attempt to gain unauthorized access to any part of
              the Plafera App, any other systems or networks connected to the
              Plafera App, or any of our servers, by hacking, password “mining”
              or any other illegitimate means.
            </li>
            <li>
              You must not probe, scan, or test the vulnerability of the Plafera
              App or any network connected to the Plafera App. You must not
              breach the security or authentication measures on the Plafera App,
              or any network connected to the Plafera App.
            </li>
          </ol>

          <h6 className="my-3 fw-bold">4.5 No interference or overloading</h6>

          <ol type="a">
            <li>
              You must not interfere or attempt to interfere with the proper
              working of the Plafera App, any transaction taking place on the
              Plafera App, or with any other person’s use of the Plafera App.
            </li>
            <li>
              You must not take any action that imposes an unreasonable or
              disproportionately large load on the infrastructure of the Plafera
              App or any of our systems or networks, or any systems or networks
              connected to the Plafera App.
            </li>
          </ol>

          <h6 className="my-3 fw-bold">4.6 Your Content</h6>

          <ol type="a">
            <li>
              You are responsible for Your Content. You represent and warrant
              that Your Content is accurate and does not otherwise violate any
              part of these Terms of Use and that use of Your Content by us will
              not violate or infringe any rights of yours or any third party.
            </li>
            <li>
              Your Content is non-confidential and non-proprietary. You retain
              all ownership rights in Your Content but agree to grant Plafera a
              perpetual, royalty-free, non-exclusive, and irrevocable license to
              use, reproduce, edit, and communicate to the public Your Content.
              This includes storing, copying, and making Your Content available
              to third parties.
            </li>
          </ol>

          <h6 className="my-3 fw-bold">4.7 No unlawful use</h6>
          <p className="fs-6">
            When you use the Plafera App (including uploading Your Content or
            making contact with other users of the Plafera App), you must not:
          </p>

          <ul>
            <li>In any way that breaches any applicable law or regulation.</li>
            <li>
              Infringe the copyright, trademarks, or other intellectual property
              rights of third parties.
            </li>
            <li>
              In any way be abusive, vulgar, obscene, unlawful, fraudulent,
              threatening, defamatory, and/or hateful to any person.
            </li>
            <li>Violate the privacy or other personal rights of others.</li>
            <li>
              Have the intention of harming or attempting to harm minors in any
              way; or
            </li>
            <li>
              Knowingly transmit any data, send or upload any material that
              contains viruses, or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware.
            </li>
          </ul>
          <p className="fs-6">
            Plafera has the right to remove any of Your Content that, in our
            opinion, does not comply with these Terms of Use.
          </p>

          <h6 className="my-3 fw-bold">4.8 Termination and suspension</h6>
          <p className="fs-6">
            We reserve the right to terminate or suspend your access to the
            Plafera App and block your IP address at any time.
          </p>

          <br />
          <h5 className="my-3 fw-bold">5. Liability and indemnity </h5>
          <h6 className="my-3 fw-bold">5.1 Your rights</h6>
          <p className="fs-6">
            We acknowledge that, in some circumstances, you may have rights
            under the Federal Competition and Consumer Protection Act, 2018
            ("FCCPA") or other laws which cannot be excluded, modified, or
            restricted. Nothing in these Terms of Use (including this clause 5)
            excludes, modifies, or restricts these rights.
          </p>
          <h6 className="my-3 fw-bold">5.2 Disclaimer</h6>
          <p className="fs-6">
            Subject to clause 5.1, except as expressly set out in these Terms of
            Use, and to the fullest extent permitted by law:
          </p>
          <ul>
            <li>
              We disclaim all express, implied, and statutory warranties about
              the Plafera App including but not limited to, the implied
              warranties of quality, merchantability, and fitness for a
              particular purpose; and
            </li>
            <li>
              We and our directors, employees, agents, and contractors are not
              liable to you for any losses, damages, liabilities, claims, or
              expenses (including but not limited to legal costs and defense or
              settlement costs) whatsoever, whether direct, indirect or
              consequential, arising out of or referable to the Plafera App and
              any Content, to any material on linked websites, or your access or
              use of the Plafera App, however, caused, whether under the
              statute, in contract or tort, including for negligence, or
              otherwise.
            </li>
          </ul>
          <h6 className="my-3 fw-bold">5.3 Our liability</h6>
          <p className="fs-6">
            Subject to clauses 5.1 and 5.2, our aggregate liability for loss or
            damage sustained by you in connection with the Plafera App (whether
            under the statute, in contract, or tort, including for negligence,
            or otherwise) is limited to resupplying the services to you (by
            allowing you to access the Plafera App in the manner described in
            these Terms of Use).
          </p>

          <h6 className="my-3 fw-bold">5.4 Indemnity</h6>
          <p className="fs-6">
            You indemnify us in respect of any liability, loss, damage, cost or
            expense suffered or incurred arising from or in connection with your
            breach of these Terms of Use or your use of the Plafera App.
          </p>

          <br />
          <h5 className="my-3 fw-bold">6. Other terms that apply to you </h5>
          <p className="fs-6">
            Our Privacy Policy sets out the terms on which we process any
            personal information we collect from you, or that you provide to us.
          </p>

          <br />
          <h5 className="my-3 fw-bold">7. Viruses </h5>
          <p className="fs-6">
            Although Plafera makes all reasonable attempts to exclude viruses
            from the Plafera App, we cannot ensure such exclusion, and no
            liability is accepted for damage caused by the downloading of
            viruses. Therefore, please take all appropriate safeguards before
            downloading information from the Plafera App.
          </p>

          <br />
          <h5 className="my-3 fw-bold">8. General </h5>
          <h6 className="my-3 fw-bold">8.1 Changes to these Terms of Use</h6>
          <ol type="a">
            <li>
              These are the current Terms of Use. They replace any other terms
              of use previously published on the Plafera App.
            </li>
            <li>
              We may at any time vary the Terms of Use by publishing the varied
              Terms of Use on the Plafera App. The new Terms of Use will apply
              to your use of the Plafera App after the date that we post the
              change on the Plafera App.
            </li>
            <li>
              If you do not agree with the change, then you must alter or cease
              your use of the Plafera App accordingly.
            </li>
          </ol>

          <h6 className="my-3 fw-bold">8.2 Jurisdiction</h6>
          <p className="fs-6">
            The law applicable to the Plafera App and disputes arising from or
            in connection with the Plafera App is the law of Nigeria and you
            irrevocably submit to the exclusive jurisdiction of the courts of
            Nigeria in respect of any such dispute.
          </p>

          <h6 className="my-3 fw-bold">8.3 Enforceability</h6>
          <p>
            Where a clause in this Terms of Use is void, illegal, or
            unenforceable, it may be severed without affecting the
            enforceability of the other provisions in these Terms of Use.
          </p>

          <h6 className="my-3 fw-bold">8.4 Definitions</h6>
          <p className="fs-6">
            The meanings of the terms used in these Terms of Use are set out
            below:
          </p>
          <ol type="a">
            <li>
              The Federal Competition and Consumer Protection Act, 2018
              ("FCCPA") .
            </li>
            <li>
              Content means all information and data (including data files,
              written text, computer software, the Plafera Challenge name and
              logos, location data, audio files or other sounds, photographs,
              videos, or other images) to which you may have access to as part
              of, or through your use of the Plafera App.
            </li>
            <li>
              Person means any natural person, company, partnership, joint
              venture, association, corporation, or other body corporate.
            </li>
            <li>
              Privacy Policy means our privacy policy available at [insert link
              to Privacy Policy].
            </li>
            <li>
              Terms of Use means this document, is updated from time to time.
            </li>
            <li>
              User means a person who accesses or uses the Plafera App and
              includes other persons jointly participating in using the Plafera
              App.
            </li>
            <li>
              Plafera App means the App under the domain name plafera.org.
            </li>
            <li>
              You or your means the person who accepts these Terms of Use,
              either personally or on behalf of an association or other legal
              entity, and includes if acceptance is on behalf of an association
              or other legal entity, the association or other legal entities’
              officers, employees, members, and agents.
            </li>
            <li>
              Your Content means content that you upload, share, post, or
              transmit to, through, or in connection with the Plafera App (for
              example information, comments, or pictures).
            </li>
          </ol>
          <p className="fs-6">Last updated: 19th of September 2022.</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={props.handleClose}>
          I understand
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsAndConditions;
