export const SET_HOMEDATA = "SET_HOMEDATA";
export const SET_STORE_DATA = "SET_STORE_DATA";
export const SET_IMPACTS_DATA = "SET_IMPACTS_DATA";

export const setHomeData = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_HOMEDATA, data: data });
  };
};

export const setStoreData = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_STORE_DATA, data: data });
  };
};

export const setImpactsData = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_IMPACTS_DATA, data: data });
  };
};
