import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as authActions from "../../store/actions/auth";
import CreatableSelect from "react-select/creatable";
import axios from "axios";

import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";
import { useForm } from "react-hook-form";
import Select from "react-select";
import HeaderLayout from "../../components/header-layout/header-layout";
import Footer from "../../components/footer-layout/footer";

const EditTeam = () => {
  const { state } = useLocation();
  const { teamId } = state;
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [stateVal, setState] = useState([]);
  const [countriesHasError, setCountriesHasError] = useState(null);
  const [stateHasError, setStateHasError] = useState(null);
  const [teamHasError, setTeamHasError] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [team, setTeam] = useState({});

  const [changeTeamLogoAvatar, setChangeTeamLogoAvatar] = useState(null);
  const [changeTeamBannerImage, setChangeTeamBannerImage] = useState(null);

  const [teamType, setTeamType] = useState([]);
  const [selectedteamType, setSelectedteamType] = useState(null);

  const user = useSelector((state) => state.auth.user);
  let { challengeID } = useParams();
  const [notLoading, setNotLoading] = useState(false);

  const changeKey = (arrayObj) => {
    for (var i = 0; i < arrayObj.length; i++) {
      arrayObj[i].value = arrayObj[i]["id"];
      arrayObj[i].label = arrayObj[i]["name"];
      delete arrayObj[i].id;
      delete arrayObj[i].name;
    }
  };

  const onChangeCountry = async (value) => {
    setSelectedCountry(value);
    setSelectedState(null);
    setCountriesHasError(false);

    axios
      .get(apiService.API_URL() + `/api/v1/states/${value.value}`)
      .then(function (response) {
        changeKey(response.data.data);
        setState(response.data.data);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const onChangeState = async (value) => {
    setSelectedState(value);
    setStateHasError(false);
  };

  const onChangeTeammateType = (value) => {
    setTeamHasError(false);
    setSelectedteamType(value);
  };

  const onChangeTeamLogoAvatar = (event) => {

    // setChangeTeamLogoAvatar(event.target.files[0]);
  };

  const onChangeTeamBannerImage = (event) => {
    setChangeTeamBannerImage(event.target.files[0]);
  };

  useEffect(() => {
    countriesData();
    getTeamMateTypes();
  }, []);

  useEffect(() => {
    fetchTeamData();
  }, [teamId]);

  useEffect(() => {
    if (countries?.length > 0 && Object.keys(team).length > 0) {
      let selectedContry = countries.find(
        (country) => country.value === parseInt(team.country_id)
      );
      onChangeCountry(selectedContry);
    }
  }, [countries, team]);

  useEffect(() => {
    if (stateVal?.length > 0 && Object.keys(team).length > 0) {
      let selectedState = stateVal.find(
        (state) => state.value === parseInt(team.state_id)
      );
      onChangeState(selectedState);
    }
  }, [stateVal, team]);

  useEffect(() => {
    if (teamType?.length > 0 && Object.keys(team).length > 0) {
      let selectedTeamType = teamType.find(
        (teamType) => teamType.value == team.team_mate_type
      );
      setSelectedteamType(selectedTeamType);
    }
  }, [teamType, team]);

  const fetchTeamData = () => {
    setNotLoading(false);
    if (token) {
      axios
        .get(apiService.API_URL() + `/api/v1/team/${teamId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          setNotLoading(true);
          setTeam(response.data);
          //   fetchTeamPoints();
        })
        .catch(function (error) {
          setNotLoading(true);
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
        });
    }
  };

  const countriesData = async () => {
    axios
      .get(apiService.API_URL() + "/api/v1/countries")
      .then(function (response) {
        changeKey(response.data.data);
        setCountries(response.data.data);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const getTeamMateTypes = async () => {
    axios
      .get(apiService.API_URL() + "/api/v1/getTeamMateTypes", {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        changeKey(response.data.team_types);
        setTeamType(response.data.team_types);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("organization_or_school", data.organization_or_school);
    formData.append("user_id", user.id);
    formData.append("challenge_id", Number(challengeID));
    if (selectedCountry === null || !selectedCountry) {
      setCountriesHasError(true);
      return;
    } else {
      setCountriesHasError(false);
      formData.append("country_id", selectedCountry.value.toString());
    }
    if (selectedState === null || !selectedState) {
      setStateHasError(true);
      return;
    } else {
      setStateHasError(false);
      formData.append("state_id", selectedState.value.toString());
    }
    if (selectedteamType === null || !selectedteamType) {
      setTeamHasError(true);
      return;
    } else {
      setTeamHasError(false);
      // formValue["state_id"] = selectedState.value.toString();
      formData.append("team_mate_type", selectedteamType.value);
    }
    if (data.logo[0]) {
      formData.append("logo", data.logo[0]);
    }
    if (data.banner[0]) {
      formData.append("banner", data.banner[0]);
    }
    if (data.password) {
      formData.append("password", data.password);
    }
    if (data.website) {
      formData.append("website", data.website);
    }

    setNotLoading(false);
    axios
      .post(apiService.API_URL() + `/api/v1/team/${teamId}`, formData, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        setNotLoading(true);
        navigate("/dashboard/home");
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  return (
    <>
      <ToastContainer />
      {notLoading && (
        <div className="d-flex flex-column bg-light">
          <section className="d-flex flex-column align-items-center justify-content-center my-4">
            <h2 className="text-capitalized fs-1 fw-light">Edit Team</h2>
          </section>

          <section>
            <div className="container team-form">
              <p>
                <span className="text-danger">*</span> Indicates required field
              </p>
              <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-12">
                  <label htmlFor="name" className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>

                  <input
                    type="test"
                    className="form-control"
                    placeholder="Team Name"
                    id="createTeamName"
                    {...register("name", {
                      required: true,
                      value: team.name ? team.name : "",
                    })}
                  />

                  {errors.name && (
                    <div className="text-danger my-2 fst-italic">
                      Please enter the valid team name.
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="country" className="form-label">
                    Country <span className="text-danger">*</span>
                  </label>
                  <Select
                    value={selectedCountry}
                    onChange={onChangeCountry}
                    options={countries}
                    className="react-select-container"
                    name="county_id"
                  />
                  {countriesHasError && (
                    <div className="text-danger my-2 fst-italic">
                      Please select your country.
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <label htmlFor="state" className="form-label">
                    State/LGA <span className="text-danger">*</span>
                  </label>

                  {selectedCountry?.label !== "Nigeria" && (
                    <>
                      <CreatableSelect
                        isClearable
                        options={stateVal}
                        onChange={onChangeState}
                        value={selectedState}
                        className="react-select-container"
                      />
                      <span className="help_text">Select or Create State</span>
                    </>
                  )}

                  {selectedCountry?.label === "Nigeria" && (
                    <>
                      <Select
                        value={selectedState}
                        onChange={onChangeState}
                        options={stateVal}
                        className="react-select-container"
                      />
                    </>
                  )}

                  {stateHasError && (
                    <div className="text-danger my-2 fst-italic">
                      Please enter the State/LGA.
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="name" className="form-label">
                    Organization or School{" "}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="test"
                    className="form-control"
                    placeholder="Oraganization or Company"
                    id="organization_or_school"
                    {...register("organization_or_school", {
                      required: true,
                      value: team.organization_or_school
                        ? team.organization_or_school
                        : "",
                    })}
                  />
                  {errors.organization_or_school && (
                    <div className="text-danger my-2 fst-italic">
                      Please enter the organization or school.
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="team_mate_type" className="form-label">
                    Which of the following best describes your team?{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    value={selectedteamType}
                    onChange={onChangeTeammateType}
                    options={teamType}
                    className="react-select-container"
                    id="team_mate_type"
                  />
                  {teamHasError && (
                    <div className="text-danger my-2 fst-italic">
                      Please enter the State/LGA.
                    </div>
                  )}
                  {/* <label htmlFor="name" className="form-label">
                  Which of the following best describes your team?
                </label>

                <input
                  type="test"
                  className="form-control"
                  id="team_mate_type"
                /> */}

                  {errors.team_mate_type && (
                    <div className="text-danger my-2 fst-italic">
                      Please enter the team_mate_type.
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="name" className="form-label">
                    Website
                  </label>

                  <input
                    type="test"
                    className="form-control"
                    placeholder="URL"
                    id="website"
                    {...register("website", {
                      value: team.website ? team.website : "",
                    })}
                  />

                  {errors.website && (
                    <div className="text-danger my-2 fst-italic">
                      Please enter the valid website.
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="password" className="form-label">
                    Password (optional)
                  </label>

                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Team Password"
                    {...register("password")}
                  />
                  {errors.password && (
                    <div className="text-danger my-2 fst-italic">
                      Please enter the valid password.
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label htmlFor="password" className="form-label">
                    Team logo or Avatar
                  </label>

                  <div
                    className="input-group file-input-group"
                    data-controller="file-input"
                  >
                    <input
                      type="file"
                      className="form-control"
                      {...register("logo")}
                    />
                    {errors.logo && (
                      <div className="text-danger my-2 fst-italic">
                        Please enter the logo.
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <label htmlFor="password" className="form-label">
                    Team banner image
                  </label>

                  <div
                    className="input-group file-input-group"
                    data-controller="file-input"
                  >
                    <input
                      type="file"
                      className="form-control"
                      {...register("banner")}
                    />
                    {/* {...register("banner")} */}
                    {errors.banner && (
                      <div className="text-danger my-2 fst-italic">
                        Please enter the banner.
                      </div>
                    )}
                  </div>
                </div>

                <div className="my-4 pb-5">
                  <button
                    className="btn bg-pla-primary text-white px-5 rounded-pill py-2"
                    type="submit"
                  >
                    Update Team
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      )}
      <LoadingSpinner hidden={notLoading} />
    </>
  );
};

export default EditTeam;
