import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiService from '../../services/api.service';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { useForm } from 'react-hook-form';
import EventHeader from './event-header';

export default function HeaderLayout(props) {
  // let deferredPrompt;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isActiveMobilePopup, setStateMobilePopup] = useState(false);
  const [isShowToPaymentGatewayModel, setToPaymentGatewayModel] =
    useState(false);
  const [paymentValues, setpaymentValues] = useState(null);
  const [isShowPaymentBtn, setShowPaymentBtn] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const userNavigateToDashboard = localStorage.getItem('user');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mob_menu_toggle = (prop) => {
    if (prop === true) {
      setStateMobilePopup(false);
    } else {
      setStateMobilePopup(true);
    }
  };

  const [sticky, setSticky] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSticky(position > 5 ? true : false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onLogin = () => {
    navigate('/sign-in');
  };

  const navigatetoDashboard = () => {
    const user = localStorage.getItem("user");
    const userdata = JSON.parse(user);
    if (userdata.team) {
      navigate('/dashboard/home');
    }
    else {
      const challlenge = localStorage.getItem('challenge');
      const challlengeData = JSON.parse(challlenge);

      toast.warning('Please create or join a team');
      navigate('/join-team/' + challlengeData.id);
    }
  }
  const goToStaticPage = () => {
    navigate('/');
  };

  // const onLogout = () => {
  //   toast.success('Logout successfully!');
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('user');
  //   dispatch(authActions.setToken(null));
  //   dispatch(authActions.setToken(null));
  //   props.logout();
  //   navigate('/sign-in');
  // };

  // payment start
  const closePopup = () => {
    setToPaymentGatewayModel(false);
    setpaymentValues(null);
    setShowPaymentBtn(false);
  };
  const isShowToPaymentGateway = () => {
    setToPaymentGatewayModel(true);
  };

  const onPaymentSumbit = (data) => {
    var formvalue = {
      name: data.customername,
      emailId: data.EmailId,
      phoneNo: data.PhoneNumber,
      donateAmt: data.Amount,
    };
    setpaymentValues(formvalue);
    setShowPaymentBtn(true);
  };

  const config = {
    public_key: apiService.paymentPublickKey(), // 'FLWPUBK_TEST-aec09808d78e8e4c36fcd2c0df4b3728-X'
    tx_ref: Date.now(),
    amount:
      paymentValues?.donateAmt != null ? Number(paymentValues.donateAmt) : 0,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: paymentValues?.emailId != null ? paymentValues.emailId : '',
      phonenumber: paymentValues?.phoneNo != null ? paymentValues.phoneNo : '',
      name: paymentValues?.name != null ? paymentValues.name : '',
    },
    customizations: {
      title: 'plafera',
      description: 'Donation',
      logo: 'https://plafera.org/assets/images/image.png',
    },
  };

  const fwConfig = {
    ...config,
    text: 'Pay Now...',
    callback: (response) => {
      closePaymentModal(); // this will close the modal programmatically
      setToPaymentGatewayModel(false);
      setpaymentValues(null);
      setShowPaymentBtn(false);
      toast.success('Payment is' + ' ' + response.status);
    },
    onClose: () => { },
  };

  // payment end

  return (
    <>
      <EventHeader />
      <ToastContainer />
      {sticky && (
        <div className="mob_header">
          <div className="container pos_rel">
            <div className="logo">
              <div
                style={{ float: 'left' }}
                onClick={goToStaticPage}
                className="cursor-pointer"
              >
                <img src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/image.png'} alt="logo" /> PLAFERA
              </div>
            </div>

            {token ? (
              // <FaPowerOff className="login_icn" onClick={onLogout} />
              <div>
                <span className="tooltip-data" data-msg="Go To Dashboard">
                  <i
                    className="fa fa-tachometer login_icn tooltip-body"
                    aria-hidden="true"
                    onClick={navigatetoDashboard}
                  ></i>
                </span>
              </div>
            ) : (
              <span className="tooltip-data" data-msg="Go To Login">
                <i
                  className="fa fa-user login_icn tooltip-body"
                  aria-hidden="true"
                  onClick={onLogin}
                ></i>
              </span>
            )}

            <div
              className="mobile_menu"
              onClick={() => mob_menu_toggle(isActiveMobilePopup)}
              style={{ right: '0px' }}
            >
              {!isActiveMobilePopup && (
                <>
                  <div></div>
                  <div></div>
                  <div></div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {!sticky && (
        <div
          className="second_header"
          style={{ backgroundImage: `url(${'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/BG_Group.png'})` }}
        >
          <div className="container pos_rel">
            <div className="logo">
              <img
                src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/image.png'}
                className="cursor-pointer"
                alt="logo"
                onClick={goToStaticPage}
              />{' '}
              <span onClick={goToStaticPage} className="cursor-pointer">
                PLAFERA{' '}
              </span>
            </div>
            {token ? (
              // <FaPowerOff className="login_icn" onClick={onLogout} />
              <span className="tooltip-data" data-msg="Go To Dashboard">
                <i
                  className="fa fa-tachometer login_icn tooltip-body"
                  aria-hidden="true"
                  onClick={navigatetoDashboard}
                ></i>
              </span>
            ) : (
              <span className="tooltip-data" data-msg="Go To Login">
                <i
                  className="fa fa-user login_icn tooltip-body"
                  aria-hidden="true"
                  onClick={onLogin}
                ></i>
              </span>
            )}

            <div
              className="mobile_menu"
              onClick={() => mob_menu_toggle(isActiveMobilePopup)}
              style={{ right: '0px' }}
            >
              {!isActiveMobilePopup && (
                // <img src={"/assets/images/menuhumbergermenu.svg"} alt="menuhumbergermenu" className="menuhumbergermenu" />
                <>
                  <div></div>
                  <div></div>
                  <div></div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {/* ================= Menu popup start ==================== */}
      {isActiveMobilePopup && (
        <div
          className="mob_menu_popup_body"
          onClick={() => mob_menu_toggle(isActiveMobilePopup)}
        >
          <div className="mob_menu_popup_content">
            <div className="menus">
              <ul>
                <li onClick={() => navigate('/')}>
                  <i className="fa fa-home" aria-hidden="true"></i>Home
                </li>
                <li>
                  <a
                    href="https://shop.plafera.org"
                    target="blank"
                  >
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                    STORE
                  </a>
                </li>
                <li onClick={isShowToPaymentGateway}>
                  <i className="fa fa-id-card-o" aria-hidden="true"></i>Donate
                </li>

                <li onClick={() => navigate('/privacy-policy')}>
                  <i className="fa fa-user-secret" aria-hidden="true"></i>
                  Privacy Policy
                </li>
                <li onClick={() => navigate('/terms-and-conditions')}>
                  <i className="fa fa-check-square" aria-hidden="true"></i>
                  Terms and Conditions
                </li>
                <li
                  onClick={() => navigate('/quiz-terms-and-conditions')}
                  className="d-flex align-items-center"
                >
                  <i className="fa fa-trophy" aria-hidden="true"></i>
                  Plafera Quiz Terms and Conditions
                </li>
                <li onClick={() => navigate('/faq')}>
                  <i className="fa fa-comments" aria-hidden="true"></i>
                  Frequently Asked Questions
                </li>
                <li onClick={() => navigate('/how-it-works')}>
                  <i className="fa fa-code-fork" aria-hidden="true"></i>
                  How It Works
                </li>
                <li onClick={() => navigate('/about')}>
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                  About
                </li>
                <li onClick={() => navigate('/blog')}>
                  <i className="fa fa-th-large" aria-hidden="true"></i>
                  Blog
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {/* ================= Menu popup end ==================== */}

      {/* payment popup start */}
      {isShowToPaymentGatewayModel && (
        <div className="popup_body">
          <div className="popup_content">
            <div className="popup_close" onClick={closePopup}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5 className="payment_title text-center mt-4 mb-4">DONATE</h5>
              </div>
            </div>

            {isShowPaymentBtn == true && (
              <div className="payment_subtitle">Proceed To Pay...</div>
            )}
            <form className="row" onSubmit={handleSubmit(onPaymentSumbit)}>
              {isShowPaymentBtn == false && (
                <>
                  <div className="col-md-6">
                    <label htmlFor="Name" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      {...register('customername', { required: true })}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="EmailId" className="form-label">
                      Email Id <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="EmailId"
                      {...register('EmailId', { required: true })}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="PhoneNumber" className="form-label">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="PhoneNumber"
                      {...register('PhoneNumber', { required: true })}
                    />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="Amount" className="form-label">
                      Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="Amount"
                      {...register('Amount', { required: true })}
                    />
                  </div>
                </>
              )}

              {isShowPaymentBtn == false && (
                <div className="col-md-12 colsm-12 col-xs-12">
                  <div className="mar_auto mt-4 mb-4">
                    <button className="theme_btn">Submit</button>
                  </div>
                </div>
              )}

              {isShowPaymentBtn == true && (
                <div className="col-md-12 colsm-12 col-xs-12">
                  <div className="mar_auto mt-4 mb-4 payment_btn">
                    <FlutterWaveButton {...fwConfig} />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
}
