import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import LoadingSpinner from '../../components/LoadingSpinner';
import apiService from '../../services/api.service';
import HeaderLayout from '../../components/header-layout/header-layout';
import Footer from '../../components/footer-layout/footer';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

function OverAllImpact() {
    const { data: impact, isLoading, refetch } = useQuery(
        'overallImpact',
        () =>
            axios
                .get(apiService.API_URL() + '/api/v1/all-impact')
                .then((response) => response.data),
        {
            enabled: false, // Set to false initially
            // onSuccess: () => {
    
            // },
            onError: (error) => {
                if (error.message) {
                    toast.error(error.message);
                }
            },
        }
    );

    useEffect(() => {
        refetch(); // Fetch data when the component mounts
    }, [refetch]);

    return (
        <div>
            <ToastContainer />
            <HeaderLayout />
            <LoadingSpinner hidden={!isLoading} />
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <section className="plafera_impact">
                    <div className="container">
                        <div className="row mb-5">
                            <div className='col-md-12 col-sm-12 col-xs-12'>
                                <h5 className='title mt-5 fnt_34px'>Plafera Impact </h5>
                            </div>
                            {impact && impact.map((item, index) => (
                                <div className="col-md-6" key={index}>
                                    <div className="impact_card">
                                        <div className="img_set">
                                            <img src={item?.image} alt="water-pollution" />
                                        </div>
                                        <p className="upto">Up to</p>
                                        <p
                                            className="number"
                                            style={{
                                                color:
                                                    item?.slug === 'skip-plastic-bags'
                                                        ? 'red'
                                                        : item?.slug === 'skip-pure-water'
                                                        ? '#807500'
                                                        : item?.slug === 'say-no-to-styrofoam'
                                                            ? '#238563'
                                                            : '#007FAD',
                                            }}
                                        >
                                            {item?.count}
                                        </p>
                                        <p className="content">{item?.name} </p>
                                        <p className="fw-bold">{item?.desctiption}</p>
                                    </div>
                                </div>
                            ))} 
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default OverAllImpact;
