import React from 'react';
import Footer from '../../components/footer-layout/footer';
import HeaderLayout from '../../components/header-layout/header-layout';

const PrivacyPolicy = (props) => {
  return (
    <>
      <HeaderLayout />
      <div className="container">
        <h3 className="my-5 fw-bold text-center">Plafera Privacy Policy</h3>
        <h5 className="my-3 fw-bold">1. Privacy Policy</h5>
        <p className="fs-6">
          This is the Web App for Plastic Free Rainy Season Sensitization
          Initiative (IT - 170255) (Plafera). Plafera is committed to
          safeguarding the privacy of supporters. This policy sets out how we at
          Plafera will treat your personal information.{' '}
        </p>
        <br />
        <h5 className="my-3 fw-bold">
          2. Your personal information is important to us
        </h5>
        <p className="fs-6">
          We recognize that your privacy is very important to you and that you
          have a right to control your personal information. We know that
          providing personal information is an act of trust and we take that
          seriously.
        </p>
        <br />
        <h5 className="my-3 fw-bold">
          3. Collecting personal information about you
        </h5>
        <h6 className="my-3 fw-bold">
          3.1 What kind of information do we collect?
        </h6>
        <p className="fs-6">
          We may collect personal information in relation to your interactions
          with us. The types of personal information we collect depend on how
          you interact with us.
        </p>
        <br />
        <p className="fs-6">
          You can visit our websites without disclosing any personal information
          about yourself. Our system may record non-personal information, such
          as your server address, the date and time of your visit, and any pages
          viewed. We use such non-personal information for statistical and
          website administration purposes.
        </p>
        <br />
        <p className="fs-6">
          We may also collect personal information that you give us when you
          interact with us – for example, your name, your place of residence,
          and your contact details.
        </p>
        <br />
        <p className="fs-6">
          We do not collect sensitive information about you – such as
          information about your health, ethnic origin, and religious beliefs –
          unless you (or a parent, guardian, or other person authorized to act
          on your behalf), have consented or the collection is otherwise
          permitted by law. We will regard consent as inferred if the
          information is freely provided to us by you, or by a family member or
          other person authorized to act on your behalf.
        </p>
        <h6 className="my-3 fw-bold">
          3.2 When do we collect personal information?
        </h6>
        <h6 className="my-3 fw-bold">
          We may collect personal information about you when you:
        </h6>

        <ul className="my-3">
          <li>Register for Plafera Challenge or register an event;</li>
          <li>Share stories or complete a survey on our Web App;</li>
          <li>Make a donation; or</li>
          <li>Make an inquiry.</li>
        </ul>
        <p className="fs-6">
          We will only collect personal information that is reasonably necessary
          for us to carry out our functions and services. If we are unable to
          collect or use your information, we may not be able to engage with you
          effectively or at all. We will generally tell you why we are
          collecting personal information about you when we collect it unless
          the reasons would be obvious to you.
        </p>
        <br />
        <p className="fs-6">
          All personal information you provide to our website is delivered
          through a secure, encrypted connection called the secure sockets layer
          (SSL). We use SSL encryption which provides industry-standard levels
          of protection against unauthorized access to the information while it
          is in transit over the Internet.
        </p>
        <br />
        <h5 className="my-3 fw-bold">
          4. Management of your personal information
        </h5>
        <h6 className="my-3 fw-bold">
          4.1 Using and disclosing your personal information
        </h6>
        <p className="fs-6">
          We will only use or disclose information about you for the purposes
          for which we collected it, for related purposes that you are likely to
          expect, or as otherwise permitted by law. This may include using or
          disclosing your personal information:
        </p>
        <ul className="my-3">
          <li>
            As required to provide and manage our services, including assisting
            with fundraising events and campaigns;
          </li>
          <li>
            To keep you informed of and to seek support for our services or
            fundraising activities;
          </li>
          <li>To procure or process a donation;</li>
          <li>To enforce our terms of use and contracts;</li>
          <li>When required by law; or</li>
          <li>
            To protect our rights or property or those of any member of the
            public.
          </li>
        </ul>
        <h6 className="my-3 fw-bold">4.2 Electronic advertising</h6>
        <p className="fs-6">
          We will not send you spam (electronic advertising information) without
          your consent. Any email we send to you with your permission will
          include an ‘unsubscribe’ link that allows you to opt-out of receiving
          any future communication from us.
        </p>
        <h6 className="my-3 fw-bold">4.3 Cookies</h6>
        <p className="fs-6">
          Cookies are text files placed on your computer to collect standard
          internet log information and visitor behavior information. When you
          visit our Web App, we may collect information from you automatically
          through cookies or similar technology
        </p>
        <br />
        <p className="fs-6">
          We use cookies to improve your experience on our Web App. There are
          several different types of cookies. Our Web App uses a mix of
          first-party and third-party cookies to improve functionality (e.g., to
          remember your previously selected preferences) and to collect
          information about your visit to our Web App (e.g. the content you
          viewed and the links you followed).
        </p>
        <br />
        <p className="fs-6">
          You can set your browser not to accept cookies. However, in a few
          cases, some of our Web App features may not function as a result.
        </p>

        <h6 className="my-3 fw-bold">
          4.4 Storage and security of your personal information
        </h6>
        <p className="fs-6">
          We will take all reasonable steps to keep any information that we hold
          about you secure, accurate, and up to date. Your information is stored
          on secure servers. We may use third parties including overseas
          companies, to provide services including processing and storage of all
          or part of the information we hold about individuals.
        </p>
        <br />
        <p className="fs-6">
          In providing your information (including any sensitive information),
          you consent to us and our service providers processing and storing
          information about you in this way and confirm that anyone else whom
          you have provided information about also consents to this. Our
          employees and contractors who provide services related to our
          information systems are obliged to respect the confidentiality of any
          personal information held by us.
        </p>
        <br />
        <p className="fs-6">
          While we take all reasonable steps to secure any personal information
          provided by you over the internet you should be aware that there are
          inherent risks in internet transmission. You should keep us informed
          of any changes to your personal information using the contact details
          below.
        </p>
        <br />
        <p className="fs-6">
          We will keep your personal information for as long as it is required
          either for our legitimate business purposes or to comply with our
          legal requirements.
        </p>
        <br />
        <h5 className="my-3 fw-bold">
          5. Accessing and correcting your personal information
        </h5>

        <p className="fs-6">
          You have the right to request access to your personal information that
          we hold. If you wish to request access, then you should:
        </p>
        <ul className="my-3">
          <li>
            Contact us at the details provided at the end of this privacy
            policy; and
          </li>
          <li>
            Provide as much detail as you can about the particular information
            you seek, to help us locate it.
          </li>
        </ul>
        <p className="fs-6">
          We may need to verify your identity and may charge a reasonable fee
          for this service.
        </p>
        <br />
        <p className="fs-6">
          If the information we hold about you is inaccurate, please let us know
          at the contact details provided at the end of this policy.
        </p>
        <br />
        <p className="fs-6">
          We will provide our reasons if we deny any request for access to or
          correction of personal information. When we decide not to make a
          requested correction to your personal information and you disagree,
          you may ask us to make a note of your requested correction with the
          information.
        </p>
        <br />
        <h5 className="my-3 fw-bold">6. Jurisdiction</h5>
        <h6 className="my-3 fw-bold">6.1 International transmissions</h6>
        <p className="fs-6">
          When you register, you acknowledge that in using Plafera’s services to
          send electronic communications, you will be causing communications to
          be sent through Plafera’s computer networks, which are located in
          various locations in Nigeria, and portions of which are located in
          other countries. Accordingly, by agreeing to this privacy policy, you
          acknowledge that the use of the service results in interstate and
          international data transmissions.
        </p>

        <h6 className="my-3 fw-bold">
          6.2 European Union data protection laws
        </h6>
        <p className="fs-6">
          If European Union data protection laws apply to the processing of your
          information, you can exercise your right to request access to, update,
          remove, and restrict the processing of your information according to
          this privacy policy. We will retain your personal information unless
          you tell us that you would like it to be removed. You also have the
          right to object to the processing of your information and to export
          your information to another service.
        </p>

        <p className="fs-6">
          This privacy policy sets out the legal grounds for the processing of
          your information, which are based on:
        </p>
        <ul className="my-3">
          <li>
            Your consent, which you may withdraw at any time without affecting
            the lawfulness of processing based on consent before its withdrawal;
          </li>
          <li>
            Our legitimate interests in engaging in fundraising activities and
            assisting others to do so, providing and improving other services,
            and enhancing the website experience of our website users, visitors,
            and registered members; and
          </li>
          <li>Complying with our legal obligations.</li>
        </ul>
        <br />
        <h5 className="my-3 fw-bold">7. Other websites</h5>
        <p className="fs-6">
          Our Web App contains links to other websites. Our privacy policy
          applies only to our Web App, so if you click on a link to another
          website, you should read their privacy policy.
        </p>
        <br />
        <h5 className="my-3 fw-bold">8. Changes</h5>
        <p className="fs-6">
          We reserve the right to change this privacy policy at any time by
          posting an updated version of our privacy policy on this site.
        </p>
        <br />
        <h5 className="my-3 fw-bold">9. Contacting us</h5>
        <h6 className="my-3 fw-bold">9.1 Our details</h6>
        <p className="fs-6">
          If you have any queries or complaints or would like to access personal
          information that we hold about you, please contact us at
          info@plafera.org. We will investigate your complaint or request and
          respond within 30 days with a proposed resolution.
        </p>

        <p className="fs-6 fw-bold my-3">Last Updated: 19th September 2022</p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
