import React, { useRef, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import './campus-dashboard.css';
import EventHeader from '../header-layout/event-header';
import HeaderLayout from '../header-layout/header-layout';
import LoadingSpinner from '../LoadingSpinner';

function CampusDashboard({ logout }) {
  let { challengeSlug } = useParams();
  let challengeNameToBind = challengeSlug;

  // let challengeNameToBind = challengename.replaceAll('_', ' ');

  const teamMenu = [
    {
      id: 1,
      menuname: 'List Of Teams',
    },
    {
      id: 1,
      menuname: 'Organizations',
    },
    {
      id: 1,
      menuname: 'competitions',
    },
  ];
  const resourceMenu = [
    {
      id: 1,
      menuname: 'Event Info',
    },
    {
      id: 1,
      menuname: 'Points',
    },
    {
      id: 1,
      menuname: 'Resources',
    },
  ];

  const [loading, setLoading] = useState(false);
  const [displayTeamMenuDroupdown, setdisplayTeamMenu] = useState(false);
  const [displayResourceMenuDroupdown, setdisplayResourceMenu] =
    useState(false);

  const isShowMenus = (res) => {
    if (res === 'Team') {
      setdisplayTeamMenu(true);
      setdisplayResourceMenu(false);
      setTimeout(() => {
        setFocus(true);
      }, 100);
    } else if (res === 'Resources') {
      setdisplayResourceMenu(true);
      setdisplayTeamMenu(false);
      setTimeout(() => {
        setFocus(true);
      }, 100);
    }
  };
  // click any where to hide div
  const [focus, setFocus] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setFocus);

  function useOutsideAlerter(ref, setFocus) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFocus(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, setFocus]);
  }

  return (
    <>
      <HeaderLayout logout={logout} />
      {/* <EventHeader /> */}
      <div className="campus_header">
        <div className="text-center">
          {challengeNameToBind != null && (
            <div className="title mt-4 mb-4">{challengeNameToBind}</div>
          )}

          {/* <div className="menu_group">
            <div
              className="cus_droupdown"
              onClick={() => isShowMenus("Team")}
              ref={wrapperRef}
            >
              Team
              <i className="fa fa-angle-down" aria-hidden="true"></i>
              {displayTeamMenuDroupdown && focus && (
                <div className="cus_droupdown_content">
                  <ul>
                    {teamMenu.map((values) => (
                      <li>{values.menuname}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="menu">Participants</div>
            <div
              className="cus_droupdown"
              onClick={() => isShowMenus("Resources")}
              ref={wrapperRef}
            >
              Resource
              <i className="fa fa-angle-down" aria-hidden="true"></i>
              {displayResourceMenuDroupdown && focus && (
                <div className="cus_droupdown_content">
                  <ul>
                    {resourceMenu.map((values) => (
                      <li>{values.menuname}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
      {/* <Footer /> */}
      <LoadingSpinner hidden={!loading} />
      <Outlet />
    </>
  );
}
export default CampusDashboard;
