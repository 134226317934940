import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

import LoadingSpinner from '../../components/LoadingSpinner';
import apiService from '../../services/api.service';
import { useForm } from 'react-hook-form';

import Select from 'react-select';
import HeaderLayout from '../../components/header-layout/header-layout';
import Footer from '../../components/footer-layout/footer';
import DateTimePicker from 'react-datetime-picker';
import './style.css';

const TeamEvent = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [countries, setCountries] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);

  const [countriesHasError, setCountriesHasError] = useState(null);
  const [stateHasError, setStateHasError] = useState(null);
  const [cityHasError, setCityHasError] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  const userIdResult = localStorage.getItem('user');
  const userData = JSON.parse(userIdResult);

  const user = useSelector((state) => state.auth.user);

  const [notLoading, setNotLoading] = useState(true);
  const [eventDatetime, onChange] = useState(new Date());

  //clear select component value
  const cityRef = useRef(null);


  const changeKey = (arrayObj) => {
    for (var i = 0; i < arrayObj.length; i++) {
      arrayObj[i].value = arrayObj[i]['id'];
      arrayObj[i].label = arrayObj[i]['name'];
      if (arrayObj[i]['name'] == 'Nigeria') {
        setSelectedCountry(arrayObj[i])
      }
      delete arrayObj[i].id;
      delete arrayObj[i].name;
    }
  };

  useEffect(() => {
    countriesData();
    setValue('team_id', userData.team)
    setValue('team_name', userData.team_name)
  }, []);

  //;
  const onChangeFile = (e) => { };

  const onChangeCountry = async (value) => {
    setSelectedCountry(value);
    setSelectedState(null);


    setCountriesHasError(false);
    getState(value.value);

    if (value.label != 'Nigeria') {
      setSelectedArea(null);
      setValue('postal_code', null)
    }


  };

  const getState = async (value) => {
    axios
      .get(apiService.API_URL() + `/api/v1/states/${value}`)
      .then(function (response) {
        changeKey(response.data.data);
        setState(response.data.data);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  }


  const countriesData = async () => {
    axios
      .get(apiService.API_URL() + '/api/v1/countries')
      .then(function (response) {
        changeKey(response.data.data);
        setCountries(response.data.data);
        getState(160);

      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const onChangeState = async (value) => {
    setSelectedState(value);
    setSelectedCity(null);
    setStateHasError(false);
    setValue('postal_code', null)
    


    axios
      .get(apiService.API_URL() + `/api/v1/cities/${value.value}`)
      .then(function (response) {
        // changeKey(response.data.data);
        setCity(response.data.data);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });

  };

  const onChangeCity = async (value) => {
    setSelectedCity(value);
    setCityHasError(false);
    setValue('postal_code', null)

    if (selectedCountry.label === 'Nigeria') {
      axios
        .get(apiService.API_URL() + `/api/v1/postal-code/${value.value}`)
        .then(function (response) {
          setArea(response.data.post_code);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setNotLoading(true);
        });
    }
  };


  const onChangeArea = async (value) => {
    setSelectedArea(value);
    setValue('postal_code', value.post_code)
  };



  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('cleanup_location', data.cleanUpLocation);
    formData.append('city', selectedCity.value.toString());
    formData.append('description', data.description);
    formData.append('event_date_time', eventDatetime);
    formData.append('team_id', data.team_id);
    formData.append('country_id', selectedCountry.value.toString());
    formData.append('state_id', selectedState.value.toString());
    formData.append('cleanup_location', data.cleanUpLocation);

    if (selectedArea != null) {
      formData.append('local_government_area', selectedArea.value.toString());
      formData.append('postal_code', data.postal_code);
    }

    for (let i = 0; i < data.images.length; i++) {
      formData.append('attachments[]', data.images.item(i));
    }

    setNotLoading(false);
    axios
      .post(apiService.API_URL() + '/api/v1/create-event', formData, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        setNotLoading(true);
        navigate(`/dashboard/event-list/${user.id}`);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  return (
    <>
      <div className="d-flex flex-column bg-light">
        <section className="d-flex flex-column align-items-center justify-content-center my-4">
          <h2 className="fs-1 fw-light">Create a New Event</h2>
        </section>

        <section>
          <div className="container team-form">
            <p className='text-capitalize'>
              <span className="text-danger">*</span> indicates required field
            </p>
            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-12">
                <label htmlFor="team" className="form-label">
                  Team
                </label>
                <input
                  type="hidden"
                  className="form-control"
                  placeholder=""
                  id="team_id"
                  {...register('team_id')}
                  disabled />

                <input
                  type="text"
                  className="form-control"
                  placeholder="Team"
                  id="team_name"
                  {...register('team_name', { required: true })}
                  disabled />
              </div>

              <div className="col-12">
                <label htmlFor="cleanUpLocation" className="form-label">
                  Cleanup Location <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Cleanup location"
                  id="cleanUpLocation"
                  {...register('cleanUpLocation', { required: true })}
                />

                {errors.cleanUpLocation && (
                  <div className="text-danger my-2 fst-italic">
                    Please enter the valid clean up location.
                  </div>
                )}
              </div>
              {countries.length > 0 &&
                <>
                  <div className="col-12">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <Select
                      defaultValue={selectedCountry}
                      onChange={onChangeCountry}
                      options={countries}
                      className="react-select-container"
                      name="country"
                    />
                    {errors.country && (
                      <div className="text-danger my-2 fst-italic">
                        Please select your country.
                      </div>
                    )}
                  </div>

                  <div className="col-12">
                    <label htmlFor="state" className="form-label">
                      State <span className="text-danger">*</span>
                    </label>
                    <Select
                      defaultValue={selectedState}
                      onChange={onChangeState}
                      options={state}
                      className="react-select-container"
                      name="state"

                    />
                    {errors.state && (
                      <div className="text-danger my-2 fst-italic">
                        Please select your state.
                      </div>
                    )}
                  </div>

                  <div className="col-12">
                  <label htmlFor="city" className="form-label">
                   {selectedCountry.label === "Nigeria"? 'City/LGA' : 'City'}  <span className="text-danger">*</span>
                  </label>

                    <Select
                      defaultValue={selectedCity}
                      onChange={onChangeCity}
                      options={city}

                      ref={cityRef}
                      className="react-select-container"
                    />
                    {cityHasError && (
                      <div className="text-danger my-2 fst-italic">
                        Please select the city.
                      </div>
                    )}
                  </div>
                  {selectedCountry.label === 'Nigeria' &&
                    <>
                      <div className="col-12">
                        <label htmlFor="local_government_area" className="form-label">
                        Locality <span className="text-danger">*</span>
                        </label>

                        <Select
                          defaultValue={selectedArea}
                          onChange={onChangeArea}
                          options={area}
                          className="react-select-container"
                        />
                        {cityHasError && (
                          <div className="text-danger my-2 fst-italic">
                            Please select the local government area.
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        <label htmlFor="postal_code" className="form-label">
                          Postal Code <span className="text-danger">*</span>
                        </label>
                        <input
                          type="test"
                          className="form-control"
                          placeholder="Post code"
                          id="postCode"
                          {...register('postal_code', { required: true })}
                          disabled />
                      </div>
                    </>
                  }
                </>
              }

              <div className="col-12">
                <label htmlFor="event_date_time" className="form-label">
                  Event Date time
                </label>

                <DateTimePicker
                  wrapperClassName="datePicker"
                  name="event_date_time"
                  className="form-control"
                  onChange={onChange}
                  value={eventDatetime}
                />
                {errors.event_date_time && (
                  <div className="text-danger my-2 fst-italic">
                    Please choose date and time
                  </div>
                )}
              </div>

              <div className="col-12">
                <label htmlFor="description" className="form-label">
                  Description
                </label>

                <textarea
                  className="form-control"
                  id="description"
                  placeholder="Description"
                  {...register('description', {
                    required: true,
                  })}
                ></textarea>

                {errors.description && (
                  <div className="text-danger my-2 fst-italic">
                    Please add description.
                  </div>
                )}
              </div>

              <div className="col-12">
                <label htmlFor="images" className="form-label">
                  Images before event
                </label>

                <div
                  className="input-group file-input-group"
                  data-controller="file-input"
                >
                  <input
                    type="file"
                    multiple
                    className="form-control"
                    onChange={onChangeFile}
                    accept="image/png, image/jpeg"
                    {...register('images', {
                      required: true,
                    })}
                  />
                </div>
                {errors.images && (
                  <div className="text-danger my-2 fst-italic">
                    Please upload event location images.
                  </div>
                )}
              </div>

              <div className="my-4 pb-5">
                <button
                  className="btn bg-pla-primary text-white px-5 rounded-pill py-2"
                  type="submit"
                >
                  Create Event
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
      <LoadingSpinner hidden={notLoading} />
    </>
  );
};

export default TeamEvent;
