import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import apiService from "../../services/api.service";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import * as pieChartAction from "../../store/actions/pie-chart";
import { useSelector, useDispatch } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  const dispatch = useDispatch();
  const [chart, setChart] = useState({});
  const [loading, setLoading] = useState(false);

  const [participantPieChartLabel, setParticipantPieChartLabel] = useState({});
  const [participantPieChartData, setParticipantPieChartData] = useState({});
  const [participantPieChartBgColor, setParticipantPieChartBgColor] = useState(
    {}
  );

  const [impactPieChartLabel, setImpactPieChartLabel] = useState({});
  const [impactPieChartData, setImpactPieChartData] = useState({});
  const [impactPieChartBgColor, setImpactPieChartBgColor] = useState({});

  const participantsStore = useSelector((state) => state.pieChart.participants);
  const impactsStore = useSelector((state) => state.pieChart.impacts);

  useEffect(() => {
    fetchParticipants();
    fetchImpact();
  }, []);

  useEffect(() => {
    if (Object.keys(participantsStore).length !== 0) {
      setChart(participantsStore);

      setParticipantPieChartLabel(JSON.parse(participantsStore?.xValues));
      setParticipantPieChartData(JSON.parse(participantsStore?.yValues));
      setParticipantPieChartBgColor(JSON.parse(participantsStore?.colors));
    }
  }, [participantsStore]);

  useEffect(() => {
    if (Object.keys(impactsStore).length !== 0) {
      setChart(impactsStore);
      setImpactPieChartLabel(JSON.parse(impactsStore?.xValues));
      setImpactPieChartData(JSON.parse(impactsStore?.yValues));
      setImpactPieChartBgColor(JSON.parse(impactsStore?.colors));
    }
  }, [impactsStore]);

  const fetchParticipants = () => {
    axios
      .get(apiService.API_URL() + `/api/v1/chart-participants`)
      .then(function (response) {
        dispatch(pieChartAction.setParticipants(response.data.participants));
        /* setChart(response.data.participants);
        setParticipantPieChartLabel(
          JSON.parse(response.data.participants.xValues)
        );
        setParticipantPieChartData(
          JSON.parse(response.data.participants.yValues)
        );
        setParticipantPieChartBgColor(
          JSON.parse(response.data.participants.colors)
        ); */
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  const fetchImpact = () => {
    axios
      .get(apiService.API_URL() + `/api/v1/chart-impact`)
      .then(function (response) {
        dispatch(pieChartAction.setimpacts(response.data.impact));
        /* setChart(response.data.participants);
        setImpactPieChartLabel(JSON.parse(response.data.impact.xValues));
        setImpactPieChartData(JSON.parse(response.data.impact.yValues));
        setImpactPieChartBgColor(JSON.parse(response.data.impact.colors)); */
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };

  var participantsData = {
    labels: participantPieChartLabel,
    datasets: [
      {
        label: `${participantPieChartData.length} Coins Available`,
        data: participantPieChartData,
        backgroundColor: participantPieChartBgColor,
        borderColor: participantPieChartBgColor,
        borderWidth: 1,
      },
    ],
  };

  var impactData = {
    labels: impactPieChartLabel,
    datasets: [
      {
        label: `${impactPieChartData.length}`,
        data: impactPieChartData,
        backgroundColor: impactPieChartBgColor,
        borderColor: impactPieChartBgColor,
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {},
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  };

  return (
    <div className="container">
      {Object.keys(participantsStore).length !== 0 && (
        <div className="row">
          <h3 className="title fnt_34px">Participants</h3>
          <div className="col-md-12 col-sm-12 col-xs-12 mt-5 mb-5">
            <Pie data={participantsData} height={400} options={options} />
          </div>
        </div>
      )}
      {Object.keys(impactsStore).length !== 0 && (
        <div className="row">
          <h3 className="title fnt_34px">Impact</h3>
          <div className="col-md-12 col-sm-12 col-xs-12 mt-5 mb-5">
            <Pie data={impactData} height={400} options={options} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PieChart;
