import React, { useState, useEffect } from "react";
import { FaHandSparkles } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { format, parseISO } from "date-fns";
import Pagination from "react-js-pagination";

import CUSRichTextEditor from "../../components/rich-text-editor/richText";
import apiService from "../../services/api.service";
import LoadingSpinner from "../../components/LoadingSpinner";

import { useSelector, useDispatch } from "react-redux";
import * as feedsAction from "../../store/actions/feeds";

const Feeds = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userIdResult = localStorage.getItem("user");
  const challenge = localStorage.getItem("challenge");
  const challengedata = JSON.parse(challenge);

  const userID = JSON.parse(userIdResult);
  const [notLoading, setNotLoading] = useState(true);
  const [getAllFeeds, setgetAllFeeds] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageRange, setPageRange] = useState();
  const [getrichtextvalue, setgetrichtextvalue] = useState();
  const [isCheckGlobelTeamsFeed, setisCheckGlobelTeamsFeed] = useState(0);
  const [showFeedReplay, setShowFeedReplay] = useState(false);
  const [isShowFeedReplayIndex, setIsShowFeedReplayIndex] = useState(false);
  const [isCurrentUserTeamFeed, setCurrentUserTeamFeed] = useState(false);
  const [globelTeamFeedValue, setGlobelTeamFeedValue] = useState("");

  const allFeedsStore = useSelector((state) => state.feeds.allFeeds);

  useEffect(() => {
    GetAllFeed(1);
  }, []);
  useEffect(() => {
    if (allFeedsStore) {
      setgetAllFeeds(allFeedsStore);
      setRecordPerPage(allFeedsStore.per_page);
      setTotalRecords(allFeedsStore.total || 0); // Use 0 if allFeedsStore.total is falsy
      setPageRange(allFeedsStore.per_page);
    }
  }, [allFeedsStore]);

  const GetAllFeed = (pageNumber) => {
    if (pageNumber == 1) {
      setCurrentPage(pageNumber);
    } else {
      setCurrentPage(pageNumber);
    }

    setNotLoading(false);
    axios
      .get(
        apiService.API_URL() +
          "/api/v1/getfeed?page=" +
          pageNumber +
          "&user_id=" +
          userID.id,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(function (response) {
        dispatch(feedsAction.setFeeds(response.data.feeds));
        setNotLoading(true);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const checkGlobelTeamsFeed = (e) => {
    if (e.checked === true) {
      setisCheckGlobelTeamsFeed(1);
    } else {
      setisCheckGlobelTeamsFeed(0);
    }
  };

  const globelTeamFeedS = (value) => {
    setGlobelTeamFeedValue(value);
  };

  const submitGlobelRichtextValue = () => {

    if(globelTeamFeedValue == ""){
      toast.error("Feed cannot be empty")
    }
    setNotLoading(false);
    const data = {
      user_id: userID.id,
      team_id: 1,
      parent_id: 0,
      only_team_feed: isCheckGlobelTeamsFeed,
      content: globelTeamFeedValue,
      challenge_id: challengedata.id,
    };

    setNotLoading(false);
    axios
      .post(apiService.API_URL() + "/api/v1/feed/create", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        toast.success(response.data.message);
        GetAllFeed(1);
        setGlobelTeamFeedValue("");
        setNotLoading(true);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const isShowFeedReplay = (e, index) => {
    setShowFeedReplay(e);
    setIsShowFeedReplayIndex(index);
  };

  const currentUserTeamFeed = (e) => {
    setCurrentUserTeamFeed(e);
  };

  const submitCurrentUserTeamFeed = (parentID) => {
    setNotLoading(false);
    const data = {
      user_id: userID.id,
      team_id: 1,
      parent_id: parentID,
      only_team_feed: 0,
      content: isCurrentUserTeamFeed,
      challenge_id: challengedata.id,
    };

    setNotLoading(false);
    axios
      .post(apiService.API_URL() + "/api/v1/feed/create", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        toast.success(response.data.message);
        setNotLoading(true);
        GetAllFeed(1);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  const deleteFeeds = (feedId, userId) => {
    setNotLoading(false);
    const data = {
      id: feedId,
      user_id: userId,
    };
    setNotLoading(false);
    axios
      .post(apiService.API_URL() + "/api/v1/feed/delete", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        toast.success(response.data.message);
        GetAllFeed(1);
        setNotLoading(true);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  return (
    <div className="bg-light min pad_lr_15px overflow_x_hide">
      <div className="team-form m-auto">
        <div className="auth-form m-auto">
          <h1 className="text-center fw-light pt-5 fs-2">
            Show updates from <span className="fw-bold">My Team</span>
          </h1>
          <p className="text-center mx-auto my-3 text fs-6">
            Reflection, encouragement, and relationship building are all
            important aspects of getting a new habit to stick. Share thoughts,
            encourage others, and reinforce positive new habits on the Feed.
          </p>
          <p className="text-center mb-5 fs-6">
            To get started, share “your why”. Why did you join the challenge and
            choose the actions you did?
          </p>
        </div>
        <div className="bg-white  border">
          <div className="form-floating">
            <textarea
              className="form-control height_150"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              value={globelTeamFeedValue}
              onChange={(e) => globelTeamFeedS(e.target.value)}
            ></textarea>
            <label htmlFor="floatingTextarea">Enter some TEAM'S FEED</label>
          </div>
        </div>
        <p className="m-0 mt-1 text-danger">Note: Your feeds can only be displayed once they are approved.</p>

        <div className="row">
          <div className="col-md-6 col-xs-12 col-sm-12">
            <div className="mt-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="only-team"
                  onChange={(e) => checkGlobelTeamsFeed(e.target)}
                />
                <label className="form-check-label">
                  POST ONLY TO MY TEAM'S FEED
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xs-12 col-sm-12 justify-content-between pb-5 mb-5">
            <div className="d-flex gap-5 align-items-center float_right">
              <p className="fw-bold text-center mt-3">EARN 5 POINTS</p>
              <button
                className="btn px-5 bg-pla-primary text-white btn-sm"
                onClick={() => submitGlobelRichtextValue()}
              >
                Post
              </button>
            </div>
          </div>
        </div>

        <div className="bg-white ">
          {getAllFeeds?.data?.length == 0 && (
            <div className="DataNotThere">There are no records to display</div>
          )}

          {getAllFeeds?.data?.map((items, i) => {
            return (
              <React.Fragment key={items.id}>
                <div className="d-flex mb-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="bg-warning ing m-2 text-uppercase d-flex feedcircle">
                        {items.user[0].name.charAt(0)}
                      </div>
                    </div>
                    <div>
                      <div className="d-flex gap-2 mt-3 align-items-center">
                        <p className="fs-5 fw-bold">
                          {items.user[0].name} {items.user[0].last_name}
                        </p>
                        <p className="">
                          {format(
                            parseISO(items.created_at),
                            "dd/MM/yyyy h:m a"
                          )}
                        </p>
                      </div>
                      <p className="fs-6">{items.content}</p>

                      <div className="d-flex gap-3">
                        <div className="d-flex gap-1 cursor-pointer text-success">
                          <FaHandSparkles />

                          <p className="fw-bold text-success">HI-FIVE</p>
                        </div>
                        <div className="d-flex gap-1 cursor-pointer text-pla-primary">
                          <MdEdit className="" />
                          <p
                            className="fw-bold text-pla-primary"
                            onClick={() =>
                              isShowFeedReplay(
                                items.child_recursive[i] != null ? "1" : "0",
                                i
                              )
                            }
                          >
                            REPLY
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <IoCloseSharp
                      className="fs-3 m-3 cursor-pointer text-danger"
                      onClick={() => deleteFeeds(items.id, items.user_id)}
                    />
                  </div>
                </div>

                {showFeedReplay == "1" &&
                  isShowFeedReplayIndex === i &&
                  items?.child_recursive.map((data) => {
                    return (
                      <div className="reply_body">
                        <p>
                          <i
                            className="fa fa-envelope-o feed_replay_msg"
                            aria-hidden="true"
                          ></i>{" "}
                          {data.content}
                        </p>
                      </div>
                    );
                  })}
                {showFeedReplay == "0" && isShowFeedReplayIndex === i && (
                  <div className="reply_body">
                    <div className="bg-white  border">
                      <div className="form-floating">
                        <textarea
                          className="form-control height_150"
                          placeholder="Leave a comment here"
                          id="floatingTextarea"
                          onChange={(e) => currentUserTeamFeed(e.target.value)}
                        ></textarea>
                        <label htmlFor="floatingTextarea">
                          Enter some TEAM'S FEED
                        </label>
                      </div>
                    </div>
                    <div className="d-flex gap-5 align-items-center justify-content-between pb-5 ">
                      <div className="mt-4"></div>
                      <div className="d-flex gap-5 align-items-center mt-4">
                        <p className="fw-bold text-center mt-3"></p>
                        <button
                          className="btn px-5 bg-pla-primary text-white btn-sm"
                          onClick={() => submitCurrentUserTeamFeed(items.id)}
                        >
                          Post
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}

          <div className="mar_auto mt-5">
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={currentPage}
              itemsCountPerPage={recordPerPage}
              totalItemsCount={totalRecords}
              pageRangeDisplayed={pageRange}
              onChange={(pageNumber) => GetAllFeed(pageNumber)}
            />
          </div>
        </div>
        <div className="mb-5 pb-5"></div>
      </div>

      <LoadingSpinner hidden={notLoading} />
    </div>
  );
};

export default Feeds;
