import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../../components/footer-layout/footer';
import HeaderLayout from '../../components/header-layout/header-layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import apiService from '../../services/api.service';
import * as authActions from '../../store/actions/auth';

const ConfirmSignup = () => {
  const { state } = useLocation();
  const { id } = state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notLoading, setNotLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setNotLoading(false);
    var formData = {};
    formData.user_id = id;
    formData.take_part = data['take_part'];
    formData.further_wrk = data['further_wrk'];
    formData.next_guid = data['what_am_i_doing'];
    formData.duration = data['doing_plafera_challenge_for'];
    axios
      .post(apiService.API_URL() + '/api/v1/furtherDetails', formData)
      .then(function (response) {
        setNotLoading(true);
        navigate('/how-it-works');
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  return (
    <div>
      <HeaderLayout />
      <LoadingSpinner hidden={notLoading} />
      <ToastContainer />
      <form className="w-md-50 m-auto" onSubmit={handleSubmit(onSubmit)}>
        <h1 className="fs-1 title text-center my-4">You're almost there!</h1>
        <div className="card p-3 mb-4 shadow sign-up-confirmation-message blinking-text">        
        <p>Thank you for choosing to sign up with us! We're excited to have you as part of our community. To complete your registration, we need to verify your email address.</p>
        <p>A verification email has been sent to the email address you provided during the sign-up process. To proceed, please follow these simple steps:</p>
        <ol>
          <li>Open your email inbox.</li>
          <li>Look for an email from plaferachallenge@plafera.org with the subject line: "User Register Email Verfication".</li>
          <li>Open the email and click on the 'Click here to confirm your email' button provided.</li>           
        </ol>
        <p>Please note that you won't be able to fully access your account until your email address is verified. This is an important security step to ensure the validity of your registration.</p>
        <p>If you don't see the verification email in your inbox, please check your spam or junk folder.</p>
        <p>Thank you for choosing us. We look forward to having you on board!</p>
        </div>
        <div className="card p-3 mb-4 shadow">
          <h3 className="fs-3 sub-title-login">
            Where I will take part in Plafera Challenge: <span className="text-danger">*</span>
          </h3>

          <div className="form-check my-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="At home, and out and about"
              {...register('take_part', { required: true })}
            />
            <label
              className="form-check-label text-black"
              htmlFor="flexCheckDefault"
            >
              At home, and out and about
            </label>
          </div>
          <div className="form-check my-2">
            <input
              className="form-check-input"
              type="checkbox"
              value={'At my workplace'}
              {...register('take_part', { required: true })}
            />
            <label
              className="form-check-label text-black"
              htmlFor="flexCheckDefault"
            >
              At my workplace
            </label>
          </div>
          <div className="form-check my-2">
            <input
              className="form-check-input"
              type="checkbox"
              value={'At a shop, café, or retail outlet'}
              {...register('take_part')}
            />
            <label
              className="form-check-label text-black"
              htmlFor="flexCheckDefault"
            >
              At a shop, café, or retail outlet
            </label>
          </div>
          <div className="form-check my-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="At school or university"
              {...register('take_part')}
            />
            <label
              className="form-check-label text-black"
              htmlFor="flexCheckDefault"
            >
              At school or university
            </label>
          </div>

          <div className="form-check my-2">
            <input
              className="form-check-input"
              type="checkbox"
              value="Other"
              {...register('take_part')}
            />
            <label
              className="form-check-label text-black"
              htmlFor="flexCheckDefault"
            >
              Other
            </label>
          </div>
          {errors.take_part && (
            <p className="text-danger fst-italic mt-2">
              This Field is required!
            </p>
          )}
        </div>

        <div className="card p-3 mb-4 shadow">
          <div>
            <h3 className="fs-3 sub-title-login">What I'm doing: <span className="text-danger">*</span></h3>

            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="whatAmIDoing"
                id="firstChallenge"
                {...register('what_am_i_doing')}
                value="This is my first Plafera Challenge"
              />
              <label
                className="form-check-label text-black"
                htmlFor="firstChallenge"
              >
                This is my first Plafera Challenge
              </label>
            </div>
            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="whatAmIDoing"
                id="nextSteps"
                {...register('what_am_i_doing')}
                value="I'm looking for next steps"
              />
              <label
                className="form-check-label text-black"
                htmlFor="nextSteps"
              >
                I'm looking for next steps
              </label>
            </div>

            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="whatAmIDoing"
                id="shareWithOthers"
                {...register('what_am_i_doing', { required: true })}
                value="I'm ready to share with others"
              />
              <label
                className="form-check-label text-black"
                htmlFor="shareWithOthers"
              >
                I'm ready to share with others
              </label>
            </div>

            {errors.what_am_i_doing && (
              <p className="text-danger fst-italic mt-2">
                This Field is required!
              </p>
            )}
          </div>
        </div>

        <div className="card p-3 mb-4 shadow">
          <div>
            <h3 className="fs-3 sub-title-login">
              During Plafera Challenge, I will: <span className="text-danger">*</span>
            </h3>
            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="checkbox"
                value="Avoid single-use plastic packaging"
                {...register('further_wrk', { required: true })}
              />
              <label
                className="form-check-label text-black"
                htmlFor="flexCheckDefault"
              >
                Avoid single-use plastic packaging
              </label>
            </div>
            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="checkbox"
                value="Target takeaway items (bags, bottles, food pack, straws and
                  cups)"
                {...register('further_wrk', { required: true })}
              />
              <label
                className="form-check-label text-black"
                htmlFor="flexCheckDefault"
              >
                Target takeaway items (bags, bottles, food pack, straws and
                cups)
              </label>
            </div>
            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="checkbox"
                value='Go completely "plastic free"'
                {...register('further_wrk', { required: true })}
              />
              <label
                className="form-check-label text-black"
                htmlFor="flexCheckDefault"
              >
                Go completely "plastic free"
              </label>
            </div>
          </div>
          {errors.further_wrk && (
            <p className="text-danger fst-italic mt-2">
              This Field is required!
            </p>
          )}
        </div>

        <div className="card p-3 mb-4 shadow">
          <div>
            <h3 className="fs-3 sub-title-login">
              I'm doing Plafera Challenge for: <span className="text-danger">*</span>
            </h3>

            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="doingPlaferaChallenge"
                id="1day"
                {...register('doing_plafera_challenge_for')}
                value="1 day"
              />
              <label className="form-check-label text-black" htmlFor="1day">
                1 Day
              </label>
            </div>
            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="doingPlaferaChallenge"
                id="1week"
                {...register('doing_plafera_challenge_for')}
                value="1 Week"
              />
              <label className="form-check-label text-black" htmlFor="1week">
                1 Week
              </label>
            </div>

            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="doingPlaferaChallenge"
                id="1month"
                {...register('doing_plafera_challenge_for')}
                value="1 Month"
              />
              <label className="form-check-label text-black" htmlFor="1month">
                1 Month
              </label>
            </div>

            <div className="form-check my-2">
              <input
                className="form-check-input"
                type="radio"
                name="doingPlaferaChallenge"
                id="fromNowOn"
                {...register('doing_plafera_challenge_for', { required: true })}
                value="From now on"
              />
              <label
                className="form-check-label text-black"
                htmlFor="fromNowOn"
              >
                From now on
              </label>

              {errors.doing_plafera_challenge_for && (
                <p className="text-danger fst-italic mt-2">
                  This Field is required!
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="text-center my-4">
          <button className="theme_btn" type="submit">
            Continue
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default ConfirmSignup;
