import { SET_TEAM_ID, SET_TOKEN, SET_USER } from "../actions/auth";

const initialState = {
  token: null,
  user: null,
  teamId: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case SET_TEAM_ID:
      return {
        ...state,
        teamId: action.data,
      };
  }
  return state;
};
