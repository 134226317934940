import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer-layout/footer';
import HeaderLayout from '../../components/header-layout/header-layout';

const HowItWorks = ({ authenticate }) => {
  const navigate = useNavigate();

  return (
    <div>
      <HeaderLayout />
      <div className="container">
        <p className="text-pla-primary fs-6 fw-bold mt-5">HOW IT WORKS </p>
        <h1 className="fs-1 fw-bolderer text-capitalize">
          Before Plafera Challenge
        </h1>

        <div className="row my-5">
          <div className="col-md-6">
            <p className="fs-7 fw-bolderer">01</p>
            <p className="fs-2 fw-bold text-pla-primary">
              Create / Join a team
            </p>
            <p className="fs-5 mt-4">
              To get started after signup, connect with your network or join our
              Community Team. Better yet, lead a new team in your company,
              school, or community as a Team Captain or Organization Captain!
            </p>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img
              src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/how/01.svg'}
              alt="create"
              className="how-image"
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-6 order-2 order-md-1 d-flex align-items-center justify-content-center">
            <img
              src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/how/02.svg'}
              alt="calculate"
              className="how-image"
            />
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <p className="fs-7 fw-bolderer">02</p>
            <p className="fs-2 fw-bold text-pla-primary">
              Calculate your plastic footprint
            </p>
            <p className="fs-5 mt-4">
              Find out how much plastic you use throughout a year with Plafera
              plastic footprint calculator.
            </p>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-md-6">
            <p className="fs-7 fw-bolderer">03</p>
            <p className="fs-2 fw-bold text-pla-primary">
              Select your actions{' '}
            </p>
            <p className="fs-5 mt-4">
              Choose actions that you can keep up with for the duration of the
              challenge. There are numerous actions to pick from; find those
              that align with your values, lifestyle, and enthusiasm.
            </p>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img
              src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/how/03.svg'}
              alt="select"
              className="how-image"
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-6 order-2 order-md-1 d-flex align-items-center justify-content-center">
            <img
              src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/how/04.svg'}
              alt="prepare"
              className="how-image"
            />
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <p className="fs-7 fw-bolderer">04</p>
            <p className="fs-2 fw-bold text-pla-primary">
              Prepare for your actions
            </p>
            <p className="fs-5 mt-4">
              Do you have any actions that require some preparation? Take the
              time you need to plan your actions for success. Choosing to eat
              just vegetarian meals? Look up the ingredients and recipes you'll
              need!
            </p>
          </div>
        </div>

        <p className="fs-1 fw-bolderer text-capitalize">
          During Plafera Challenge
        </p>

        <div className="row my-5">
          <div className="col-md-6">
            <p className="fs-7 fw-bolderer">05</p>
            <p className="fs-2 fw-bold text-pla-primary">
              Complete your actions{' '}
            </p>
            <p className="fs-5 mt-4">
              To earn points, complete your actions and check them off. Simply
              go to your Dashboard, locate the actions you've selected, and
              click the circle icon. You've now collected your points!
            </p>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img
              src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/how/05.svg'}
              alt="complete"
              className="how-image"
            />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-6 order-2 order-md-1 d-flex align-items-center justify-content-center">
            <img
              src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/how/06.svg'}
              alt="share"
              className="how-image"
            />
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <p className="fs-7 fw-bolderer">06</p>
            <p className="fs-2 fw-bold text-pla-primary">Share your story</p>
            <p className="fs-5 mt-4">
              Motivate your teammates and fellow Plafera Challengers! On our
              Participant Feed, you can share your Plafera Challenge experience.
              Observations, inspirations, and lessons learnt are all welcomed,
              and they inspire others to keep going!
            </p>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-6">
            <p className="fs-7 fw-bolderer">07</p>
            <p className="fs-2 fw-bold text-pla-primary">
              Our impact together{' '}
            </p>
            <p className="fs-5 mt-4">
              Hundreds of thousands of everyday actions add up to a big
              difference! See how tens of thousands of everyday people taking
              action together can make a great difference in our communities and
              on the planet.
            </p>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img
              src={'https://storage.googleapis.com/plafera_bucket-1/plaferaImages/public/assets/images/how/07.svg'}
              alt="together"
              className="how-image"
            />
          </div>
        </div>
      </div>
      <div className="text-center mb-4">
        <button
          className="btn text-white bg-pla-primary px-4"
          onClick={() => {
            navigate('/sign-in');
          }}
        >
          Go To Sign In
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default HowItWorks;
