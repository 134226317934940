export const SET_ACTION_POINTS = "SET_ACTION_POINTS";
export const GET_ACTION_POINTS = "GET_ACTION_POINTS";

export const setActionPoints = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_ACTION_POINTS, data: data });
  };
};
export const getActionPoints = (data) => {
  return async (dispatch) => {
    dispatch({ type: GET_ACTION_POINTS, data: data });
  };
};
