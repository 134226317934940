import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

const propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  perspective: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  trigger: PropTypes.oneOf(["hover", "click"]),
  children: (props, propName, componentName) => {
    const childCount = React.Children.count(props[propName]);
    if (childCount !== 2) {
      return new Error(
        `\`${componentName}\` requires exactly two children (a front and back). You have passed ${childCount}.`
      );
    }
  }
};

const defaultProps = {
  width: 240,
  height: 300,
  perspective: 500,
  trigger: "click"
};

const FlipCard = props => {
  const { width, height, perspective, trigger } = props;
  const children = React.Children.toArray(props.children);

  return (
    <label
      className={`flipcard flipcard-${trigger} `}
      style={{ width, height, perspective }}
    >
      {trigger === "click" && (
        <input className="flipcard-checkbox" type="checkbox" />
      )}
      <span className="flipcard-content">
        <span className="flipcard-content-front">{children[0]}</span>
        <span className="flipcard-content-back">{children[1]}</span>
      </span>
    </label>
  );
};

FlipCard.propTypes = propTypes;
FlipCard.defaultProps = defaultProps;
export default FlipCard;
