import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";

import * as eventsAction from "../../store/actions/events";

import DataTable from "react-data-table-component";
import HeaderLayout from "../../components/header-layout/header-layout";
import Footer from "../../components/footer-layout/footer";

const TeamEventList = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userIdResult = localStorage.getItem("user");
  const userData = JSON.parse(userIdResult);
  const user = useSelector((state) => state.auth.user);
  const [notLoading, setNotLoading] = useState(false);
  const [eventListData, setEventListData] = useState([]);

  const eventListDataStore = useSelector((state) => state.events.eventList);

  // useEffect(() => {
  //   setEventListData(eventListDataStore);
  // }, [eventListDataStore]);

  const columns = [
    {
      name: "Clean Up Location",
      selector: (row) => `${row.cleanup_location}`,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => `${row.country}`,
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => `${row.state}`,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => `${row.city}`,
      sortable: true,
    },
    {
      name: "LGA",
      selector: (row) => `${row.local_government_area}`,
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: (row) => `${row.event_date_time}`,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          {row.status == "Open" && (
            <button
              className="btn btn-info btn-sm"
              id={row.id}
              onClick={() => clickHandler(row.id)}
            >
              <span className="glyphicon glyphicon-pencil"></span>Edit
            </button>
          )}
          {row.status == "Approved" && (
            <button
              className="btn btn-info btn-sm"
              id={row.id}
              onClick={() => navigateToEventComplete(row.id)}
            >
              <span className="glyphicon glyphicon-pencil"></span>Complete
            </button>
          )}
        </>
      ),

      sortable: false,
    },
  ];

  useEffect(() => {
    eventList();
  }, []);

  const navigateToEventComplete = (id) => {
    navigate(`/dashboard/event-success/${id}`);
  };
  const clickHandler = (id) => {
    //To do need to check alternate for this
    // let eventId = state.currentTarget.attributes[1].nodeValue

    navigate(`/dashboard/edit-event/${id}`, {
      state: { eventId: id },
    });
  };

  const eventList = async () => {
    setNotLoading(false);
    axios
      .get(apiService.API_URL() + `/api/v1/get-teams-events/${userData.id}`, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        setNotLoading(true);
        dispatch(eventsAction.setEventList(response.data.events));
        // setEventListData(response.data.events);
      })
      .catch(function (error) {
        setNotLoading(true);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  return (
    <>
      <div className="container team-form  mt-5 mb-5 my-4 pb-5">
        <div className="d-flex flex-column bg-light">
          <section className="d-flex flex-column align-items-center justify-content-center my-4">
            <h2 className="fs-1 fw-light">Event List</h2>
            {userData.user_role === "captain" && (
              <Link
                className="btn-primary float-right"
                to="/dashboard/create-event"
              >
                Create Event
              </Link>
            )}
          </section>

          <section>
            <DataTable
              title="All Events"
              columns={columns}
              data={eventListDataStore}
              pagination
              highlightOnHover
            />
          </section>
        </div>
      </div>
      <LoadingSpinner hidden={notLoading} />
    </>
  );
};

export default TeamEventList;
