import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Footer from '../../components/footer-layout/footer';
import HeaderLayout from '../../components/header-layout/header-layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import apiService from '../../services/api.service';
import { useSelector, useDispatch } from "react-redux";

const GenerateLink = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onGenerateLink = (data) => {
    setLoading(true);
    var data = {
      email: data.email,
    }
    axios
      .post(apiService.API_URL() + '/api/v1/user/reset-password', data, { headers: { Authorization: `Bearer ${token}` }})
      .then(function (response) {
        setLoading(false);
        reset();
        toast.success(response.data.msg);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
      });
  };
  return (
    <>
      <HeaderLayout />
      <div className="d-flex flex-column align-items-center justify-content-center ">
        <LoadingSpinner hidden={!loading} />
        <ToastContainer />
        {/* <img
      src={"/assets/images/image.png"}
      alt="logo"
      style={{ height: "150px", width: "150px" }}
    /> */}
        <div className="text-center mt-3">
          <h2>Forgot Password</h2>
          <p className="text-pla-gray">
            Enter your email to generate password reset link.
          </p>
        </div>
        <div className="container auth-form">
          <form className="row g-3" onSubmit={handleSubmit(onGenerateLink)}>
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                Email <span className="text-danger">*</span>
              </label>

              <input
                type="email"
                className="form-control"
                id="email"
                {...register('email', {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors.email && (
                <div className="text-danger my-2 fst-italic">
                  Please enter the valid email.
                </div>
              )}
            </div>

            <div className="text-center mb-5">
              <button
                className="btn bg-pla-primary text-white px-5 rounded-pill py-2"
                type="submit"
              >
                Send Link
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GenerateLink;
