import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const VideoModal = (props) => {
  const videoData = props.data;

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      scrollable={true}
      size="lg"
      style={{ margin: 0, padding: 0 }}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ margin: 0, padding: 0 }}>
        <div>
          {videoData && (
            <video controls style={{ height: '100%', width: '100%' }} autoPlay>
              <source
                src={videoData?.base_url + '/' + videoData?.data[0]?.path}
                type="video/mp4"
              />
            </video>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
