import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";
import Feeds from "../Feeds";
import * as userAction from "../../store/actions/user";
import * as pointsAction from "../../store/actions/points";

const UserProfile = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { id, teamId } = state;
  const token = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [points, setPoints] = useState(null);

  const userProfileStore = useSelector((state) => state.user.userProfile);
  const userPointsStore = useSelector((state) => state.points.actionPointsByUser);

  const challenge = localStorage.getItem("challenge");
  const challengedata = JSON.parse(challenge);

  useEffect(() => {
    fetchUserData();
  }, []);
  useEffect(() => {
    if (userProfileStore) setUser(userProfileStore);
  }, [userProfileStore]);
  useEffect(() => {
    if (userPointsStore) {
      setPoints(userPointsStore);
    }
  }, [userPointsStore]);

  const fetchUserData = () => {
    setLoading(true);
    if (token) {
      axios
        .get(apiService.API_URL() + `/api/v1/profile/edit/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          dispatch(userAction.setUserProfile(response.data));

          setLoading(false);
          fetchUserPoints();
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setLoading(false);
        });
    }
  };

  const fetchUserPoints = () => {
    setLoading(true);
    if (token) {
      axios
        .get(
          apiService.API_URL() +
            `/api/v1/get-user-points-by-team-with-actions/${id}/${teamId}/${challengedata?.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          dispatch(pointsAction.getActionPoints(response.data));
          setLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setLoading(false);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <LoadingSpinner hidden={!loading} />
      {!loading && (
        <div className="T_profile_body">
          {user && (
            <div className="T_profile_content">
              <div
                className="bg-dark p-5 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundImage: `url(${`data:image/png;base64,${user?.banner}`})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                <div>
                  {user?.avatar ? (
                    <img
                      src={`data:image/png;base64,${user?.avatar}`}
                      className="mx-auto d-block rounded-circle"
                      alt="Cinque Terre"
                      style={{ height: "100px", width: "100px" }}
                    />
                  ) : (
                    <div
                      className="p-5 rounded-circle bg-success d-flex align-items-center justify-content-center text-uppercase fs-3 text-white"
                      style={{ height: "100px", width: "100px" }}
                    >
                      {user?.name?.charAt(0)}
                      {user?.last_name != null
                        ? user?.last_name?.charAt(0)
                        : ""}
                    </div>
                  )}
                </div>
                <h3 className="text-center title mt-4 mb-2">{}</h3>
                <h5 className="sub_title mt-1 mb-4 cursor-pointer text-capitalize fs-3 fw-bold">
                  {user?.name + " "}{" "}
                  {user?.last_name != null ? user?.last_name : ""}
                </h5>
                <p className="text-white text-center fs-6">{user?.mission}</p>
              </div>
              {points && (
                <div className="points_body mt-5">
                  <h4 className="text-center mb-4">POINTS TOTAL</h4>
                  <div className="d-flex justify-content-center">
                    <div className="box">
                      <h2 className="value text-center">
                        {" "}
                        {points?.today_points}{" "}
                      </h2>
                      <div className="text-center text-uppercase">Today</div>
                    </div>
                    <div className="box">
                      <h2 className="value text-center">
                        {" "}
                        {points?.current_week_points}{" "}
                      </h2>
                      <div className="text-center text-uppercase">
                        this week
                      </div>
                    </div>
                    <div className="box">
                      <h2 className="value text-center">
                        {" "}
                        {points?.total_points}{" "}
                      </h2>
                      <div className="text-center text-uppercase">Total</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {!loading && (
        <div className="T_actions">
          <div className="imkpact_body">
            <div className="row">
              {points && points.user_actions.length > 0 && (
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h1 className="mb-4 mt-5 text-uppercase text-center">
                    {user?.name} 's Actions
                  </h1>
                </div>
              )}
              {points &&
                points?.user_actions.map((action, index) => {
                  return (
                    <div className="col-md-4 col-sm-4 col-xs-12 mb-4">
                      <div
                        className="d-flex flex-column align-items-center p-4 bg-white border m-2"
                        style={{ minHeight: "430px" }}
                      >
                        <div className="py-2 px-3 border bg-warning rounded-circle d-flex align-items-center justify-content-center text-white mb-3">
                          {index + 1}
                        </div>
                        <p>{action.name}</p>
                        <p className="text-center fs-6 ">
                          {action.description}
                        </p>

                        <h6 className="text-center fw-bold fs-6 mt-4">
                          {" "}
                          COMPLETED
                        </h6>
                        <h6 className="text-center fw-bold fs-6">
                          {" "}
                          {action.frequency =='daily' ? 'DAILY' : 'ONE-TIME'} ACTION
                        </h6>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
