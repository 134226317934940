import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import apiService from "../../services/api.service";
import LoadingSpinner from "../LoadingSpinner";
import Footer from "../footer-layout/footer";

function ActionCategories() {
  const [challengeBySlug, setchallengeBySlug] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notLoading, setNotLoading] = useState(true);
  const navigate = useNavigate();

  let { challengeSlug } = useParams();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const challengeBySlug = async () => {
      setLoading(true);

      axios
        .get(apiService.API_URL() + "/api/v1/challenge/" + challengeSlug, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
          setchallengeBySlug(response.data);
          setLoading(false);
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
            setNotLoading(false);
          }
        });
    };
    challengeBySlug();
  }, []);

  const actionByActionCategorySlug = (sluge, challengeId, challengeSlug) => {
    var ActionCategorySlug = sluge;
    var ChallengeID = challengeId;
    var ChallengeSluge = challengeSlug;
    var URL =
      "/challenge/actionbyactioncategories/" +
      ActionCategorySlug +
      "/challengeId/" +
      ChallengeID +
      "/challengesluge/" +
      challengeSlug;
    navigate(URL);
  };
  return (
    <>
      <div className="action_categories category">
        <div className="title text-center mb-5">action categories</div>
        <div className="container">
          <div className="row">
            {challengeBySlug[0]?.actionCategories?.map((item, index) => {
              return (
                <div key={`abcSlug${index}`}className="col-md-4 mb-4">
                  <div
                    onClick={() =>
                      actionByActionCategorySlug(
                        item.name_slug,
                        challengeBySlug[0].challenge.challenge_id,
                        challengeBySlug[0].challenge.challenge_slug
                      )
                    }
                  >
                    <div
                      className="cat_card"
                      style={{ backgroundColor: item.bgcolor }}
                    >
                      <div className="logo">
                        <img src={item.icon} />
                      </div>
                      <div className="title">{item.name}</div>
                      <div className="sub_title">0 participants</div>
                    </div>
                    <div className="img">
                      <img src={item.banner} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <LoadingSpinner hidden={!loading} />
      </div>
      <Footer />
    </>
  );
}

export default ActionCategories;
