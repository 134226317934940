import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query"; // Import the useQuery hook
import apiService from "../../services/api.service";
import LoadingSpinner from "../LoadingSpinner";
import Footer from "../footer-layout/footer";
import { useSelector, useDispatch } from "react-redux";
import * as actionCategoriesAction from "../../store/actions/actionCategories";

function CampusDashboardView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { challengeSlug } = useParams();
  const token = localStorage.getItem("token");

  const {
    data: allActionCategories,
    isLoading,
    error,
  } = useQuery(
    "allActionCategories",
    () =>
      axios
        .get(apiService.API_URL() + "/api/v1/allActionCategories", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.data.AllActionCategory),
    {
      onSuccess: (data) => {
        dispatch(actionCategoriesAction.setAllActionCategories(data));
      },
    }
  );

  const challenge = localStorage.getItem("challenge");
  const challengeData = JSON.parse(challenge);

  const actionCategoriesStore = useSelector(
    (state) => state.actionCategories.actionCategories
  );

  const actionByActionCategorySlug = (slug) => {
    const ActionCategorySlug = slug;
    const ChallengeID = challengeData.id;
    const ChallengeSlug = challengeData?.challenge_slug;

    const URL =
      "/challenge/actionbyactioncategories/" +
      ActionCategorySlug +
      "/challengeId/" +
      ChallengeID +
      "/challengesluge/" +
      ChallengeSlug;

    navigate(URL);
  };
  const backToDashboard = () => {
    navigate("/dashboard/home");
  };

  return (
    <>
      <div className="category">
        <div className="title text-center mb-5">action categories</div>
        <div className="col-md-12">
                <div className="mar_auto mt-4 mb-4">
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={() => backToDashboard()}
                  >
                    Go To Dashboard
                  </button>
                </div>
              </div>
        <div className="container">
          <div className="row">
            {allActionCategories?.map((items, i) => (
              <div
                className="col-md-3 mb-4 cursor-pointer d-flex flex-column"
                onClick={() => actionByActionCategorySlug(items.name_slug)}
                key={`cursor-pointer${i}`}
              >
                <div
                  className="cat_card flex-grow-1"
                  style={{ backgroundColor: items.bgcolor }}
                >
                  <div className="logo">
                    <img src={items.icon} alt={`${items.name} icon`} />
                  </div>
                  <div className="title">{items.name}</div>
                  <div className="sub_title">
                    {items.participants} participants
                  </div>
                </div>
                <div className="img">
                  <img src={items.banner} alt={`${items.name} banner`} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <LoadingSpinner hidden={!isLoading} />
      <Footer />
    </>
  );
}

export default CampusDashboardView;
