import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import FlipCard from "../../helper/FlipCard";
import apiService from "../../services/api.service";
import { IoTimeSharp } from "react-icons/io5";
import LoadingSpinner from "../LoadingSpinner";
import Footer from "../footer-layout/footer";

import * as actionCategoriesAction from "../../store/actions/actionCategories";

function ActionByActionCategories() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { ActionCategorySlug } = useParams();
  let { ChallengeID } = useParams();

  const [loading, setLoading] = useState(false);
  const [notLoading, setNotLoading] = useState(true);
  const [actionByActionCategory, setactionByActionCategory] = useState([]);
  const [selectActionCategoryStatus, setselectActionCategoryStatus] =
    useState();
  const [isGoalView, setIsGoalView] = useState({ isgoal: null, index: null });
  const [isGoalNumber, setIsGoalNumber] = useState();

  const token = localStorage.getItem("token");
  const userIdResult = localStorage.getItem("user");
  const userID = useSelector((state) => state.auth.user); //JSON.parse(userIdResult);
  const challlenge = localStorage.getItem("challenge");
  const challlengeData = JSON.parse(challlenge);

  const actionsByActionCategoryStore = useSelector(
    (state) => state.actionCategories.actionsByActionCategory
  );

  var commitActionData = {
    challenge_id: 0,
    user_id: 0,
    action_id: 0,
    status: 0,
    already_did_this: 0,
    target: 0,
  };

  useEffect(() => {
    challengeBySlug();
  }, []);

  useEffect(() => {
    setactionByActionCategory(actionsByActionCategoryStore);
  }, [actionsByActionCategoryStore]);

  const challengeBySlug = async () => {
    setLoading(true);
    var user_ID = null;
    if (localStorage.getItem("user"))
      user_ID = JSON.parse(localStorage.getItem("user")).id;

    axios
      .get(
        apiService.API_URL() + "/api/v1/actionByActionCategorySlug/" + ActionCategorySlug + "/" + ChallengeID + "/" + user_ID, {headers: { Authorization: `Bearer ${token}` },})
      .then(function (response) {
        // setactionByActionCategory(response.data);
        dispatch(
          actionCategoriesAction.setactionByActionCategory(response.data)
        );
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setLoading(false);
      });
  };

  const commitAction = (
    action_category_id,
    status,
    isGoal,
    Index,
    alreadyDidThis
  ) => {
    if (isGoal == 1) {
      setIsGoalView({ isgoal: isGoal, index: Index });
    } else {
      commitActionData.challenge_id = ChallengeID;
      commitActionData.user_id = userID.id;
      commitActionData.action_id = action_category_id;
      commitActionData.status =
        status == 0 || status == null ? (status = 1) : (status = 0);
      commitActionData.already_did_this = alreadyDidThis;
      commitActionData.target = 0;
      setLoading(true);
      axios
        .post(
          apiService.API_URL() + "/api/v1/commit_action", commitActionData, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
          toast.success(response.data.msg);
          setLoading(false);
          challengeBySlug();
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              toast.error(error.response.data.errors[i]);
            }
          }
          setLoading(false);
        });
    }
  };

  const JoinToSelect = () => {
    navigate("/join-team/" + challlengeData.id);
    // navigate("/sign-in");
  };

  const onChangeAlreadyDidThis = (event, actionCategorieId, status) => {
    commitActionData.challenge_id = ChallengeID;
    commitActionData.user_id = userID.id;
    commitActionData.action_id = actionCategorieId;
    commitActionData.status = status === null ? (status = 0) : status;
    commitActionData.already_did_this = event === true ? 1 : 0;

    setLoading(true);
    axios
      .post(
        apiService.API_URL() + "/api/v1/commit_action", commitActionData, { headers: { Authorization: `Bearer ${token}` }})
      .then(function (response) {
        setLoading(false);
        challengeBySlug();
        toast.success(response.data.msg);
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setLoading(false);
      });
  };

  const SetGoalNumber = (event) => {
    setIsGoalNumber(event);
  };
  const addGoal = (actioId, status, didthis) => {
    commitActionData.challenge_id = ChallengeID;
    commitActionData.user_id = userID.id;
    commitActionData.action_id = actioId;
    commitActionData.status =
      (status === 0) | (status === null) ? (status = 1) : (status = 0);
    commitActionData.already_did_this = didthis;
    commitActionData.target = isGoalNumber;

    setLoading(false);
    axios
      .post(
        apiService.API_URL() + "/api/v1/commit_action", commitActionData, { headers: { Authorization: `Bearer ${token}` }})
      .then(function (response) {
        setNotLoading(true);
        challengeBySlug();
        toast.success(response.data.msg);
        setIsGoalView({ isgoal: null, index: null });
      })
      .catch(function (error) {
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(false);
      });
  };

  const backToDashboard = () => {
    navigate("/dashboard/home");
  };

  return (
    <>
      <div
        className="action_categories_body"
        style={{
          backgroundColor: actionByActionCategory?.actionCategory?.bgcolor,
        }}
      >
        <div className="container">
          <div className="action_categories_content">
            <div className="title text-center">
              {actionByActionCategory?.actionCategory?.name}
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-12 col-xs-12"></div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="sub_title text-center mt-4 mb-4">
                  {actionByActionCategory?.actionCategory?.description}
                </div>
              </div>
              <div className="col-md-3 col-sm-12 col-xs-12"></div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            {userID?.id != null && userID?.team && (
              <div className="col-md-12">
                <div className="mar_auto mt-4 mb-4">
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={() => backToDashboard()}
                  >
                    Go To Dashboard
                  </button>
                </div>
              </div>
            )}
            {userID?.team == null && (
              <div className="col-md-12">
                <div className="mar_auto mt-4 mb-4">
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={() => JoinToSelect()}
                  >
                    Join To Team
                  </button>
                </div>
              </div>
            )}
            {actionByActionCategory?.actionByActionCategorySlug?.map(
              (items, i) => {
                return (
                  <div className="col-md-6 col-sm-12 col-xs-12 col-lg-3 col-xl-3 col-xxl-3 d-flex flex-column" key={`action-card${i}`}>
                    <div className="Action_card d-flex flex-column align-items-center p-4 bg-white m-2 flex-grow-1">
                      {isGoalView.index !== i && (
                        <>
                          <div className="title text-center"> {items.name}</div>
                          <div className="sub_title text-center mt-2 mb-4 flex-grow-1">
                            {items.description}
                          </div>
                          <div className="mar_auto">
                            {userID !== null && userID?.team !== null && (
                              <button
                                type="button"
                                className={
                                  items.status != null && items.status == 1
                                    ? "btn btn-dark"
                                    : "btn btn-success"
                                }
                                onClick={() =>
                                  commitAction(
                                    items.action_id,
                                    items.status,
                                    items.is_goal,
                                    i,
                                    items.already_did_this
                                  )
                                }
                              >
                                {" "}
                                {items.status == 1 ? "Selected" : "Select"}
                              </button>
                            )}
                            {userID?.team == null && (
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => JoinToSelect()}
                              >
                                Join to Select
                              </button>
                            )}
                          </div>
                          <div className="Actions mt-4 mb-4 text-center">
                            {items.frequency} Action
                          </div>
                          {userID != null && (
                            <div className="form-check mar_auto already_did_this">
                              <input
                                className="form-check-input rounded-circle"
                                type="checkbox"
                                value={items.already_did_this == null ? 0 : 1}
                                id={items.name}
                                name={items.name}
                                checked={+items.already_did_this}
                                onChange={(e) =>
                                  onChangeAlreadyDidThis(
                                    e.target.checked,
                                    items.action_id,
                                    items.status
                                  )
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={items.name}
                              >
                                <small>
                                  I ALREADY{" "}
                                  {items.already_did_this == 1 ? "DO" : "DID"}{" "}
                                  THIS{" "}
                                </small>
                              </label>
                            </div>
                          )}
                        </>
                      )}
                      {isGoalView.index == i &&
                        isGoalView.isgoal == items.is_goal && (
                          <div>
                            <div className="title text-center">
                              {" "}
                              SET YOUR GOAL
                            </div>
                            <div className="sub_title text-center mt-2 mb-4">
                              {items.question_part_1}
                            </div>
                            <div className="form-check mar_auto mb-4">
                              <input
                                className="form-check-input"
                                type="number"
                                min={0}
                                id={items.name + i}
                                style={{ width: "100px", height: "30px" }}
                                onChange={(e) => SetGoalNumber(e.target.value)}
                              />
                            </div>
                            <div className="sub_title text-center mt-2 mb-4">
                              {items.question_part_2}
                            </div>
                            <div className="mar_auto">
                              <button
                                type="button"
                                className="btn btn-dark"
                                onClick={() =>
                                  addGoal(
                                    items.action_id,
                                    items.status,
                                    items.already_did_this
                                  )
                                }
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
      <LoadingSpinner hidden={!loading} />
      <Footer />
    </>
  );
}

export default ActionByActionCategories;
