import {
  SET_MY_EVENTS,
  SET_OTHER_EVENTS,
  SET_POINTS,
  SET_WEEKLY_POINTS,
  SET_USER_POINTS,
  SET_CHALLENGE,
  SET_EVENT_LIST,
  GET_EVENT_BY_ID,
} from "../actions/events";

const initialState = {
  myEvents: null,
  otherEvents: null,
  points: null,
  weeklyPoints: null,
  userPoints: null,
  eventList: [],
  challenge: null,
  eventById: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_EVENTS:
      return {
        ...state,
        myEvents: action.data,
      };

    case SET_OTHER_EVENTS:
      return {
        ...state,
        otherEvents: action.data,
      };

    case SET_POINTS:
      return {
        ...state,
        points: action.data,
      };
    case SET_WEEKLY_POINTS:
      return {
        ...state,
        weeklyPoints: action.data,
      };
    case SET_USER_POINTS:
      return {
        ...state,
        userPoints: action.data,
      };
    case SET_CHALLENGE:
      return {
        ...state,
        challenge: action.data,
      };
    case SET_EVENT_LIST:
      return {
        ...state,
        eventList: action.data,
      };
    case GET_EVENT_BY_ID:
      return {
        ...state,
        eventById: action.data,
      };
  }
  return state;
};
