export const SET_ALL_ACTION_CATEGORIES = "SET_ALL_ACTION_CATEGORIES";
export const SET_ACTION_BY_ACTION_CATEGORY = "SET_ACTION_BY_ACTION_CATEGORY"

export const setAllActionCategories = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_ALL_ACTION_CATEGORIES, data: data });
  };
};

export const setactionByActionCategory = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_ACTION_BY_ACTION_CATEGORY, data: data });
  };
};