import React from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { FaLinkedinIn } from 'react-icons/fa';
import { useState } from 'react';
import apiService from '../../../services/api.service';
import { toast } from 'react-toastify';
import axios from 'axios';

const LinkedinLoginComponent = () => {
  const clientId = '861r97cr3r92o9';
  const [loading, setLoading] = useState(false);

  const responseLinkedin = (token) => {
    setLoading(true);
    // axios
    //   .post(apiService.API_URL() + '/api/v1/social-app-auth', {
    //     provider: 'linkedin',
    //     access_token: token,
    //   })
    //   .then(function (response) {
    //     setLoading(false);
    //     toast.success(response.data.msg);
    //   })
    //   .catch(function (error) {
    //     setLoading(false);
    //     if (error.response.data.errors) {
    //       for (let i = 0; i < error.response.data.errors.length; i++) {
    //         toast.error(error.response.data.errors[i]);
    //       }
    //     }
    //   });
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: clientId,
    redirectUri: `http://localhost:3000/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      responseLinkedin(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <button className="btn linkedIn mx-2" onClick={linkedInLogin} type="button">
      <FaLinkedinIn />
    </button>
  );
};

export default LinkedinLoginComponent;
