export const SET_TOKEN = "SET_TOKEN";
export const SET_USER = "SET_USER";
export const SET_TEAM_ID = "SET_TEAM_ID";

export const setToken = (token) => {
  return async (dispatch) => {
    dispatch({ type: SET_TOKEN, token: token });
  };
};

export const setUser = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_USER, user: data });
  };
};

export const setTeamId = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_TEAM_ID, data: data });
  };
};
