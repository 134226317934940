import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

import LoadingSpinner from "../../components/LoadingSpinner";
import apiService from "../../services/api.service";

import DataTable from "react-data-table-component";
import HeaderLayout from "../../components/header-layout/header-layout";
import Footer from "../../components/footer-layout/footer";

import * as quizsAction from "../../store/actions/quiz";

const QuizResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userIdResult = localStorage.getItem("user");
  const userData = JSON.parse(userIdResult);
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const [notLoading, setNotLoading] = useState(false);
  const [quizResultData, setQuizResultData] = useState([]);
  const quizResultDataStore = useSelector((state) => {
    return state.quiz.quizResultData;
  });
  const columns = [
    {
      name: "Rank",
      selector: (row) => `${row.result_rank}`,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => `${row.user_name}`,
      sortable: true,
    },

    {
      name: "Average Time Taken",
      selector: (row) => `${row.avg_time_taken}`,
      sortable: true,
    },
    {
      name: "Average Quiz Start Time",
      selector: (row) => `${row.avg_quiz_start_time_with_converter}`,
      sortable: true,
    },
  ];

  useEffect(() => {
    eventList();
  }, []);
  useEffect(() => {
    setQuizResultData(quizResultDataStore);
  }, [quizResultDataStore]);

  const eventList = async () => {
    setNotLoading(false);
    axios
      .get(apiService.API_URL() + `/api/v1/quiz/get-quiz-result`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function (response) {
        setNotLoading(true);
        dispatch(quizsAction.setQuizResult(response.data.quiz_result));
        // setQuizResultData(response.data.quiz_result);
      })
      .catch(function (error) {
        setNotLoading(true);
        if (error.response.data.errors) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            toast.error(error.response.data.errors[i]);
          }
        }
        setNotLoading(true);
      });
  };

  return (
    <>
      <div className="container team-form  mt-5 mb-5 my-4 pb-5 ">
        <div className="d-flex flex-column bg-light">
          <section className="d-flex flex-column align-items-center justify-content-center my-4">
            <h2 className="fs-1 fw-light">Quiz Results</h2>
          </section>

          <section>
            <DataTable
              title="Results"
              columns={columns}
              data={quizResultData}
              pagination
              highlightOnHover
            />
          </section>
        </div>
      </div>
      <LoadingSpinner hidden={notLoading} />
    </>
  );
};

export default QuizResult;
